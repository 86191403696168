import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStudentById } from "../../../ReduxToolkit/Slices/studentSlice";

import "./PromoCodes.styles.scss";
import arrow from "../../assets/copy.svg";
import InviteFriends from "../InviteFriends/InviteFriends";

const NewCard = ({ promo, discount }) => {
  const [copied, setCopied] = useState(false);

  const handleClick = () => {
    navigator.clipboard.writeText(promo);
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };
  return (
    <>
      <div className="promo__row animatedCopy">
        <p>Discount: {discount} %</p>
        <div className="inputs">
          <p className="input">{promo}</p>

          {copied ? (
            <div class="wrapper">
              {" "}
              <svg class="animated-check" viewBox="0 0 24 24">
                <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" />{" "}
              </svg>
            </div>
          ) : (
            <img alt="" src={arrow} onClick={() => handleClick()} />
          )}
        </div>
      </div>
    </>
  );
};
function PromoCodes() {
  const dispatch = useDispatch();
  const { student } = useSelector((state) => state.student);

  const [promo, setPromo] = useState([]);
  useEffect(() => {
    dispatch(getStudentById(student)).then((res) => {
      setPromo(res.payload.data.promoCodes);
    });
  }, []);
  return (
    <>
      <div className="PromoCodes_Container">
        <div className="student__promo">
          <div className="promo__header">
            {promo.length > 0 ? (
              <h3>
                You can benefit from these promo codes to get special discounts
                on your next private and group lessons purchase:
              </h3>
            ) : (
              <h4>No promo codes available</h4>
            )}
          </div>
          {promo.length > 0 &&
            promo.map((code, i) => (
              <NewCard key={i} promo={code.code} discount={code.percentage} />
            ))}
        </div>
      </div>
      <InviteFriends />
    </>
  );
}

export default PromoCodes;

const BookingInfoWrapper = ({ title, data }) => {
  return (
    <div className="booking_info_wrapper">
      <h1>{title}</h1>
      <div className="booking_info_details">
        {data &&
          data.map((el, i) => (
            <div className="data_row" key={i}>
              <span className="data_name">{el.name}</span>
              <span className="data_value">{el.value}</span>
            </div>
          ))}
      </div>
    </div>
  );
};
export default BookingInfoWrapper;

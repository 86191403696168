import Pagination from "../../../Components/Pagination/Pagination";
import { Link } from "react-router-dom";
import moment from "moment";
import notfound from "../../../assets/SharedApp/images/default.webp";
import right from "../../../assets/SharedApp/svgs/right.svg";
const BookingTable = ({
  bookings,
  page,
  setCurrentPage,
  currentPage,
  setBooking,
  filters,
  setFilters,
}) => {
  const newDate = (date) => {
    return moment(date).format("DD/MM/YYYY");
  };
  return (
    <div className="manage__booking__page">
      <div className="header">
        <div className="header__text">
          <span>Booking Lists</span>
          {/* <p>More than 400+ members</p> */}
        </div>
        <div className="teachers__actions">
          <div className="search__teachers">
            <select
              onChange={(e) => {
                if (e.target.value === "") {
                  let newFilters = { ...filters };
                  delete newFilters.status;
                  setFilters({
                    ...newFilters,
                  });
                } else setFilters({ ...filters, status: e.target.value });
              }}
              className="select_status"
            >
              <option disabled>Select Booking Status</option>
              <option value={""}>All</option>
              <option value={"ACCEPTED"}>Accepted</option>
              <option value={"COMPLETED"}>Completed</option>
              <option value={"REASSIGNED"}>Reassigned</option>
              <option value={"PENDING"}>Pending</option>
              <option value={"REJECTED"}>Rejected</option>
              <option value={"CANCELLED"}>Cancelled</option>
            </select>
            <input
              type="text"
              placeholder="Search students"
              value={filters.search}
              onChange={(e) =>
                setFilters({ ...filters, search: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className="students__table">
        <table>
          <tr>
            <th>STUDENTS</th>
            <th>LESSONS</th>
            <th>CREATED AT</th>
            <th>TEACHER</th>
            <th>AMOUNT</th>
            <th>STATUS</th>
            <th></th>
          </tr>
          {bookings &&
            bookings.length > 0 &&
            bookings.map((booking) => (
              <tr>
                <td>
                  <div className="student__cell">
                    <img
                      src={
                        booking.student.user?.profileImage?.url
                          ? booking.student.user?.profileImage?.url
                          : notfound
                      }
                      alt=""
                    />
                    <div className="name">
                      <span>
                        {booking?.student?.user?.name}{" "}
                        {booking?.student?.user?.lastName}
                      </span>
                      <p>{booking?.student?.user?.address?.street}</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="manag e__std__lesson">
                    <div className="lesson__date">
                      <span>
                        {booking?.lesson?.isGroup ? "Group" : "Private"} Lesson
                      </span>
                      <p>{booking?.lesson?.title}</p>
                    </div>
                  </div>
                </td>
                <td>
                  <span>{newDate(booking.createdAt)} </span>
                  <p>Date</p>
                </td>
                <td>
                  <span>
                    {booking?.lesson?.teacher?.user?.name}
                    {booking?.lesson?.teacher?.user?.lastName}
                  </span>
                  {/* change bellow */}
                  <p>Virtual</p>
                </td>
                <td>
                  <div className="student__cell">
                    <div className="name">
                      <span>{booking.lesson.hourlyRate}</span>
                      <p>per hour</p>
                    </div>
                  </div>
                </td>
                <td>
                  <button className={booking.status.toLowerCase()}>
                    {booking.status.toLowerCase() === "pendingpayment"
                      ? "Pending payment"
                      : booking.status.toLowerCase()}
                  </button>
                </td>
                <td>
                  <Link
                    onClick={() => setBooking(booking)}
                    to={`${booking.id}`}
                  >
                    <img src={right} alt="" />
                  </Link>
                </td>
              </tr>
            ))}
        </table>
      </div>
      <Pagination
        page={page}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
    </div>
  );
};
export default BookingTable;

import React from "react";
import "./ContactsContainer.styles.scss";
import { useSelector } from "react-redux";
import moment from "moment";

const Contact = ({
  email,
  name,
  icon,
  onClick,
  messageIndex,
  id,
  //   unreadMessageCount,
  showMessages,
  lastMessage,
  unreadMessageCount,
  lastMessageTime,
}) => {
  const utcToTimezone = (utcTime, targetTimezone) => {
    const localTime = moment
      .utc(utcTime) // Parse the UTC time directly
      .tz(targetTimezone) // Convert to the target timezone
      .format("hh:mm A"); // Format to 12-hour time with AM/PM
    return localTime;
  };
  const { timezone } = useSelector((state) => state.user);

  return (
    <div
      className={
        messageIndex === id && showMessages
          ? "contact_row selected_contact_row"
          : "contact_row"
      }
      onClick={() => onClick()}
    >
      <div className="contact_col">
        <img alt="" src={icon} />
        <div>
          <h4>{name}</h4>
          <p>{lastMessage}</p>
          {/* <p>{email}</p> */}
        </div>
        <div className="time">
          <span>
            {" "}
            {lastMessageTime ? utcToTimezone(lastMessageTime, timezone) : ""}
          </span>
          {/* remove false later */}
          {false && !!unreadMessageCount && unreadMessageCount > 0 && (
            <div className="msg_counter">
              <span>{unreadMessageCount}</span>
            </div>
          )}
        </div>
      </div>
      {/* <p>{duration}</p> */}
      {/* {unreadMessageCount > 0 ? (
        <p className="count_messages flex_center">{unreadMessageCount}</p>
      ) : null} */}
    </div>
  );
};

function ContactsContainer({
  //   showMessages,
  //   setShow,
  messageIndex,
  search,
  setMesageIndex,
  //   markMessagesAsSeen,
  setSearch,
  contacts,
  //   unreadMessagesCount,
  profilePic,
  children,
}) {
  return (
    <div>
      <div
        className="contacts_container new_contacts_container"
        //   onClick={() => {
        //     !showMessages && setShow(true);
        //   }}
      >
        <input
          type="text"
          className="searchInput"
          value={search}
          placeholder="Search by surname or email..."
          onChange={(e) => setSearch(e.target.value)}
        />

        <div className="children_container">
          <div className="all_contacts">
            {contacts &&
              contacts.map((user, i) => (
                <>
                  <Contact
                    showMessages={true}
                    onClick={() => {
                      setMesageIndex(user.id ?? "");
                      // markMessagesAsSeen(rooms[i]);
                    }}
                    key={i}
                    messageIndex={messageIndex}
                    id={user.id}
                    name={`${user.name} ${
                      user?.lastName ? user?.lastName : ""
                    }`}
                    email={user.email ?? ""}
                    //   unreadMessageCount={unreadMessagesCount[roomId]} // Pass the unread message count here
                    icon={
                      user?.profileImage?.url
                        ? user.profileImage.url
                        : profilePic
                    }
                    lastMessage={
                      user?.messages[0]?.content
                        ? user?.messages[0]?.content
                        : ""
                    }
                    unreadMessageCount={user?.unseenMessages}
                    lastMessageTime={user?.lastMessageTime}
                  />
                  <h1></h1>
                </>
              ))}
          </div>

          {children}
        </div>
      </div>
    </div>
  );
}

export default ContactsContainer;

import PhoneInput from "react-phone-number-input";
const BookingPhoneInput = ({ label, value, onChange, placeholder }) => (
  <div className="booking_input">
    <span className="input_name">{label}</span>
    <div className="input">
      <PhoneInput
        className="booking_phone_input"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  </div>
);
export default BookingPhoneInput;

import SharedModalContainer from "../Booking/SharedModalContainer";
import BookingInfoWrapper from "../../Components/BookingInfoWrapper/BookingInfoWrapper";
import moment from "moment";
import { useSelector } from "react-redux";

const ConfirmBookingModal = ({
  isOpen,
  closeModal,
  onSubmit,
  bookingInfo,
  submitButtonName,
}) => {
  const { timezone } = useSelector((state) => state.user);
  const utcToTimezone = (utcTime, targetTimezone) => {
    const localTime = moment
      .utc(utcTime, "hh:mm A") // Interpret the given time as UTC
      .tz(targetTimezone) // Convert it to the target timezone
      .format("hh:mm A"); // Format it in the desired output format
    return localTime;
  };

  const data = [
    {
      name: "Lesson Name",
      value: bookingInfo?.selectedTeacherLesson?.info?.title,
    },
    {
      name: "Lesson Location",
      value: bookingInfo?.selectedLocation?.label,
    },
    {
      name: "Package",
      value: bookingInfo?.selectedPackage?.label,
    },
    {
      name: "Lesson Frequency",
      value: bookingInfo?.booking?.type.toLowerCase(),
    },
    {
      name: "Lesson Date(s)",
      value:
        bookingInfo?.booking?.type === "WEEKLY"
          ? bookingInfo?.weeklyDate?.date
          : bookingInfo?.flexibleDates
              ?.map((el) =>
                moment(el?.label.split("at")[0]).format("DD/MM/YYYY")
              )
              .join(", "),
    },
    {
      name: "Duration",
      value: `${bookingInfo?.selectedPackage?.info?.duration} Min`,
    },
    {
      name: "Start Time",
      value:
        bookingInfo?.booking?.type === "WEEKLY"
          ? utcToTimezone(bookingInfo?.weeklyDate?.time, timezone)
          : bookingInfo?.flexibleDates
              ?.map((el) => el.label.split("at")[1])
              .join(", "),
    },
  ];
  const data2 = [
    {
      name: "Created by",
      value: `${bookingInfo?.selectedTeacher?.info?.user?.name} ${bookingInfo?.selectedTeacher?.info?.user?.lastName}`,
    },
    {
      name: "Billed to",
      value: bookingInfo?.selectedStudent?.label,
    },
    {
      name: "Booking type",
      value: bookingInfo?.booking?.subscription ? "Recurring" : "One-Time",
    },
  ];
  return (
    <SharedModalContainer
      title={"Booking Information"}
      isOpen={isOpen}
      onClose={closeModal}
      onCancel={closeModal}
      onSubmit={onSubmit}
      submitButtonName={submitButtonName}
    >
      <div className="booking_inputs">
        <BookingInfoWrapper data={data} title={"lesson details"} />
        <BookingInfoWrapper data={data2} title={"booking details"} />
      </div>
    </SharedModalContainer>
  );
};

export default ConfirmBookingModal;

import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import "./InviteFriends.styles.scss";
import copy from "../../assets/copy.svg";
// import gmail from "../../assets/gmail.svg";
// import facebook from "../../assets/facebook.svg";
// import instagram from "../../assets/instagram.svg";
// import gmail2 from "../../assets/gmail2.svg";
// import twitter from "../../assets/twitter.svg";
// import whatsapp from "../../assets/whatsapp.svg";
import { useDispatch, useSelector } from "react-redux";
import { getStudentById } from "../../../ReduxToolkit/Slices/studentSlice";
import {
  inviteFriends,
  // sendEmailNotification,
} from "../../../ReduxToolkit/Slices/userSlice";
import { toast } from "react-toastify";
import { Steps } from "intro.js-react";

const SocialIcon = ({ icon, platform }) => {
  return (
    <div className="SocialIcon_Container flex_col">
      <img alt="" src={icon} />
      <p>{platform}</p>
    </div>
  );
};

function InviteFriends() {
  const { student } = useSelector((state) => state.student);

  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [message, setMessage] = useState({
    to: "",
    subject: `${user.name} has invited you to join him on Educify and start you learning together!`,
    text: `You have been invited by ${user.name}!`,
    otp: "",
    html: "",
  });
  const [copied, setCopied] = useState(false);
  const [referalCode, setReferal] = useState("");
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    dispatch(getStudentById(student)).then((res) =>
      setReferal(res.payload.data.inviteCode)
    );
  }, []);

  const notify = (message) => toast.success(message);
  const sendError = (message) => toast.error(message);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const sendEmail = () => {
    if (!validateEmail(message.to)) {
      return sendError("Please add a correct email address");
    }
    if (!message.to) {
      return sendError("Please add receiver's email address");
    }
    setFetching(true);
    dispatch(inviteFriends({ email: message.to })).then((res) => {
      setFetching(false);
      if (res.type === "inviteFriends/fulfilled") {
        notify(`Invitation sent to ${message.to}, thank you!`);
        setMessage({
          ...message,
          to: "",
        });
      } else {
        sendError("Error sending email");
        setMessage({
          ...message,
          to: "",
        });
      }
    });
  };

  const handleClick = (referal) => {
    navigator.clipboard.writeText(referal);
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      sendEmail();
    }
  };

  const [showGuide, setShowGuide] = useState(true);
  const { pathname } = useLocation();
  const steps = [
    {
      element: ".InviteFriends_Container",
      title: "Invite your friends",
      intro: "Here you can invite your friends by typing their email address",
    },
    {
      element: ".row1",
      title: "Invitation Code",
      intro:
        "by sharing your invitation code you can earn 15% when someone enters your code when registering",
    },
  ];

  return (
    <div className="InviteFriends_Container std_invite_container">
      {showGuide && pathname.includes("invite") && (
        <Steps
          steps={steps}
          enabled={user.helpGuide}
          initialStep={0}
          onExit={() => setShowGuide(false)}
          options={{ exitOnOverlayClick: false }}
        />
      )}
      <div className="InviteFriends_Subcontainer">
        <h4>Invite a Friend</h4>
        <p className="link">
          If you need more info, please check out{" "}
          <Link to="/students/faq">FAQ Page.</Link>
        </p>
        <div className="row1 flex_center">
          <p className="referal">Referal Code</p>
          <div className="referal_input flex_center animatedCopy">
            <p> {referalCode} </p>
            {copied ? (
              <div class="wrapper">
                {" "}
                <svg class="animated-check" viewBox="0 0 24 24">
                  <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" />{" "}
                </svg>
              </div>
            ) : (
              <img alt="" src={copy} onClick={() => handleClick(referalCode)} />
            )}
          </div>
        </div>
        {/* <div className="invite_container flex_center">
          <img alt="gmail" src={gmail} />
          <p>Invite Gmail Contacts</p>
        </div> */}
        {/* <div className="social_container flex_center">
          <SocialIcon platform="Facebook" icon={facebook} />
          <SocialIcon platform="Instagram" icon={instagram} />
          <SocialIcon platform="Twitter" icon={twitter} />
          <SocialIcon platform="Whatsapp" icon={whatsapp} />
          <SocialIcon platform="Gmail" icon={gmail2} />
        </div> */}
        <input
          type="text"
          placeholder="Type or paste email"
          className="message_input"
          value={message.to}
          onKeyDown={(e) => handleKeyDown(e)}
          onChange={(e) => setMessage({ ...message, to: e.target.value })}
        />
        <button
          className={fetching ? "fetching" : ""}
          onClick={() => sendEmail()}
        >
          Send invitation
        </button>
      </div>
    </div>
  );
}

export default InviteFriends;

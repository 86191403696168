import React from "react";
import "./progressBar.styles.scss";
const ProgressBar = ({ progress }) => {
  return (
    <div className="progress-container">
      <div
        className="progress-bar"
        style={{
          width: `${parseInt(progress)}%`,
          height: "100%",
        }}
      />
    </div>
  );
};

export default ProgressBar;

import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import "./StudentApp.styles.scss";
import SharedLayout from "../Components/SharedDashboardLayout/SharedLayout/SharedLayout";
import MyClasses from "./Pages/MyClasses/MyClasses";
import MyWallets from "./Pages/MyWallets/MyWallets";
import MyMessages from "./Pages/MyMessages/MyMessages";
import InviteFriends from "./Pages/InviteFriends/InviteFriends";
import FAQ from "./Pages/FAQ/FAQ";
import AccountSettings from "../TeacherApp/Pages/AccountSettings/AccountSettings";
import StudentContact from "./Components/StudentContactus/StudentContact";
import StudentPaymentMethods from "./Components/StudentPaymentsMethod/StudentPaymentMethods";
import Tutors from "../Sections/Tutors/Tutors";
// import Notifications from "../SharedApp/Pages/Notifications/Notifications";
import Notifications from "../StudentApp/Pages/Courses/Notifications/Notifications";

import PromoCodes from "./Pages/PromoCodes/PromoCodes";
import { CheckLoggedIn } from "../hooks";
import { useDispatch, useSelector } from "react-redux";
import {
  getStudentById,
  setStudent,
} from "../ReduxToolkit/Slices/studentSlice";
import NotFound from "../SharedApp/Pages/404/NotFound";
import CoursesLanding from "./Pages/Courses/CoursesLanding/StudentCoursesLanding";
import { setUser } from "../ReduxToolkit/Slices/userSlice";
import StudentHomework from "../TeacherApp/Pages/StudentHomework/StudentHomework";
import AddStudentHomework from "./Pages/AddStudentHomework/AddStudentHomework";
import StudentCalendar from "./Pages/StudentCalendar/StudentCalendar";
import StudentNotes from "./Pages/StudentNotes/StudentNotes";
import StudentsSubscriptions from "./Pages/StudentsSubscriptions/StudentsSubscriptions";
import StudentAdminBooking from "./Pages/StudentAdminBooking/StudentAdminBooking";
import Chat from "./Pages/Chat/Chat";

function StudentApp() {
  const { showCourses } = useSelector((state) => state.user);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { student } = useSelector((state) => state.student);
  const { user, refreshUser } = useSelector((state) => state.user);

  useEffect(() => {
    let studentID = localStorage.getItem("studentId");
    if (!student && studentID) {
      dispatch(setStudent(JSON.parse(studentID)));
    }
    if (!user?.name && studentID) {
      dispatch(getStudentById(JSON.parse(studentID))).then((Res) => {
        dispatch(setUser(Res.payload.data.user));
      });
    }
    if (!CheckLoggedIn()) {
      navigate("/login/sessionExpired");
    } // change here optimize code
  }, [location, student]);

  useEffect(() => {
    // change here for redundancy
    let studentID = localStorage.getItem("studentId");
    studentID &&
      dispatch(getStudentById(JSON.parse(studentID))).then((Res) => {
        dispatch(setUser(Res.payload.data.user));
      });
  }, [refreshUser]);

  return !student ? (
    <div className="spinner" />
  ) : (
    <div className="StudentApp_Container">
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<SharedLayout />}>
          <Route path="/" element={<MyClasses />} />
          <Route path="/myclasses" element={<MyClasses />} />
          <Route path="/admin-booking" element={<StudentAdminBooking />} />
          <Route path="/mywallet" element={<MyWallets />} />
          <Route path="/mywallet/success" element={<MyWallets success />} />
          <Route path="/calendar" element={<StudentCalendar />} />
          <Route path="/notes" element={<StudentNotes />} />
          <Route path="/subscriptions" element={<StudentsSubscriptions />} />
          <Route path="/homework" element={<StudentHomework />} />
          <Route path="/homework/add" element={<AddStudentHomework />} />
          <Route path="/promo" element={<PromoCodes />} />
          <Route path="/mymessages" element={<Chat />} />
          <Route path="/invite" element={<InviteFriends />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route
            path="/findClasses"
            element={<Tutors customClass={"student__dash__tutors"} />}
          />
          <Route path="/faq" element={<FAQ />} />
          <Route
            path="/payment"
            element={
              <StudentPaymentMethods
                setIsCreditFilled={(e) => {}}
                setIsPaypalFilled={(e) => {}}
              />
            }
          />
          <Route path="/settings" element={<AccountSettings />} />
          <Route path="/contact" element={<StudentContact />} />
          {/* Courses */}
          {showCourses && (
            <>
              <Route path="/courses/" element={<CoursesLanding />} />
              <Route
                path="/courses/mylist"
                element={<CoursesLanding mylist />}
              />
            </>
          )}
        </Route>
      </Routes>
    </div>
  );
}
export default StudentApp;

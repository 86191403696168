import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { io, Socket } from "socket.io-client";
import { Tooltip } from "@mui/material";

import {
  MinChatUiProvider,
  MainContainer,
  MessageInput,
  MessageContainer,
  MessageList,
  MessageHeader,
} from "@minchat/react-chat-ui";
import notificationSound from "./notify.wav";
import "./Chat.styles.scss"; // Import your CSS file for custom styles
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import defaultPP from "../../../assets/SharedApp/images/default.webp";
import {
  getContacts,
  getUserChatMessages,
} from "../../../ReduxToolkit/Slices/userSlice";
import ContactsContainer from "./ContactsContainer";
import moment from "moment";

const token = localStorage.getItem("access_token");
const socket = io(import.meta.env.VITE_BASEURL2, {
  auth: {
    token,
  },
});

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState();
  const [isTyping, setIsTyping] = useState(false);
  const [userStatus, setUserStatus] = useState({});
  const [newMessage, setNewMessage] = useState(null);
  const [recipient, setRecipient] = useState({ name: "", profileImage: "" });
  const [recipientId, setRecipientId] = useState();
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getContacts()).then((res) => {
      if (res.type.includes("fulfilled")) {
        setUsers(res.payload);
        if (res.payload.length > 0)
          setRecipient({
            name: `${res?.payload[0]?.name} ${res?.payload[0]?.lastName}`,
            profileImage: res?.payload[0]?.profileImage?.url,
          });
        else {
          setRecipient({
            name: `Tutor`,
            profileImage: undefined,
            role: "User",
          });
        }
        setRecipientId(res.payload[0]?.id);
      }
    });
  }, [search]);
  const markMessageAsSeen = () => {
    socket.emit("message seen", {
      userId: user.id,
      recipientId,
    });
  };

  useEffect(() => {
    recipientId &&
      dispatch(getUserChatMessages(recipientId)).then((res) => {
        setMessages(res.payload.messages);
        setRecipient({
          profileImage: res?.payload?.profileImage,
          name: res?.payload?.name,
          role: res?.payload?.role,
        });
        markMessageAsSeen();
      });
  }, [recipientId, dispatch]);

  const audioRef = useRef(new Audio(notificationSound));

  useEffect(() => {
    // Join the shared room for the conversation
    if (user.id && recipientId) {
      socket.emit("join", user.id, recipientId);
    }
    socket.on("chat message", (msg) => {
      setMessages((prevMessages) => {
        // Prevent duplicate messages by checking if the message already exists
        if (!prevMessages.find((m) => m.id === msg.id)) {
          if (msg.senderId !== user.id) {
            audioRef.current.play();
            document.title = `New message from ${msg.name}`;
          }
          return [...prevMessages, msg];
        }
        return prevMessages;
      });
    });

    socket.on("typing", (userId, typingStatus) => {
      if (userId !== user.id) {
        setIsTyping(typingStatus);
      }
    });

    socket.on("message seen", (messageId) => {
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === messageId ? { ...msg, seen: true } : msg
        )
      );
    });

    socket.on("user status", ({ userId, status }) => {
      setUserStatus((prevStatus) => ({
        ...prevStatus,
        [userId]: status,
      }));
    });

    return () => {
      socket.off("chat message");
      socket.off("typing");
      socket.off("message seen");
      socket.off("user status");
    };
  }, [user?.id, recipientId]);

  const sendMessage = (content) => {
    if (content.trim() && recipientId) {
      const message = {
        content,
        recipientId,
        profileImage: user.profileImage?.url,
      };
      socket.emit("chat message", message);
      setInput("");
    }
  };

  const handleTypingEnd = () => {
    socket.emit("typing", recipientId, false);
  };

  const handleTypingStart = () => {
    socket.emit("typing", recipientId, true);
  };

  const handleSend = (message) => {
    sendMessage(message);
    handleTypingEnd();
  };
  const messageListRef = useRef(null);

  const scrollToBottom = () => {
    if (messageListRef.current) {
      messageListRef.current.scrollTo(0, messageListRef.current.scrollHeight);
    }
  };

  // useEffect(() => {
  //   scrollToBottom();
  // }, [messages]);

  // useEffect(() => {
  //   scrollToBottom();
  // }, [messages]);

  return (
    <div className="chat_page">
      <div className="chat_layout">
        <div className="all_contacts_container">
          <ContactsContainer
            setSearch={setSearch}
            contacts={users}
            messageIndex={recipientId}
            profilePic={defaultPP}
            setMesageIndex={(id) => setRecipientId(id)}
          />
        </div>
        <div className="chatting_container">
          <MinChatUiProvider theme="#235987">
            <MainContainer
            // style={{ height: "60vh", background: "white", width: "600px" }}
            >
              <MessageContainer>
                <div className="message_header">
                  <img
                    src={
                      recipient?.profileImage
                        ? recipient?.profileImage
                        : defaultPP
                    }
                    alt=""
                    className="avatar"
                  />
                  <div className="name">
                    <span>{recipient.name ?? "Tutor"}</span>
                    <p style={{ textTransform: "capitalize" }}>
                      {recipient?.role?.toLowerCase()}
                    </p>
                  </div>
                </div>
                <CustomMessageList
                  messages={messages}
                  userId={user.id}
                  userProfile={user?.profileImage?.url}
                  userStatus={userStatus}
                  isTyping={isTyping}
                />

                <MessageInput
                  placeholder="Type message here"
                  onSendMessage={handleSend} // Directly pass handleSend
                  showSendButton
                  onStartTyping={handleTypingStart}
                  onEndTyping={handleTypingEnd}
                />
              </MessageContainer>
            </MainContainer>
          </MinChatUiProvider>
        </div>
      </div>

      <div></div>
    </div>
  );
};

export default Chat;
const CustomMessageList = ({
  messages,
  userId,
  userStatus,
  isTyping,
  userProfile,
}) => {
  const messageListRef = useRef(null);
  const scrollToBottom = () => {
    if (messageListRef?.current) {
      messageListRef.current.scrollTop = messageListRef?.current?.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  // const getStatusIndicator = (status: string) => {
  //   switch (status) {
  //     case "online":
  //       return <span className="status-indicator online"></span>;
  //     case "away":
  //       return <span className="status-indicator away"></span>;
  //     default:
  //       return <span className="status-indicator offline"></span>;
  //   }
  // };
  const { timezone } = useSelector((state) => state.user);

  function convertUtcToTimezone(utcDateString, timezone) {
    // Parse the UTC date string
    const utcDate = moment.utc(utcDateString);

    // Convert the UTC date to the specified timezone
    const localDate = utcDate.tz(timezone);

    // Format the date string as desired
    return localDate.format("MMMM Do YYYY, h:mm A");
  }
  const { user } = useSelector((state) => state.user);

  const navigate = useNavigate();
  return (
    <div
      ref={messageListRef}
      style={{ overflowY: "auto", flexGrow: 1 }}
      className="chat-container"
    >
      <div className="all_user_messages">
        {messages &&
          messages.map((msg, i) => {
            const isOutgoing = msg.senderId === userId;
            let booking = msg.type === "booking" && user.role === "TEACHER";
            return (
              <Tooltip
                title={booking ? "Click to view request" : ""}
                arrow={booking}
              >
                <div
                  key={i}
                  onClick={() => {
                    if (booking) {
                      navigate("/teachers/myrequests");
                    }
                  }}
                  style={{
                    background:
                      msg.content ===
                      "Sharing contact information is not allowed. If this was repeated, we will suspend your account"
                        ? "red"
                        : "",
                    cursor: booking ? "pointer" : "inherit",
                  }}
                  className={`message-item ${
                    isOutgoing ? "outgoing" : "incoming"
                  }`}
                >
                  <div className="custom_message_header">
                    <div className="image_name">
                      <img
                        src={
                          // isOutgoing
                          //   ? msg?.sender?.profileImage?.url
                          //   : msg?.recipient?.profileImage?.url
                          //   ? msg?.recipient?.profileImage?.url

                          //     :

                          //     defaultPP
                          msg.profileImage
                        }
                        alt=""
                      />
                      <span>{msg?.senderName}</span>
                    </div>
                    <span className="msg_time">
                      {convertUtcToTimezone(msg.createdAt, timezone)}
                    </span>
                  </div>
                  {/* <img src={userInfo.avatar} alt="" className="avatar" /> */}
                  <div className="message-content">
                    {/* <div className="message-header">
                    <span className="username">{userInfo.name}</span>
                    {getStatusIndicator(userStatus[msg.senderId] || "offline")}
                  </div> */}
                    <p className="message-text">{msg.content}</p>
                    <div className="message-meta">
                      <span className="timestamp">
                        {/* {new Date(msg.createdAt).toLocaleTimeString()} */}
                      </span>
                      {/* {msg.seen && <span className="seen-indicator">Seen</span>} */}
                    </div>
                  </div>
                  {/* {msg.seen ? "seen" : "unseen"} */}
                </div>
              </Tooltip>
            );
          })}
      </div>

      {/* {isTyping && <span>...typing</span>} */}
    </div>
  );
};

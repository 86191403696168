import React, { useState, useEffect, useRef, useCallback } from "react";
import "./Tutors.styles.scss";
import { useDispatch, useSelector } from "react-redux";

import TutorProfile from "../../Components/TutorProfile/TutorProfile";
import Pagination from "../../Components/Pagination/Pagination";
import filterIcon from "../../assets/HomeApp/images/filter.png";
import filter from "../../assets/TeacherApp/assets/svgs/tune.svg";
import Panel from "./Panel";
import GroupLessonCard from "../../Components/GroupLesson/GroupLessonCard";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getAllLessons,
  getAllGroupLessons,
} from "../../ReduxToolkit/Slices/teacherSlice";

import { setStudentState } from "../../ReduxToolkit/Slices/studentSlice";
import { Steps } from "intro.js-react";
import { steps } from "./steps";
import { FilterModal } from "./FilterModal";
import Filter from "./Filter";
import { getCategoriesWithSubjects } from "../../ReduxToolkit/Slices/lessonsSlice";
import { CustomRangeSlider } from "./CustomRangeSlider";
import FilterPanel from "./FilterPanel";
import Button from "../../Components/Button/Button";

export default function Tutors({
  search,
  subjectSearch,
  locations,
  customClass,
}) {
  const params = useParams();
  const dispatch = useDispatch();

  const { lessonLocation } = useSelector((state) => state.student);
  const [currentPage, setCurrentPage] = useState(1);

  const filtersInitial = {
    // minPrice: "",
    // maxPrice: "",
    isGroup: false,
    startTime: "",
    bgChecked: "",
    gender: "",
    endTime: "",
    day: "",
    sortOrder: "",
    sortField: "",
    location: lessonLocation
      ? lessonLocation
      : locations
      ? params.location
      : "",
    latitude: "",
    longitude: "",
    distance: "",
    search: !search ? "" : params.search,
    subject: !subjectSearch ? "" : params.subject,
    category: !subjectSearch ? "" : params.category,
    page: currentPage,
    limit: "10",
  };

  const location = useLocation();
  const [page, setPage] = useState(1);
  const [categories, setCategories] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState(filtersInitial);

  const [previewed, setPreviewed] = useState();
  const [totalLessons, setTotalLessons] = useState(0);
  const [showPanelNew, setShowPanelNew] = useState(false);
  const [fetched, setFetched] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  const [lessons, setLessons] = useState([]);
  const [grouplessons, setGroupLessons] = useState([]);
  // const [filters, setFilters] = useState(filtersInitial);
  const [showPreview, setShowPreview] = useState(0);

  // const handleLatLang = (latitude, longitude) => {
  //   setFilters({ ...filters, longitude, latitude });
  // };
  // useEffect(() => {
  //   setFilters(filtersInitial);
  // }, [location]);

  // const resetFilter = () => {
  //   setCurrentPage(1);
  //   const filter = {
  //     // minPrice: "",
  //     // maxPrice: "",
  //     isGroup: filters.isGroup,
  //     startTime: "",
  //     endTime: "",
  //     day: "",
  //     location: filters.location,
  //     search: "",
  //     subject: "",
  //     gender: "",
  //     limit: filters.limit,
  //     category: "",
  //     distance: "",
  //     page: 1,
  //   };
  //   setFilters(filter);
  // };

  const handleSearchChange = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    handleChange(e.target.name, e.target.value);
  };

  //   const delay = 200; // Delay in milliseconds

  //   // Start a timeout to delay the effect execution

  //   const timeoutId = setTimeout(() => {
  //     const newFilters = {
  //       // ...filters,
  //       ...selectedFilters,
  //       // minPrice: filters?.minPrice ? Math.round(filters.minPrice) : 0,
  //       // maxPrice: filters?.maxPrice ? Math.round(filters.maxPrice) : 0,
  //     };
  //     if (selectedFilters.location) {
  //       setFetched(true);
  //       if (filters.isGroup) {
  //         dispatch(getAllGroupLessons(newFilters))
  //           .then((res) => {
  //             setPage(res.payload.data.numOfPages);
  //             setTotalLessons(res.payload.data.totalLessons);
  //             setGroupLessons(res.payload.data.lessons);
  //             setFetched(false);
  //           })
  //           .catch((err) => {
  //             setFetched(false);
  //           });
  //       } else {
  //         dispatch(getAllLessons(selectedFilters))
  //           .then((res) => {
  //             setTotalLessons(res?.payload?.data?.totalLessons);
  //             setPage(res?.payload?.data?.numOfPages);
  //             setLessons(res?.payload?.data?.lessons);
  //             setFetched(false);
  //           })
  //           .catch((err) => {
  //             setFetched(false);
  //           });
  //       }
  //     }
  //   }, delay);

  //   // Cleanup function to clear the timeout if the component unmounts or if the dependencies change
  //   return () => clearTimeout(timeoutId);
  // }, [selectedFilters]); // Dependencies array. Make sure all dependencies are listed here.
  useEffect(() => {
    const delay = 400; // Delay in milliseconds
    const timeoutId = setTimeout(() => {
      setFetched(true);
      dispatch(
        getAllLessons({
          ...selectedFilters,
          sortField: "price",
        })
      ).then((res) => {
        setTotalLessons(res?.payload?.data?.totalLessons);
        setPage(res?.payload?.data?.numOfPages);
        setLessons(res?.payload?.data?.lessons);
        setFetched(false);
      });
    }, delay);
    return () => clearTimeout(timeoutId);
  }, [selectedFilters, params.subject, params.category]);
  // const handleIsGroup = (value) => {
  //   setCurrentPage(1);
  //   setFilters({ ...filters, isGroup: value, page: 1 });
  // };

  const [pixels, setPixels] = useState("0");

  const handlePageChange = (number) => {
    setCurrentPage(number);
    setSelectedFilters({ ...selectedFilters, page: number });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location]);

  const [showGuide, setShowGuide] = useState(true);

  // new stuff here

  useEffect(() => {
    dispatch(getCategoriesWithSubjects()).then((res) => {
      if (res.type.includes("fulfilled")) {
        const categoriesData = res?.payload?.data?.categories;
        setCategories(categoriesData);
        const initialCategory = categoriesData[0]; // Assuming the first category is selected initially
        setSubjects(initialCategory?.subjects);
      }
    });
  }, []);

  useEffect(() => {
    const selectedCategory = categories?.find(
      (cat) => cat?.name === selectedFilters?.category
    );

    if (selectedCategory) {
      setSubjects(selectedCategory.subjects);
    }
  }, [selectedFilters?.category, selectedFilters]);

  const handleChange = (name, value) => {
    if (name === "location") {
      dispatch(
        setStudentState({
          name: "lessonLocation",
          value,
        })
      );
    }
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      if (name === "category") {
        updatedFilters.subject && delete updatedFilters.subject;
      }
      // If the selected value is empty, remove the filter
      if (value === "") {
        delete updatedFilters[name];
      } else {
        updatedFilters[name] = value;
      }

      return updatedFilters;
    });
  };
  const locationFilters = [
    {
      name: "location",
      title: "Location",
      filterName: "Lesson Location",
      options: [
        { name: "online", value: "online" },
        { name: "student place", value: "studentPlace" },
        { name: "teacher place", value: "teacherPlace" },
      ],
    },
  ];
  const filterOptions = [
    // {
    //   name: "location",
    //   title: "Location",
    //   filterName: "Lesson Location",
    //   options: [
    //     { name: "online", value: "online" },
    //     { name: "student place", value: "studentPlace" },
    //     { name: "teacher place", value: "teacherPlace" },
    //   ],
    // },
    {
      name: "category",
      title: "Category",
      filterName: "Category",
      options: categories?.map((el) => {
        return { name: el?.name, value: el?.name };
      }),
    },
    {
      name: "subject",
      title: "Subject",
      filterName: "subject",
      options: subjects.map((el) => {
        return { name: el?.name, value: el?.name };
      }),
    },

    {
      name: "day",
      title: "Availability",
      filterName: "day",
      options: [
        { name: "Monday", value: "MONDAY" },
        { name: "Tuesday", value: "TUESDAY" },
        { name: "Wednesday", value: "WEDNESDAY" },
        { name: "Thursday", value: "THURSDAY" },
        { name: "Friday", value: "FRIDAY" },
        { name: "Saturday", value: "SATURDAY" },
        { name: "Sunday", value: "SUNDAY" },
      ],
    },
  ];
  const otherFilterOptions = [
    {
      name: "bgCheck",
      title: "Background Checked",
      filterName: "bgCheck",
      options: [
        { name: "Yes", value: "ACCEPTED" },
        { name: "No", value: "UNCHECKED" },
      ],
    },
    {
      name: "gender",
      title: "Teacher's Gender",
      filterName: "gender",
      options: [
        { name: "Male", value: "MALE" },
        { name: "Female", value: "FEMALE" },
      ],
    },
  ];
  const sortFilters = [
    {
      name: "sortOrder",
      title: "Sort By",
      filterName: "sortOrder",
      options: [
        { name: "Price ascending", value: "asc" },
        { name: "Price descending", value: "desc" },
        { name: "Highest rating", value: "rating" },
      ],
    },
  ];

  const [priceValue, setPriceValue] = useState([0, 500]);
  const [distanceValue, setDistanceValue] = useState([0, 25]);
  const [showPanel, setShowPanel] = useState(false);

  const { user } = useSelector((state) => state.user);
  return (
    <div className={`tutors__section ${customClass ? customClass : ""}`}>
      <div className="inner_max">
        {showGuide &&
        (grouplessons?.length || lessons?.length) &&
        location.pathname === "/students/findClasses" ? (
          <Steps
            enabled={user?.helpGuide}
            steps={steps}
            initialStep={0}
            onExit={() => setShowGuide(false)}
            options={{ exitOnOverlayClick: false }}
          />
        ) : (
          ""
        )}
        {/* <div className="lessontype_filters">
        <p
          className="filter_1"
          style={
            !filters.isGroup
              ? {
                  color: "#00a3ff",
                  backgroundColor: "white",
                }
              : {
                  color: "rgba(0,0,0,.3)",
                  backgroundColor: "#FAFAFC",
                }
          }
          onClick={() => handleIsGroup(false)}
        >
          Private classes
        </p>
        <p
          className="filter_2"
          style={
            filters.isGroup
              ? {
                  color: "#00a3ff",
                  backgroundColor: "white",
                }
              : {
                  color: "rgba(0,0,0, .3)",
                  backgroundColor: "#FAFAFC",
                }
          }
          onClick={() => handleIsGroup(true)}
        >
          Group classes
        </p>
        {showCourses && (
          <p
            className="filter_3"
            style={{
              color: "rgba(0,0,0, .3)",
              backgroundColor: "#FAFAFC",
            }}
            onClick={() => navigate("/courses/all")}
          >
            Courses
          </p>
        )}
      </div> */}
        <div className="search__tutors__section">
          {/* <div className="filter">
          <div
            className="fitler__btn__mobile"
            onClick={() => setShowPanelNew(!showPanelNew)}
          >
            <img src={filter} alt="" />
          </div>
      
        </div> */}
          <input
            type="text"
            placeholder="Search for lessons"
            value={selectedFilters.search}
            name="search"
            onChange={(e) => handleSearchChange(e)}
            className="tutors__search"
          />
          {fetched && <div className="spinner" />}
        </div>
        {showPanel && (
          <FilterPanel
            filterOptions={[
              ...locationFilters,
              ...filterOptions,
              ...otherFilterOptions,
            ]}
            onChange={handleChange}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            showPanel={showPanel}
            setShowPanel={setShowPanel}
          />
        )}

        <div className="lesson_filters_container">
          <div className="filter_btn_container">
            <button
              className="filter-button"
              onClick={() => setShowPanel(true)}
            >
              Filter <img src={filterIcon} alt="" />
            </button>
          </div>
          <div className="lesson_filters lesson_filters_hide">
            {locationFilters.map((filter) => (
              <Filter
                key={filter.name}
                title={filter.title}
                name={filter?.name}
                options={filter?.options?.map((option) => ({
                  name: option?.name,
                  value: option?.value,
                  checked: selectedFilters[filter.name] === option?.value,
                }))}
                type="radio"
                onChange={handleChange} // Pass the handleChange function
                selectedFilters={selectedFilters}
              >
                {selectedFilters?.location !== "online" && (
                  <CustomRangeSlider
                    className={"filter_drop_slider"}
                    onChange={handleChange}
                    name={"distance"}
                    name2={"distance"}
                    symbol={"km"}
                    max={25}
                    min={0}
                    value={distanceValue}
                    setValue={setDistanceValue}
                    isFirstPointDisabled={true}
                  />
                )}
              </Filter>
            ))}
            {filterOptions.map((filter) => (
              <Filter
                key={filter.name}
                title={filter.title}
                name={filter?.name}
                options={filter?.options?.map((option) => ({
                  name: option?.name,
                  value: option?.value,
                  checked: selectedFilters[filter.name] === option?.value,
                }))}
                type="radio"
                onChange={handleChange} // Pass the handleChange function
                selectedFilters={selectedFilters}
              />
            ))}

            <Filter
              key={"Price Slider"}
              title={"Price"}
              name={"price"}
              type="custom"
              selectedFilters={selectedFilters}
              onChange={handleChange}
              className="price_filter_dropDown"
            >
              <CustomRangeSlider
                className={"filter_drop_slider"}
                onChange={handleChange}
                name={"minPrice"}
                name2={"maxPrice"}
                prefix="$"
                symbole=""
                value={priceValue}
                setValue={setPriceValue}
              />
            </Filter>
            <Filter
              key={"More"}
              title={"More"}
              name={""}
              type="custom"
              selectedFilters={selectedFilters}
              onChange={handleChange}
              className="price_filter_dropDown more_filter_outer_container"
            >
              <div className="more_filters">
                {otherFilterOptions?.map((filter) => (
                  <div className="more_filter_container">
                    <div className="more_filter">
                      <h3>{filter?.title}</h3>
                      <div className="options">
                        {filter?.options?.map((option) => (
                          <div key={option?.value} className={`dropdown-item`}>
                            <label className="option-label">
                              <input
                                type="radio"
                                value={option?.value}
                                checked={
                                  selectedFilters[filter?.filterName] ===
                                  option?.value
                                }
                                onClick={() => {
                                  handleChange(
                                    filter?.filterName,
                                    option?.value
                                  );
                                }}
                              />
                              {option?.name}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Filter>
            {/* <button
              className="rejected"
              onClick={() => {
                setSelectedFilters({ location: "online" });
              }}
            >
              Reset
            </button> */}
            {/* Example of a custom component being passed */}
            <div className="filters_reset">
              <button
                className="rejected"
                onClick={() => {
                  setSelectedFilters({});
                  setDistanceValue([0, 200]);
                  setPriceValue([0, 500]);
                }}
              >
                Reset
              </button>
            </div>

            {/* <Button onClick={() => setShowPanel(true)}>Open</Button> */}
          </div>
          <div className="lesson_filters">
            {sortFilters.map((filter) => (
              <Filter
                key={filter.name}
                title={filter.title}
                name={filter?.name}
                options={filter?.options?.map((option) => ({
                  name: option?.name,
                  value: option?.value,
                  checked: selectedFilters[filter.name] === option?.value,
                }))}
                type="radio"
                onChange={handleChange} // Pass the handleChange function
                selectedFilters={selectedFilters}
                className={"sort_by_container"}
              />
            ))}
          </div>
        </div>

        <div className="Tutors__container">
          {/* <div
          className={`tutor__filter__panel ${
            showPanelNew ? "tutor__filter__panel__active" : "none_display"
          }`}
        >
          <Panel
            fetched={fetched}
            setShowPanel={setShowPanelNew}
            showPanel={showPanelNew}<F
            resetFilter={resetFilter}
            width={width}
            handleLatLang={handleLatLang}
            handleFilterChange={handleFilterChange}
            filters={filters}
            setFilters={setFilters}
          />
        </div> */}

          <div
            // className={filters.isGroup ? "maxWidth" : "tutors__list__container"}
            className={"tutors__list__container"}
          >
            <>
              <div className={"tutors__list"}>
                {/* <div className={filters.isGroup ? "tutors_grid" : "tutors__list"}> */}
                {
                  // filters?.isGroup
                  // ? grouplessons?.map((lesson) => {
                  //     return <GroupLessonCard lesson={lesson} />;
                  // })

                  //   :

                  lessons?.map((lesson, i) => {
                    const tutor = lesson?.teacher;
                    let prices = tutor?.lessons.map((lesson) => {
                      const currentDate = new Date();
                      const endDate = new Date(lesson.discountEndDate);
                      const discount =
                        lesson?.discount &&
                        lesson.discount > 0 &&
                        endDate > currentDate
                          ? lesson.discount
                          : 0;
                      return lesson[`${selectedFilters?.location}Price`]
                        ? {
                            price: lesson[`${selectedFilters?.location}Price`],
                            discount,
                          }
                        : 0;
                    });

                    return (
                      <>
                        <TutorProfile
                          lesson={tutor.lessons}
                          levels={tutor.levels}
                          tutor={tutor}
                          key={tutor.id}
                          id={tutor.id}
                          index={i}
                          name={tutor.user?.name}
                          image={tutor.user?.profileImage}
                          email={tutor.user?.email}
                          description={tutor.description}
                          isVerified
                          rating={tutor?.rating.toFixed(2)}
                          showPreview={showPreview}
                          setShowPreview={setShowPreview}
                          location={tutor.location}
                          minPrice={tutor.hourlyRate}
                          discount={0}
                          pixels={pixels}
                          teacher={tutor}
                          setPixels={setPixels}
                          setPreviewed={setPreviewed}
                        />
                      </>
                    );
                  })
                }
              </div>
              {/* {fetched && !filters.isGroup && (
                <TutorPreview
                  pixels={pixels}
                  teacher={lessons[previewed]}
                  lessons={lessons}
                  previewed={previewed}
                />
              )} */}
            </>
          </div>
        </div>
        <Pagination
          page={page}
          setCurrentPage={handlePageChange}
          currentPage={currentPage}
          dataRendered={lessons?.length}
          totalData={totalLessons}
        />
      </div>
    </div>
  );
}

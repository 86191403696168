import { useRef, useState } from "react";
import { customStyles } from "../Booking/selectStyles";

import CreateBookingModal from "./CreateBookingModal";
import ConfirmBookingModal from "./ConfirmBooking";
import AddClientModal from "../../Components/AddClientModal/AddClientModal";
import { toast } from "react-toastify";
import { validateEmail } from "../../../hooks";

/**
 * BookingPage Component
 *
 * @component
 * @example
 * return (
 *   <BookingPage />
 * )
 */

const BookingPage = ({
  isModalOpen,
  openModal,
  closeModal,
  setIsModalOpen,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showCreateClient, setShowCreateClient] = useState(false);
  const [showConfirmBooking, setShowConfirmBooking] = useState(false);
  const [isCreatingStudent, setIsCreatingStudent] = useState(false);

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  /**
   * Handles changes in the selected options.
   *
   * @param {Array} selected - The selected options.
   */

  const handleChange = (selected) => {
    setSelectedOptions(selected);
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [studentToCreate, setStudentToCreate] = useState({
    name: "",
    email: "",
    lastName: "",
    country: "",
    phone: "",
    // address: "",
    city: "",
    geoLocation: { longitude: "", latitude: "", address: "" },
  });

  const addClientSubmit = () => {
    const { name, lastName, email, city, country, phone } = studentToCreate;
    const { longitude, latitude, address } = studentToCreate.geoLocation;
    if (isCreatingStudent) {
      if (!name) return toast.warning("Missing student name");
      if (!lastName) return toast.warning("Missing student last name");
      if (!email) return toast.warning("Missing student email");
      if (!validateEmail(email)) return toast.warning("Incorrect Email");
      if (!phone) return toast.warning("Missing student phone");
      if (!city) return toast.warning("Missing student city");
      if (!country) return toast.warning("Missing student country");
      if (!longitude) return toast.warning("Missing student longitude");
      if (!latitude) return toast.warning("Missing student latitude");
      if (!address) return toast.warning("Missing student address");
    }
    if (
      name &&
      lastName &&
      email &&
      city &&
      country &&
      phone &&
      longitude &&
      latitude &&
      address
    ) {
      setShowConfirmBooking(false);
      setShowCreateClient(false);
    } else {
      toast.warning("Missing info");
    }
  };
  const [bookingInfo, setBookingInfo] = useState();
  return (
    <div>
      {isModalOpen && (
        <CreateBookingModal
          isOpen={isModalOpen}
          closeModal={() => {
            setStudentToCreate({});
            closeModal();
          }}
          selectedOptions={selectedOptions}
          handleChange={handleChange}
          customStyles={customStyles}
          options={options}
          onIconClick={() => {
            setShowCreateClient(true);
            setIsCreatingStudent(true);
            // setIsModalOpen(false);
          }}
          onSubmit={() => {
            setShowConfirmBooking(true);
            setStudentToCreate({});
          }}
          studentToCreate={studentToCreate}
          isCreatingStudent={isCreatingStudent}
          setIsCreatingStudent={setIsCreatingStudent}
          setShowConfirmBooking={setShowConfirmBooking}
          setBookingInfo={setBookingInfo}
        />
      )}
      {showCreateClient && (
        <AddClientModal
          isOpen={showCreateClient}
          closeModal={() => setShowCreateClient(false)}
          customStyles={customStyles}
          setStudentToCreate={setStudentToCreate}
          studentToCreate={studentToCreate}
          onSubmit={addClientSubmit}
          onCancel={() => {
            setStudentToCreate({});
            setShowConfirmBooking(false);
            setShowCreateClient(false);
            setIsCreatingStudent(false);
          }}
          isCreatingStudent={isCreatingStudent}
          setIsCreatingStudent={setIsCreatingStudent}
        />
      )}
      {showConfirmBooking && (
        <ConfirmBookingModal
          isOpen={showConfirmBooking}
          bookingInfo={bookingInfo}
          closeModal={() => {
            setShowConfirmBooking(false);
            closeModal();
            setStudentToCreate({});
          }}
          onSubmit={() => {
            setShowConfirmBooking(false);
            setShowCreateClient(false);
            setIsModalOpen(false);
            setStudentToCreate({});
          }}
          submitButtonName={"Send Invoice"}
        />
      )}
    </div>
  );
};
export default BookingPage;

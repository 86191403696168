import { useState } from "react";
import selectArrowDown from "../../../assets/AdminApp/svgs/select_arrow_down.svg";
import SharedModalContainer from "../../Pages/Booking/SharedModalContainer";
import BookingTextInput from "../BookingTextInput/BookingTextInput";
import BookingInput from "../BookingInput/BookingInput";
import BookingPhoneInput from "../BookingPhoneInput/BookingPhoneInput";
import { countryList } from "../../../assets/SharedApp/data/CountryList";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
const AddClientModal = ({
  isOpen,
  closeModal,
  customStyles,
  onSubmit,
  onCancel,
  studentToCreate,
  setStudentToCreate,
}) => {
  const [phone, setPhone] = useState("");
  const [value, setValue] = useState(studentToCreate?.locationValue);
  const handleStudentChange = (e) => {
    const { name, value } = e?.target;
    setStudentToCreate({ ...studentToCreate, [name]: value });
  };
  const onValueChange = (e) => {
    setValue(e);
    // setAddress(e.label);
    // add to student to create
    geocodeByAddress(e.label)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setStudentToCreate({
          ...studentToCreate,
          geoLocation: {
            longitude: lat,
            latitude: lng,
            address: e.label,
          },
          locationValue: e,
        });
      });
  };
  return (
    <SharedModalContainer
      title={"Add Client"}
      isOpen={isOpen}
      onClose={closeModal}
      onCancel={onCancel}
      onSubmit={onSubmit}
    >
      <div className="booking_inputs">
        <BookingTextInput
          label={"Name"}
          placeholder={"Client name"}
          onChange={handleStudentChange}
          value={studentToCreate?.name}
          name={"name"}
        />
        <BookingTextInput
          label={"Last name"}
          placeholder={"last name"}
          onChange={handleStudentChange}
          value={studentToCreate?.lastName}
          name={"lastName"}
        />
        <BookingTextInput
          label={"Email"}
          placeholder={"Client email address"}
          onChange={handleStudentChange}
          name={"email"}
          value={studentToCreate?.email}
        />
        <BookingPhoneInput
          label={"Phone number"}
          placeholder={"Client phone number"}
          onChange={(e) => {
            setPhone(e);
            setStudentToCreate((oldStudent) => {
              return {
                ...oldStudent,
                phone: e,
              };
            });
          }}
          value={studentToCreate?.phone}
          name={"phone"}
        />
        <BookingInput
          label="Country"
          customStyles={customStyles}
          options={countryList.map((country) => {
            return {
              label: country,
              value: country,
            };
          })}
          placeholder="Select country..."
          icon={selectArrowDown}
          imgWithMargin
          onChange={(val) =>
            setStudentToCreate({ ...studentToCreate, country: val?.value })
          }
          name={"country"}
          value={
            studentToCreate?.country && {
              value: studentToCreate?.country,
              label: studentToCreate?.country,
            }
          }
        />

        {/* <BookingTextInput
          label={"Address"}
          placeholder={"Enter address"}
          onChange={handleStudentChange}
          value={studentToCreate?.address}
          name={"address"}
        /> */}
        <BookingTextInput
          label={"City"}
          placeholder={"Enter city"}
          onChange={handleStudentChange}
          name={"city"}
          value={studentToCreate?.city}
        />
        <div className="booking_input">
          <span className="input_name">Address</span>
          <div className="input">
            <GooglePlacesAutocomplete
              apiKey={import.meta.env.VITE_GOOGLEPLACE}
              selectProps={{
                value,
                onChange: onValueChange,
                className: "add_client_address_select",
              }}
            />
          </div>
        </div>
        {/* <BookingTextInput
          label={"Zip"}
          placeholder={"Enter Zip code"}
          onChange={handleStudentChange}
          value={studentToCreate?.zip}
          name={"zip"}
        /> */}
        {/* <BookingTextInput
          label={"Password"}
          placeholder={"Enter student's password"}
          onChange={handleStudentChange}
          name={"password"}
          type="password"
          value={studentToCreate?.password}
        /> */}
      </div>
    </SharedModalContainer>
  );
};

export default AddClientModal;

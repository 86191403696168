import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { isPossiblePhoneNumber } from "react-phone-number-input";

import {
  handleUserChange,
  updateUser,
} from "../../../ReduxToolkit/Slices/userSlice";
import SharedSingleImageUploader from "../../../Components/SharedSingleImageUploader/SharedSingleImageUploader";

export default function AdminTeacherDetails({
  missing,
  setRefresh,
  refresh,
  onCancelClick,
  recommend,
  className,
  setProfileImage,
  teacher,
}) {
  const dispatch = useDispatch();

  const stateUser = useSelector((state) => state.user.user);

  const [fetching, setFetching] = useState(false);
  const [user, setUser] = useState(teacher);

  const updateUsers = () => {
    setFetching(true);
    dispatch(
      updateUser({
        user: {
          name: user?.name,
          lastName: user?.lastName,
          email: user?.email,
          phone: user?.phone,
          helpGuide: user.helpGuide ? user.helpGuide : false,
          description: user?.description,
          address: {
            state: user?.address?.state ? user?.address?.state : "state",
            country: user?.address?.country
              ? user?.address?.country
              : "country",
            city: user?.address?.city ? user?.address?.city : "city",
            street: user?.address?.street ? user?.address?.street : "street",
            zipCode: user?.address?.zipCode
              ? user?.address?.zipCode
              : "zipCode",
          },
          profileImage: {
            url: user?.profileImage?.url ? user?.profileImage?.url : "",
            publicId: user?.profileImage?.publicId
              ? user?.profileImage?.publicId
              : "",
            id: user?.profileImage?.id ? user?.profileImage?.id : "",
          },
          nationality: user.nationality,
        },
        id: user.id,
      })
    )
      .then((res) => {
        setFetching(false);

        if (res.type === "updateUser/fulfilled") {
          toast.success("Thank you, your profile was updated!");
          return dispatch(
            handleUserChange({
              name: "refreshUser",
              value: !stateUser.refreshUser,
            })
          );
        } else {
          toast.error(res.payload);
        }
      })
      .catch((err) => {
        setFetching(false);
      });
  };

  const onSubmit = () => {
    if (recommend) {
      return onCancelClick();
    } else {
      if (isPossiblePhoneNumber(user.phone)) {
        updateUsers();
        missing && setRefresh(!refresh);
      } else {
        toast.error("Phone number not valid");
      }
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === "helpGuide") {
      return setUser({ ...user, helpGuide: !user.helpGuide });
    }
    setUser({ ...user, [name]: value });
  };

  const onSelectFile = (value) => {
    if (recommend) setProfileImage(value);
    else setUser({ ...user, profileImage: value });
  };
  const MAX_SIZE = 4 * 1024 * 1024; // Maximum allowed file size in bytes (2MB)
  return (
    <div
      className={
        missing
          ? "account__settings missing"
          : `account__settings ${className ?? ""}`
      }
    >
      <div className="settings__container">
        {!recommend ? (
          <div className="header">
            {!missing ? (
              <h3>Profile Details</h3>
            ) : (
              <h3>
                Please Update Your Address Before You Book A Lesson At Your
                Place
              </h3>
            )}
          </div>
        ) : (
          <div className="header">
            <h3>Please add your info before submitting a recomendation.</h3>
          </div>
        )}
        <div className="account__rows">
          {!recommend && (
            <div className="account__row">
              <span className="title">Tour Guide</span>
              <div>
                <input
                  type="checkbox"
                  value={user.helpGuide}
                  checked={user.helpGuide}
                  name="helpGuide"
                  onChange={(e) => onChange(e)}
                />
                <label>Enable Tour Guide</label>
              </div>
            </div>
          )}

          <div className="account__row">
            <span className="title">Profile Photo</span>
            <div className="image">
              <div className="image__selector">
                {/* <h1>Image uploader</h1> */}
                <SharedSingleImageUploader
                  onSelectFile={onSelectFile}
                  detectFace
                  preview={user.profileImage}
                  // tip={`Image size must be  less then ${MAX_SIZE / (1024 * 1024)} MB.`}
                  MAX_SIZE={MAX_SIZE}
                />
              </div>
            </div>
          </div>

          <div className="account__row">
            <span className="title">Full Name</span>
            <div className="inputs__multiple">
              <input
                type="text"
                placeholder="First Name"
                value={user.name}
                name="name"
                onChange={(e) => onChange(e)}
              />
              <input
                type="text"
                placeholder="Last Name"
                value={user.lastName}
                name="lastName"
                onChange={(e) => onChange(e)}
              />
            </div>
          </div>
          {!user.bySocial ||
            (recommend && (
              <div className="account__row">
                <span className="title">Email Address</span>
                <input
                  type="text"
                  placeholder="jhonDoe@example.com"
                  value={user.email}
                  name="email"
                  onChange={(e) => onChange(e)}
                />
              </div>
            ))}
          {!recommend && (
            <>
              <div className="account__row">
                <span className="title">Phone Number</span>
                <input
                  type="text"
                  placeholder="phone number"
                  value={user.phone}
                  onChange={(e) =>
                    setUser({
                      ...user,
                      phone: e.target.value,
                    })
                  }
                />
              </div>
            </>
          )}
        </div>

        <div className="account__Settings__btns">
          {!recommend ? (
            <button />
          ) : (
            <button className="delete" onClick={() => onCancelClick()}>
              Cancel
            </button>
          )}

          <button
            className={
              fetching ? "account__submit fetching" : "account__submit"
            }
            onClick={() => onSubmit()}
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  );
}

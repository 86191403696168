import React, { useState } from "react";
import delBtn from "../../../assets/SVG/del-btn.svg";
import editBtn from "../../../assets/SVG/edit-btn.svg";
import arrRight from "../../../assets/SVG/arrRight.svg";
import Button from "../../../Components/Button/Button";
import closeBtn from "/src/assets/AdminApp/svgs/close.svg";
import { toast } from "react-toastify";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleSliceChange } from "../../../ReduxToolkit/Slices/teacherSlice";
import SharedSingleImageUploader from "../../../Components/SharedSingleImageUploader/SharedSingleImageUploader";

const SignupStep13 = () => {
  const teacherToRegister = useSelector(
    (state) => state?.teacher?.teacherToRegister
  );
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [educations, setEducations] = useState(
    teacherToRegister?.education ? teacherToRegister.education : []
  );
  const [image, setImage] = useState();
  const [education, setEducation] = useState({
    school: "",
    degree: "",
    startDate: "",
    endDate: "",
    field: "",
    grade: "",
    image: { url: "", id: "", publicId: "" },
  });

  const handleEducationChange = ({ name, value }) => {
    setEducation({ ...education, [name]: value });
  };
  const handleCloseModal = () => {
    setEducation({
      school: "",
      degree: "",
      startDate: "",
      endDate: "",
      field: "",
      grade: "",
      image: { url: "", id: "", publicId: "" },
    });
    setShow(false);
  };
  const handleAddEducation = () => {
    if (!validateCertification()) return;

    if (editIndex !== null) {
      // Edit mode
      const updatedEducations = educations.map((exp, index) =>
        index === editIndex ? education : exp
      );
      setEducations(updatedEducations);
    } else {
      // Add mode
      setEducations([...educations, education]);
    }

    // Reset the image state after adding or editing the certification
    setImage(null);

    // Close the modal and reset the certification state
    handleCloseModal();
  };

  const handleDeleteEducation = (index) => {
    const updatedEducations = educations.filter((_, i) => i !== index);
    setEducations(updatedEducations);
  };

  const handleEditEducation = (index) => {
    const educationToEdit = educations[index];
    setEducation(educationToEdit); // Set the education data to be edited
    setImage(educationToEdit.image); // Set the image for editing
    setShow(true); // Open the modal to edit the experience
    setEditIndex(index); // Set the index of the experience being edited
  };

  const validateCertification = () => {
    const { school, degree, startDate, endDate, field, grade } = education;
    const missingFields = [];

    if (!school) missingFields.push("school");
    if (!degree) missingFields.push("degree");
    if (!startDate) missingFields.push("Start Date");
    if (!endDate) missingFields.push("End Date");
    if (!field) missingFields.push("field");
    if (!grade) missingFields.push("grade");
    if (!image?.url) missingFields.push("image");

    if (missingFields.length > 0) {
      toast.warning(
        `Please fill out the following fields: ${missingFields.join(", ")}`
      );
      return false;
    }
    return true;
  };
  const dispatch = useDispatch();
  const handleSubmit = () => {
    if (!educations?.length) {
      return toast.error("Empty educations");
    }
    navigate("/teacher-signup/step-ten");
    dispatch(
      handleSliceChange({
        name: "teacherToRegister",
        value: {
          ...teacherToRegister,
          education: educations,
        },
      })
    );
  };
  const onSelectFile = (imageData) => {
    setImage(imageData);
    setEducation({ ...education, image: imageData });
  };

  return (
    <div className="signup_experience_section_container">
      <div className="signup_experience_section">
        <div className="title">
          <h1>Educational Background</h1>
        </div>
        <div className="experience_container">
          <p className="text_description">
            Showcase your academic journey. Highlight your degrees, diplomas,
            and educational background to give students a clear picture of your
            expertise
          </p>
          <div className="exp_list">
            {educations &&
              educations?.map((el, index) => (
                <EducationItem
                  key={index}
                  {...el}
                  image={el?.image?.url}
                  index={index}
                  onDelete={handleDeleteEducation}
                  onEdit={handleEditEducation}
                />
              ))}
          </div>
          <div className="add_experience_button">
            <span className="xp" onClick={() => setShow(true)} role="button">
              Add {!!educations?.length && "another"} Education
              <span className="plus">+</span>
            </span>
          </div>

          <div className="button_exp">
            <Button
              variant={"cancel"}
              onClick={() => navigate("/teacher-signup/step-eight")}
            >
              Go back
            </Button>
            <Button variant="new_secondary" onClick={handleSubmit}>
              Next
              <img src={arrRight} alt="" />
            </Button>
          </div>
        </div>
        {show && (
          <EducationModal
            certification={education}
            handleCertificationChange={handleEducationChange}
            handleAddCertificate={handleAddEducation}
            handleCloseModal={handleCloseModal}
            image={image}
            setImage={setImage}
            onSelectFile={onSelectFile}
          />
        )}
      </div>
    </div>
  );
};

export const EducationModal = ({
  certification,
  handleCertificationChange,
  handleAddCertificate,
  handleCloseModal,
  image,
  setImage,
  onSelectFile,
}) => {
  const MAX_SIZE = 4 * 1024 * 1024; // Maximum allowed file size in bytes (2MB)

  return (
    <div className="xp_modal_overlay">
      <div className="xp_modal_container">
        <img
          src={closeBtn}
          className="close"
          alt=""
          onClick={handleCloseModal}
        />
        <div className="header">
          <h1>Add Education</h1>
        </div>
        <div className="inputs" style={{ padding: "20px" }}>
          <div className="input">
            <span>School</span>
            <input
              type="text"
              placeholder="School Name"
              name="school"
              value={certification.school}
              onChange={(e) =>
                handleCertificationChange({
                  name: e.target.name,
                  value: e.target.value,
                })
              }
            />
          </div>
          <div className="multiple">
            <div className="input">
              <span>Degree</span>
              <input
                type="text"
                placeholder="Degree"
                name="degree"
                value={certification.degree}
                onChange={(e) =>
                  handleCertificationChange({
                    name: e.target.name,
                    value: e.target.value,
                  })
                }
              />
            </div>
            <div className="input">
              <span>Field</span>
              <input
                type="text"
                name="field"
                placeholder="Field of study"
                value={certification.field}
                onChange={(e) =>
                  handleCertificationChange({
                    name: e.target.name,
                    value: e.target.value,
                  })
                }
              />
            </div>
          </div>

          <div className="multiple">
            <div className="input">
              <span>Start date</span>
              <input
                type="date"
                name="startDate"
                value={certification.startDate}
                onChange={(e) =>
                  handleCertificationChange({
                    name: e.target.name,
                    value: e.target.value,
                  })
                }
              />
            </div>
            <div className="input">
              <span>End date</span>
              <input
                type="date"
                name="endDate"
                value={certification.endDate}
                onChange={(e) =>
                  handleCertificationChange({
                    name: e.target.name,
                    value: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="input">
            <span>Grade</span>
            <input
              type="text"
              placeholder="Grade"
              name="grade"
              value={certification.grade}
              onChange={(e) =>
                handleCertificationChange({
                  name: e.target.name,
                  value: e.target.value,
                })
              }
            />
          </div>
          <div className="input with_image">
            <span>Certification</span>
            <SharedSingleImageUploader
              onSelectFile={onSelectFile}
              detectFace={false}
              preview={image}
              MAX_SIZE={MAX_SIZE}
            />
          </div>
          <div className="modal_submit">
            <Button variant="new_secondary" onClick={handleAddCertificate}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const EducationItem = ({
  school,
  degree,
  startDate,
  endDate,
  field,
  grade,
  image,
  onDelete,
  onEdit,
  index,
}) => {
  return (
    <div className="signup_certificate_item">
      <div className="col">
        <div className="cert_title">
          <div className="cert_logo">
            <img src={image} alt="" />
          </div>
        </div>
        <div className="cert_name">
          <h4>{school}</h4>
          <span>
            {degree}, {field}
          </span>
          <p>
            {moment(startDate).format("MMM YYYY")} -
            {moment(endDate).format("MMM YYYY")}
          </p>
        </div>
      </div>
      <div className="col">
        <img src={editBtn} alt="" onClick={() => onEdit(index)} />
        <img src={delBtn} alt="" onClick={() => onDelete(index)} />
      </div>
    </div>
  );
};
export default SignupStep13;

import React from "react";
import "./sharedModal.styles.scss";
const SharedModal = ({ children, isOpen, onClose, className }) => {
  if (!isOpen) return null;

  return (
    <div
      className={`shared_modal_overlay ${className ?? ""}`}
      onClick={onClose}
    >
      <div
        className="shared_modal_content"
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
};

export default SharedModal;

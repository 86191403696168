import React, { useEffect, useState, useRef } from "react";
import {
  ref,
  getStorage,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import {
  collection,
  addDoc,
  serverTimestamp,
  query,
  orderBy,
  onSnapshot,
  getDocs,
  writeBatch,
} from "firebase/firestore";
import { Tooltip } from "@mui/material";

import profilePic from "../../assets/profilePic.svg";
import expand from "../../assets/expand.svg";
import close from "../../assets/Close.svg";
import Clip from "../../assets/Clip.svg";
import ClipWhite from "../../assets/ClipWhite.svg";
import "./MyMessages.styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { getContacts, getChats } from "../../../ReduxToolkit/Slices/userSlice";
import { handleUserChange } from "../../../ReduxToolkit/Slices/userSlice";
import { toast } from "react-toastify";
import { uploadImage } from "../../../ReduxToolkit/Slices/teacherSlice";
import Note from "../../../TeacherApp/Components/Note/Note";

const Contact = ({
  name,
  email,
  icon,
  duration,
  onClick,
  messageIndex,
  i,
  unreadMessageCount,
  hasUnseenMessages,
  showMessages,
}) => {
  return (
    <div
      className="contact_container flex_center"
      onClick={() => onClick()}
      style={{
        background:
          messageIndex === i && showMessages
            ? "rgba(200, 200,200,.1 )"
            : "transparent",
      }}
    >
      <div className="contact_col flex_center">
        <img alt="" src={icon} />
        <div>
          <h4>{name}</h4>
          {/* <p>{email}</p> */}
        </div>
      </div>
      {/* <p>{duration}</p> */}
      {unreadMessageCount > 0 ? (
        <p className="count_messages flex_center">{unreadMessageCount}</p>
      ) : null}
    </div>
  );
};

const MessageContainer = ({
  image,
  name,
  message,
  time,
  reverse,
  attachmentUrl,
  filename,
}) => {
  return (
    <div className="MessageContainer">
      <div className={reverse ? "row1 rowreverse" : "row1"}>
        <img alt="" src={image} />
        <p className={reverse ? "row1 rowreverse" : "row1"}>{name}</p>
      </div>
      {filename ? (
        <Tooltip title="Click to view" arrow>
          <a
            className={
              reverse
                ? "message flex_center messagereverse attach"
                : "message attach flex_center"
            }
            href={attachmentUrl}
            style={{ cursor: "pointer" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img alt="" src={Clip} />
            {filename}
          </a>
        </Tooltip>
      ) : (
        <p className={reverse ? "message messagereverse" : "message"}>
          {message}
        </p>
      )}
    </div>
  );
};

function MyMessages() {
  const db = firebase.firestore();

  const handleOnChange = (e) => {
    setNewMessage(e.target.value);
  };
  const storage = getStorage();
  const sendToast = (m) => toast.error(m);
  const dispatch = useDispatch();

  const { user, refreshMessages } = useSelector((state) => state.user);
  const { student } = useSelector((state) => state.student);
  const { teacher } = useSelector((state) => state.teacher);

  const [rooms, setRooms] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [messageIndex, setMesageIndex] = useState(null);
  const [search, setSearch] = useState("");
  const [hasUnseenMessages, setHasUnseenMessages] = useState([]);
  const [showMessages, setShow] = useState(false);
  const [fileName, setFileName] = useState("");
  const [unreadMessagesCount, setUnreadMessagesCount] = useState({});

  function getUniqueListBy(arr, key) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }
  useEffect(() => {
    dispatch(
      getContacts({
        role: user.role === "STUDENT" ? "studentContacts" : "teacherContacts",
        id: user.role === "STUDENT" ? student : teacher?.id,
      })
    ).then((res) => {
      if (res.payload?.length) {
        let uniqueArray = getUniqueListBy(res.payload, "id");
        if (!res.payload) {
          return;
        }
        if (user.role === "STUDENT") {
          setContacts(
            uniqueArray.map((item) => {
              return { ...item, roomId: item.user.id + user.id };
            })
          );
          setRooms(uniqueArray.map((item) => item.user.id + user.id));
        } else {
          setContacts(
            uniqueArray.map((item) => {
              return { ...item, roomId: user.id + item.user.id };
            })
          );
          setRooms(uniqueArray?.map((item) => user?.id + item?.user?.id));
        }
        setMesageIndex(0); // Reset messageIndex to 0
      }
    });
  }, []);
  useEffect(() => {
    // This effect is for tracking unread messages for each contact
    contacts.forEach((contact) => {
      const roomId = contact.roomId;
      const unsubscribe = onSnapshot(
        query(
          collection(db, "chat-rooms", roomId, "messages"),
          orderBy("timestamp", "asc")
        ),
        (querySnapshot) => {
          const messages = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          // Filter for unread messages that are not sent by the current user
          const unreadCount = messages.filter(
            (message) => !message.seen && message.email !== user.email
          ).length;
          setUnreadMessagesCount((prevState) => ({
            ...prevState,
            [roomId]: unreadCount,
          }));
        }
      );

      return () => unsubscribe();
    });
  }, [contacts, db, user.email]);
  const markMessagesAsSeen = async (roomId) => {
    const messagesRef = collection(db, "chat-rooms", roomId, "messages");
    const querySnapshot = await getDocs(messagesRef);
    const batch = writeBatch(db);

    querySnapshot.forEach((doc) => {
      if (doc.data().email !== user.email) {
        batch.update(doc.ref, { seen: true });
      }
    });

    await batch.commit();

    refreshSeen();
  };

  const sendMessage = async (roomId, user, text) => {
    try {
      await addDoc(collection(db, "chat-rooms", roomId, "messages"), {
        displayName: user.name + user.lastName,
        email: user.email,
        text: text.trim(),
        timestamp: serverTimestamp(),
        attachmentUrl: attachments,
        filename: fileName ? fileName : null,
        seen: false,
      });
    } catch (error) {
      console.error("error sending chats", error);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      sendMessage(rooms[messageIndex], user, newMessage);
      setNewMessage("");
      setAttachments("");
      setFileName("");
    } catch (error) {
      console.error("error sending message with attachments", error);
    }
  };

  const bottomRef = useRef(null);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const getMessages = (roomId, callback) => {
    return onSnapshot(
      query(
        collection(db, "chat-rooms", roomId, "messages"),
        orderBy("timestamp", "asc")
      ),
      (querySnapshot) => {
        const messages = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          roomId: roomId,
          ...doc.data(),
        }));
        callback(messages);
      }
    );
  };

  const refreshSeen = () => {
    dispatch(
      handleUserChange({ name: "refreshMessages", value: !refreshMessages })
    );
  };

  useEffect(() => {
    if (rooms.length) {
      const unsubscribe = getMessages(rooms[messageIndex], (messages) => {
        setMessages(messages);

        const unseenMessages = messages.filter(
          (message) => message.email !== user.email && !message.seen
        );

        const updatedHasUnseenMessages = rooms.map(() => false);
        unseenMessages.forEach((message) => {
          const contactIndex = rooms.findIndex(
            (room) => room === message.roomId
          );
          if (contactIndex !== -1) {
            updatedHasUnseenMessages[contactIndex] = true;
          }
        });
        setHasUnseenMessages(updatedHasUnseenMessages);
      });

      return unsubscribe;
    }
  }, [rooms, messageIndex]);

  const [imageLoading, setImageLoading] = useState(false);
  const MAX_SIZE = 4 * 1024 * 1024; // Maximum allowed file size in bytes (2MB)

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) {
      sendToast("Please select a file");
      return;
    }
    if (file.size > MAX_SIZE) {
      sendToast(`File size must be within ${MAX_SIZE / (1024 * 1024)} MB.`);
      return;
    }

    setImageLoading(true);
    handleImageUpload(file);
    setAttachments("");
  };
  const handleImageUpload = (file) => {
    let form = new FormData();
    form.append("file", file);
    Promise.resolve(dispatch(uploadImage(form))).then((value) => {
      if (!value.type === "uploadImage/fulfilled") {
        setImageLoading(false);
        return sendToast("Error uploading image, please try again");
      }
      setImageLoading(false);
      if (value.type !== "uploadImage/fulfilled") {
        return sendToast("Error uploading file");
      }
      setFileName(value.payload.fileName);
      setAttachments(value.payload.url);
    });
  };

  const markMessageContainer = () => {
    markMessagesAsSeen(contacts[messageIndex].roomId);
  };

  const onCancelAttach = () => {
    setAttachments("");
    setFileName("");
  };
  return contacts.length ? (
    <div className="">
      <Note
        p1={
          "Use the chat feature provided here to engage in discussions about lesson materials, upcoming topics, or any questions you may have regarding past or future lessons."
        }
        p2={
          "please remember not to share any sensitive or private information through this platform."
        }
      />

      <div className="MyMessage_Container">
        {showMessages && (
          <div
            className="messages_container"
            onClick={() => markMessageContainer()}
          >
            {contacts.length > 0 && (
              <div className="miniheader">
                <div>
                  <p className="contact_name">
                    {contacts[messageIndex].user.name}{" "}
                    {contacts[messageIndex].user.lastName}
                  </p>
                  {/* <span className="contact_status">
                <div className="activity_status" />
                Active
              </span> */}
                </div>
                <div>
                  {/* <img alt="" src={expand} /> */}
                  <img
                    alt=""
                    src={close}
                    onClick={() => setShow(false)}
                    className="pointer"
                  />
                </div>
              </div>
            )}
            <div className="firstMessageRow">
              {contacts.length && messages.length
                ? messages.map((chat, i) => (
                    <MessageContainer
                      key={i}
                      image={chat.photoURL ? chat.photoURL : profilePic}
                      name={
                        chat.senderId === user.id
                          ? user.name
                          : contacts[messageIndex].user.name
                      }
                      message={chat.text}
                      filename={chat.filename}
                      attachmentUrl={chat.attachmentUrl}
                      reverse={chat.email === user.email ? true : false}
                    />
                  ))
                : null}
              <div ref={bottomRef} />
            </div>

            <form className="input_container" onSubmit={(e) => handleSubmit(e)}>
              {imageLoading && (
                <div
                  className="spinner filename"
                  style={{ position: "absolute" }}
                />
              )}
              {fileName ? (
                <div className="filename">
                  <span>{fileName}</span>
                  <button onClick={() => onCancelAttach()}>x</button>
                </div>
              ) : (
                <input
                  type="text"
                  placeholder="Type message..."
                  value={newMessage}
                  onChange={handleOnChange}
                />
              )}
              <div className="action_container">
                <label htmlFor="file-upload">
                  <img alt="attach" src={Clip} />
                </label>
                <input
                  id="file-upload"
                  type="file"
                  name="file"
                  accept=".pdf, image/*"
                  style={{ display: "none" }}
                  onChange={(e) => handleFileUpload(e)}
                />
                <label />
                <button
                  className="btn_send"
                  type="submit"
                  onClick={(e) => handleSubmit(e)}
                >
                  Send
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  ) : (
    <>
      <Note
        p1={
          "Use the chat feature provided here to engage in discussions about lesson materials, upcoming topics, or any questions you may have regarding past or future lessons."
        }
        p2={
          "please remember not to share any sensitive or private information through this platform."
        }
      />
      <p>You need to book a class to be able to send messages </p>
    </>
  );
}

export default MyMessages;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  deletePackages,
  getPackages,
  setAdminState,
} from "../../../ReduxToolkit/Slices/adminSlice";
import moment from "moment";

import edit from "../../../assets/AdminApp/svgs/edit.svg";
import plus from "../../../assets/AdminApp/svgs/plus.svg";
import view from "../../../assets/AdminApp/svgs/view.svg";
import deleteIcon from "../../../assets/AdminApp/svgs/deleteico.svg";

export default function ManagePackages() {
  const [packages, setPackages] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPackages()).then((res) => {
      dispatch(setAdminState({ name: "packages", value: res.payload }));
      setPackages(res.payload);
    });
  }, []);

  const dateFormat = (date) => {
    return moment(date).format("DD/MM/YYYY");
  };

  // const onDelete = (id) => {
  //   dispatch(deletePackages(id)).then((res) => console.log("res delete", res));
  // };
  return (
    <div className="manage__admin__subjects packages__table">
      <div className="header">
        <div className="header__text">
          <span>Packages List</span>
          {/* <p>More than 400 packages generated</p> */}
        </div>
        <div className="teachers__actions">
          {/* <select id="select">
            <option value="">Sort</option>
          </select>
          <div className="search__teachers">
            <input type="text" placeholder="Search Promo codes" />
          </div> */}
          <Link to={"/admin/packages/new"}>
            <div className="new__category">
              <img src={plus} alt="" />
              <span>Add New Package</span>
            </div>
          </Link>
        </div>
      </div>
      <div className="students__table">
        <table>
          <tr>
            <th>
              <span className="th__span">NO</span>
            </th>
            <th>
              <span className="th__span">NAME</span>
            </th>
            <th>
              <span className="th__span">DURATION</span>
            </th>
            <th>
              <span className="th__span">PERCENTAGE</span>
            </th>
            <th>
              <span className="th__span">OFFERS</span>
            </th>
            <th>
              <span className="th__span">DATE</span>
            </th>

            <th>
              <span className="th__span">STATUS</span>
            </th>
            <th></th>
          </tr>
          {packages &&
            packages.map((pck, i) => (
              <tr key={pck.id} className="packages__tr">
                <td>
                  <div className="manage__std__lesson">
                    <div className="lesson__title">
                      <span>{i + 1}</span>
                    </div>
                  </div>
                </td>
                <td>
                  <span>{pck.name}</span>
                </td>
                <td>
                  <span>{pck.duration} min</span>
                </td>
                <td>
                  <span>{pck.percentage}%</span>
                </td>
                <td>
                  <span>{pck.offers.length}</span>
                </td>
                <td>
                  <span>{dateFormat(pck.createdAt)}</span>
                </td>
                <td>
                  <span className={`${pck.status.toLowerCase()}`}>
                    {pck.status.toLowerCase()}
                  </span>
                </td>

                <td>
                  <div className="category__actions">
                    <div className="actions">
                      <Link to={`/admin/packages/${pck.name}/${pck.duration}`}>
                        <img src={view} alt="" />
                      </Link>
                      {/* <img src={edit} alt="" /> */}
                      <Link
                        to={`/admin/packages/${pck.name}/${pck.duration}/edit`}
                      >
                        <img src={edit} alt="" />
                      </Link>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
        </table>
      </div>
      {/* <Pagination
    page={page}
    setCurrentPage={setCurrentPage}
    currentPage={currentPage}
  /> */}
    </div>
  );
}

// const cut = () => {
//   const data = [
//     { header: "Trial Lessons Pricing", price30: "1$", price60: "1$" },
//     { header: "5 Lessons Pricing", price30: "1$", price60: "1$" },
//     { header: "10 Lessons Pricing", price30: "1$", price60: "1$" },
//     { header: "15 Lessons Pricing", price30: "1$", price60: "1$" },
//     { header: "15 Lessons Pricing", price30: "1$", price60: "1$" },
//   ];
//   return (
//     <div className="subject__details">
//       <div className="header">
//         <div className="col">
//           <h1>Lesson</h1>
//           <p>Your lesson pricing</p>
//         </div>
//         <Link to="/admin/packages/edit">
//           <button>Edit</button>
//         </Link>
//       </div>
//       <div className="detail__rows">
//         {data.map((el) => (
//           <div className="subject__detail__row">
//             <h2>{el.header}</h2>
//             <div className="sub__row">
//               <p>
//                 30 Minute Price:<span>$1</span>
//               </p>
//               <p>
//                 60 Minute Price:<span>$1</span>
//               </p>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

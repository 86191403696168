const BookingTextInput = ({
  label,
  value,
  onChange,
  placeholder,
  name,
  type = "text",
}) => (
  <div className="booking_input">
    <span className="input_name">{label}</span>
    <div className="input">
      <input
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        name={name}
      />
    </div>
  </div>
);
export default BookingTextInput;

import ProgressBar from "../ProgressBar/ProgressBar";
import { Link, useLocation } from "react-router-dom";
import { CircularProgressbar } from "react-circular-progressbar";

import "react-circular-progressbar/dist/styles.css";
import "./signupNav.styles.scss";

import logo from "../../../assets/SVG/logo.png";

const SignupNav = () => {
  const location = useLocation();
  const steps = [
    "/teacher-signup",
    "/teacher-signup/step-one",
    "/teacher-signup/step-two",
    "/teacher-signup/step-three",
    "/teacher-signup/step-four",
    "/teacher-signup/step-five",
    "/teacher-signup/step-six",
    "/teacher-signup/step-seven",
    "/teacher-signup/step-eight",
    "/teacher-signup/step-nine",
    "/teacher-signup/step-ten",
    "/teacher-signup/step-eleven",
    "/teacher-signup/step-twelve",
    "/teacher-signup/step-thirteen",
    "/teacher-signup/complete",
  ];

  const currentStepIndex = steps.indexOf(location.pathname);
  const progress =
    currentStepIndex >= 0 ? ((currentStepIndex + 1) / steps.length) * 100 : 0;
  return (
    <div className="signup_nav_container">
      <div className="signup_nav">
        <Link to={"/"}>
          <img width={85} src={logo} alt="" />
        </Link>
        <CircularProgressbar
          value={parseInt(progress)}
          text={`${parseInt(progress)}%`}
          className="custom_signup_progress_circle"
        />
      </div>
      <ProgressBar progress={progress} />
    </div>
  );
};
export default SignupNav;

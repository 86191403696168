import React, { useEffect } from "react";
import "./PaymentSuccess.styles.scss";
import success from "../../../assets/StudentApp/images/payment-sucess.png";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { handlePurchase } from "../../../hooks";

export default function PaymentSuccess({ courses }) {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const bookingId = searchParams.get("bookingId");
  const appointments = searchParams.get("appointments");

  useEffect(() => {
    if (appointments) {
      handlePurchase(bookingId, appointments, user, true);
    }
  }, []);
  return (
    <div className="payment__success__container">
      <div className="container">
        <img src={success} alt="" />
        <div className="title">
          <span>Payment Successful!</span>
        </div>
        <button className="btn__primary" onClick={() => navigate("/students")}>
          <Link to="/students">Go To Dashboard</Link>
        </button>
      </div>
    </div>
  );
}

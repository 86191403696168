import React, { useEffect, useRef, useState } from "react";
import "./Schedule.styles.scss";
import { toast } from "react-toastify";
import { Tooltip } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import {
  createCalendar,
  getTeacherAvailabilites,
  handleSliceChange,
} from "../../../ReduxToolkit/Slices/teacherSlice";
import moment from "moment";

import arrow from "../../../assets/StudentApp/svgs/Arrow-left.svg";
import arrowDark from "../../../assets/StudentApp/svgs/Arrow-right-dark.svg";
import Track from "../../Components/Track/Track";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { convertTimezoneTwentyFour } from "../../../SharedApp/Components/Timezone";
import { GoodToKnow } from "../../../Components/SharedSingleImageUploader/SharedSingleImageUploader";

import { TimezoneSelector } from "../../../hooks";
import Button from "../../../Components/Button/Button";

export default function Schedule({
  customLinks,
  given,
  customClass,
  handleNext,
}) {
  const { relatedInfo } = useSelector((state) => state.teacher);
  const { teacher, availabilitesToAdd } = useSelector((state) => state.teacher);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const signupId = relatedInfo.teacherId;

  const { timezone } = useSelector((state) => state.user);

  let teacherId = signupId ? signupId : teacher.id;
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  let hourObject = {};
  const hours = [];
  let location = useLocation();
  let step = location.pathname.split("/")[3];
  const update = location.pathname.includes("/teachers/subjects/2");
  const calendarInitial = days.map((day) => ({ day, hours: hourObject }));
  const date = (hour, mins) => new Date(2022, 1, 1, hour, mins, 0);

  const [availability, setAvailability] = useState(
    availabilitesToAdd ? availabilitesToAdd : []
  );
  const [calendar, setCalendar] = useState(calendarInitial);
  for (let hour = 0; hour < 24; hour++) {
    hours.push(date(hour, 0));
    hours.push(date(hour, 15));
    hours.push(date(hour, 30));
  }
  const newHours = () => hours.map((hour) => (hourObject[hour] = false));

  useEffect(() => {
    newHours();
    dispatch(getTeacherAvailabilites(teacherId))
      .then((res) => {
        setAvailability(res.payload.data);
      })
      .catch((err) => console.log("error getting av", err));

    return;
  }, []);

  const handleCalendarClick = (index, hourClicked, addedValue, onCLick) => {
    if (onCLick) {
      setCalendar((calendar) => {
        return [
          ...calendar.slice(0, index),
          {
            ...calendar[index],
            hours: {
              ...calendar[index].hours,
              [hourClicked]: !calendar[index].hours[hourClicked],
            },
          },
          ...calendar.slice(index + 1),
        ];
      });
    } else if (addedValue) {
      return setCalendar((calendar) => {
        return [
          ...calendar.slice(0, index),
          {
            ...calendar[index],
            hours: {
              ...calendar[index].hours,
              [hourClicked]: addedValue,
            },
          },
          ...calendar.slice(index + 1),
        ];
      });
    } else {
      // return setCalendar((calendar) => {
      //   return [
      //     ...calendar.slice(0, index),
      //     {
      //       ...calendar[index],
      //       hours: {
      //         ...calendar[index].hours,
      //         [hourClicked]: addedValue,
      //       },
      //     },
      //     ...calendar.slice(index + 1),
      //   ];
      // });
    }
  };
  const getDateFromTime = (time) => {
    return moment.utc(time, "HH:mm").format("YYYY-MM-DDTHH:mm:ss.sssZ");
  };

  const handleSubmit = () => {
    if (!checkAvailability(calendar)) {
      return toast.error("Please enter at least one avalability");
    }
    let Availabilites = {};
    calendar.map(
      (item) =>
        (Availabilites[item.day] = Object.keys(item.hours).filter((element) => {
          return item.hours[element] !== false;
        }))
    );
    const newnew = [];
    Object.keys(Availabilites).map((keyname, i) => {
      return newnew.push({
        day: keyname.toUpperCase(),
        times: Availabilites[keyname].map((items) => {
          let finals = convertTimezoneTwentyFour(
            moment.utc(items).format("HH:mm"),
            timezone,
            "UTC"
          );
          let finalsend = convertTimezoneTwentyFour(
            moment.utc(items).add(30, "minutes").format("HH:mm"),
            timezone,
            "UTC"
          );
          return {
            startTime: getDateFromTime(finals),
            endTime: getDateFromTime(finalsend),
            timeZone: "GMT",
          };
        }),
      });
    });
    let final = newnew;
    customLinks
      ? dispatch(
          createCalendar({
            id: teacherId,
            availabilites: final,
          })
        )
          .then((res) => {
            toast.success("Thank you, your availabilities have been updated!");
            return navigate("/teachers");
          })
          .catch((err) => console.log("error", err))
      : dispatch(
          handleSliceChange({ name: "availabilitesToAdd", value: final })
        );

    !customLinks && navigate("/teacher-signup/step-nine");
  };

  const newDate = (date) => {
    let formated = moment
      .utc(`${moment().format("YYYY-MM-DD")} ${date}`)
      .format("HH:mm");
    return formated;
  };
  const compareAv = (day, times) => {
    let index;
    const newAv = calendar.filter((element, i) => {
      if (element.day.toLowerCase() === day.toLowerCase()) {
        index = i;
        return element;
      }
    });
    times.map((time) => {
      Object.keys(newAv[0].hours).map((item, i) => {
        if (
          newDate(moment(item).format("HH:mm")) ===
          convertTimezoneTwentyFour(
            moment.utc(time.startTime).format("HH:mm"),
            "UTC",
            timezone
          )
        ) {
          handleCalendarClick(index, item, true);
        } else {
          return handleCalendarClick(index, item, false);
        }
      });
    });
  };

  const activateCalendar = () => {
    availability.map((item, i) => {
      compareAv(item.day, item.times);
    });
  };

  useEffect(() => {
    if (availability.length) {
      activateCalendar();
    }
  }, [availability]);

  const hourString = (dates) => {
    const hours = dates.getHours();
    const ampm = hours >= 12 ? "PM" : "AM"; // Determine if it's AM or PM
    const formattedHours = hours % 12 || 12; // Convert hours to 12-hour format
    const minutes = dates.getMinutes();
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  };

  const checkAvailability = (av) => {
    return (
      av.filter((arr) => JSON.stringify(arr.hours).includes("true")).length > 0
    );
  };

  const [mouseDown, setMouseDown] = useState(false);

  return (
    <div
      className={`schedule__page ${customClass ? customClass : ""}`}
      onMouseDown={() => !mouseDown && setMouseDown(true)}
      onTouchStart={() => !mouseDown && setMouseDown(true)}
      onTouchEnd={() => mouseDown && setMouseDown(false)}
      onMouseUp={() => mouseDown && setMouseDown(false)}
    >
      {customLinks && <Track step={step} givenData={given} />}
      <div className="schedule__container">
        <h1>Select Availability</h1>{" "}
        <div className="inner__schedule">
          <GoodToKnow
            title={"Attention!"}
            images={[]}
            notes={[
              "Do not forget to check and change your selected timezone if needed.",
              "Please choose the days you are available for your teaching.",
              "This covers all your subjects. This is what would be shown to the potential students.",
              "You can quickly select your available times by clicking and dragging your mouse over the options.",
            ]}
          />

          <div className="timezone">
            <Tooltip
              title="Select your schedule's timezone here"
              arrow
              placement="left"
            >
              <TimezoneSelector />
            </Tooltip>
          </div>
          <div className="inner_table_container">
            <table className="Tables_Containers" cellSpacing="0">
              <thead>
                <tr className="days">
                  <th />
                  <th>Monday</th>
                  <th>Tuesday</th>
                  <th>Wednesday</th>
                  <th>Thursday</th>
                  <th>Friday</th>
                  <th>Saturday</th>
                  <th>Sunday</th>
                </tr>
              </thead>
              {hours &&
                hours.map((hour, i) => (
                  <tbody key={i}>
                    <tr>
                      <th className="hour">
                        {hourString(hour)} -{" "}
                        {i !== hours.length - 1
                          ? hourString(hours[i + 1])
                          : "00:00"}
                      </th>

                      {calendar.map((day, k) => (
                        <th
                          key={k}
                          onClick={() =>
                            handleCalendarClick(k, hour, false, true)
                          }
                          onMouseEnter={() =>
                            mouseDown &&
                            !day.hours[hour] &&
                            handleCalendarClick(k, hour, false, true)
                          }
                          onTouchMove={() =>
                            mouseDown &&
                            !day.hours[hour] &&
                            handleCalendarClick(k, hour, false, true)
                          }
                          className={day.hours[hour] ? "clickedCalendar" : ""}
                          style={{ cursor: "pointer" }}
                        />
                      ))}
                    </tr>
                  </tbody>
                ))}
            </table>
          </div>
        </div>
        <div className="schedule__buttons">
          {customLinks ? (
            <Link to={"/teachers/subjects/1"}>
              <button className="btn__prev">
                <img src={arrow} alt="" /> Previous
              </button>
            </Link>
          ) : (
            <Button
              variant={"cancel"}
              onClick={() =>
                customLinks
                  ? navigate(-1)
                  : navigate("/teacher-signup/step-seven")
              }
            >
              Go back
            </Button>
          )}

          {/* <Link to={customLinks ? "/teachers/subjects/4" : "/teacher/signup/lessons"}> */}
          <button
            className={`${
              checkAvailability(calendar)
                ? "shared_btn primary "
                : "  shared_btn locked primary "
            }`}
            onClick={() => handleSubmit()}
          >
            {update ? <span>Submit</span> : <span>Next</span>}{" "}
            <img src={arrowDark} alt="" />
          </button>
          {/* </Link> */}
        </div>
      </div>
    </div>
  );
}

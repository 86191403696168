import React, { useState, useEffect, useRef } from "react";
import "./TutorSignup.styles.scss";
import { toast } from "react-toastify";
import { Tooltip } from "@mui/material";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { getPhoneCode } from "libphonenumber-js";
import { zodResolver } from "@hookform/resolvers/zod";

import next from "../../../assets/TeacherApp/assets/svgs/Arrow-right.svg";
// import nextArrow from "../../../assets/StudentApp/svgs/Arrow-right.svg";
// import Track from "../../Components/Track/Track";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  checkUserEmail,
  handleSliceChange,
  // handleTeacherChange,
  handleUserChange,
} from "../../../ReduxToolkit/Slices/teacherSlice";

import PhoneInput from "react-phone-number-input";

import { passwordRegex, validatePassword } from "../../../hooks";

import google from "../../../assets/HomeApp/svgs/googe-icon-white.svg";
import facebook from "../../../assets/HomeApp/svgs/facebook.svg";
import { z } from "zod";
import { useForm, Controller } from "react-hook-form";

export default function TutorSignup() {
  const googleProvider = new firebase.auth.GoogleAuthProvider();
  const auth = firebase.auth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const teacherToRegister = useSelector(
    (state) => state?.teacher?.teacherToRegister
  );
  const [termsChecked, setTermsChecked] = useState(false);

  const { user, teachCourses, teachLessons, showCourses } = useSelector(
    (state) => state.teacher
  );

  const teacherSignUpInfo = useSelector(
    (state) => state.teacher?.teacherToRegister
  );

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const updateWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  const checkEmailAddress = async (email) => {
    if (user?.bySocial) return true;
    else {
      return dispatch(checkUserEmail(email))
        .then((res) => {
          return res.payload === true; // Assuming true means email is available
        })
        .catch(() => false); // Handle potential errors and assume email is not available on error
    }
  };
  const handleLocation = (e) => {
    const { name } = e.target;

    dispatch(handleSliceChange({ name, value: true }));
    if (name === "teachLessons") {
      dispatch(handleSliceChange({ name: "teachCourses", value: false }));
    } else {
      dispatch(handleSliceChange({ name: "teachLessons", value: false }));
    }
  };

  // const facebookProvider = new firebase.auth.FacebookAuthProvider();

  const signupWithGoogle = async () => {
    try {
      const res = await auth.signInWithPopup(googleProvider);
      const googleUser = res.user;
      const firebaseToken = await googleUser.getIdToken();
      const value = {
        ...user,
        name: res?.additionalUserInfo?.profile.name,
        email: res?.additionalUserInfo?.profile.email,
        phone: res?.user?.phoneNumber
          ? res.user.phoneNumber
          : getValues("phone"),
        firebaseToken,
        gender: getValues("gender") ? getValues("gender") : "UNSPECIFIED",
        role: "TEACHER",
        bySocial: true,
      };
      setValue("name", value?.name);
      setValue("email", value?.email);
      setValue("phone", value?.phone);
      setValue("gender", value?.gender);
      setValue("password", "@Password12345");
      setValue("confirmPassword", "@Password12345");
      dispatch(handleSliceChange({ name: "user", value }));
    } catch (error) {
      console.error("google err", error);
    }
  };

  const nameRegex = /^[a-zA-Z\s]+$/;
  const registerSchema = z
    .object({
      name: z.string().min(3).regex(nameRegex, {
        message: "Name can only contain letters and spaces.",
      }),
      lastName: z.string().min(3).regex(nameRegex, {
        message: "Last name can only contain letters and spaces.",
      }),
      password: z
        .string()
        .min(8, {
          message: "Password must be at least 8 characters long.",
        })
        .regex(passwordRegex, {
          message:
            "Password must contain at least one uppercase letter, one digit, and one special character from !@#$%^&*",
        })
        .refine((data) => user.bySocial || data),
      confirmPassword: z
        .string()
        .min(8, {
          message: "Password must be at least 8 characters long.",
        })
        .regex(passwordRegex, {
          message:
            "Password must contain at least one uppercase letter, one digit, and one special character from !@#$%^&*",
        })
        .refine((data) => user.bySocial || data),

      phone: z
        .string()
        .min(6, {
          message: "Phone number is missing.",
        })
        .refine((data) => data && isPossiblePhoneNumber(data), {
          message: "Invalid Phone number",
        }),
      email: z.string().email("Invalid email format"),
      gender: z.enum(["MALE", "FEMALE", "UNSPECIFIED"]),
      isAdult: z.enum(["yes", "no"]),
    })
    .refine(
      (data) => user?.bySocial || data.password === data.confirmPassword,
      {
        message: "Passwords don't match",
        path: ["confirmPassword"],
      }
    )
    .refine((data) => data.isAdult === "yes", {
      message: "isAdult is required.",
      path: ["isAdult"],
    });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    clearErrors,
    watch,
    setError,
    control,
    setValue,
    getValues,
  } = useForm({
    resolver: zodResolver(registerSchema),
    defaultValues: {
      name: teacherSignUpInfo?.name,
      lastName: teacherSignUpInfo?.lastName,
      password: teacherSignUpInfo?.password,
      confirmPassword: teacherSignUpInfo?.confirmPassword,
      email: teacherSignUpInfo?.email,
      phone: teacherSignUpInfo?.phone,
      gender: teacherSignUpInfo?.gender,
      isAdult: teacherSignUpInfo?.isAdult,
    },
  });
  const formPassword = watch("password");
  const isAdult = watch("isAdult");
  const onSubmit = async (data) => {
    if (!termsChecked)
      return toast.error("you need to accept the terms and conditions!");
    const isAvailableEmail = await checkEmailAddress(getValues("email"));
    if (!isAvailableEmail) {
      return toast.error(
        "Email already in use, please login or try another one."
      );
    }
    dispatch(
      handleSliceChange({
        name: "teacherToRegister",
        value: { ...teacherToRegister, ...data },
      })
    );
    navigate("/teacher-signup/step-one");
  };
  const hasErrors = (errors) => {
    const errorKeys = Object.keys(errors);
    return (
      errorKeys.length > 0 ||
      termsChecked === false ||
      getValues("isAdult") === "no"
    );
  };

  return (
    <>
      {/* <Track step={1} /> */}
      <div className="tutor__signup__page">
        <div className="container">
          <div className="inner__container">
            <div className="header">
              <h1>Create Teacher Account</h1>
              <p>
                Already have an Account?{" "}
                <span>
                  <Link to={"/login"}>Sign In</Link>
                </span>
              </p>
              <div className="flex_space">
                <div
                  className="signin__with"
                  onClick={() => signupWithGoogle()}
                >
                  <img src={google} alt="" />
                  <span>Sign up with Google</span>
                </div>
                {/* <div
                  className="signin__with"
                  onClick={() => signupWithFacebook()}
                >
                  <img src={facebook} alt="" />
                  <span>Sign up with Facebook</span>
                </div> */}
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="inputs">
                <Tooltip
                  title="Emails and Phone numbers are not allowed here"
                  placement="top-start"
                  arrow
                >
                  <div className={`input ${errors.name && "error_input"}`}>
                    <h4>First Name</h4>
                    <input type="text" {...register("name")} />
                    <p>{errors.name ? errors?.name?.message : ""}</p>
                  </div>
                </Tooltip>
                <Tooltip
                  title="Emails and Phone numbers are not allowed here"
                  placement="top-start"
                  arrow
                >
                  <div
                    className={`input ${
                      errors.lastName?.message && "error_input"
                    }`}
                  >
                    <h4>Last Name</h4>
                    <input type="text" {...register("lastName")} />
                    <p>{errors.lastName ? errors?.lastName?.message : ""}</p>
                  </div>
                </Tooltip>
                {!user.bySocial && (
                  <Tooltip
                    title="Password must be at least 8 characters long and contain at least one uppercase letter, one number, and one symbol (e.g. !@#$%^&*)"
                    placement="top-start"
                    arrow
                  >
                    <div
                      className={`input ${errors.password && "error_input"}`}
                    >
                      <h4>Password</h4>
                      <input type="password" {...register("password")} />
                      <p>
                        {errors?.password?.message
                          ? errors?.password?.message
                          : ""}
                      </p>
                    </div>
                  </Tooltip>
                )}

                {!user.bySocial && (
                  <div
                    className={`input ${
                      errors?.confirmPassword?.message && "error_input"
                    }`}
                  >
                    <h4>Confirm Password</h4>
                    <input
                      type="password"
                      // name="confirmPassword"
                      {...register("confirmPassword", {
                        validate: (value) =>
                          value === formPassword || "Passwords do not match",
                      })}
                    />
                    <p>
                      {errors.confirmPassword?.message
                        ? errors?.confirmPassword?.message
                        : ""}
                    </p>
                  </div>
                )}
                <div className="multiple">
                  <Tooltip
                    title="Please Add Your Country Code"
                    placement="top-start"
                    arrow
                  >
                    <div
                      className={`input custom_phone_input_container ${
                        errors?.phone && "custom_phone_error_input_container"
                      }`}
                    >
                      <h4>Phone Number</h4>
                      {/* <Controller
                        name="phone"
                        control={control}
                        rules={{ required: true }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => ( */}
                      <PhoneInput
                        // placeholder="Enter phone number"
                        // value={value}
                        // onChange={(val) => setValue("phone", val)}
                        // onBlur={onBlur}
                        countryCallingCodeEditable={true}
                        className="signup_phone_input"
                        // ref={ref}
                        // onCountryChange={handleCountryChange}
                        onChange={(val) => {
                          val &&
                            val !== getValues("phone") &&
                            setValue("phone", val, { shouldValidate: true });
                        }}
                        value={getValues("phone")}
                      />
                      {/* )} */}
                      {/* /> */}
                      <p>
                        {errors.phone?.message ? errors.phone?.message : ""}
                      </p>
                    </div>
                  </Tooltip>
                  <div
                    className={`input ${
                      errors?.email?.message && "error_input"
                    }`}
                  >
                    <h4>Email</h4>
                    <input
                      type="text"
                      {...register("email")}
                      disabled={user.bySocial}
                    />
                    <p>{errors.email?.message ? errors.email?.message : ""}</p>
                  </div>
                </div>
                <div className="gender">
                  <div className="label">
                    <span>Select Gender</span>
                  </div>
                  <div
                    className={`checbox ${
                      errors?.gender?.message && "error_input"
                    }`}
                  >
                    <div className="option">
                      <input
                        {...register("gender")}
                        type="radio"
                        value="MALE"
                      />
                      <span>Male</span>
                    </div>
                    <div className="option">
                      <input
                        {...register("gender")}
                        type="radio"
                        value="FEMALE"
                      />
                      <span>Female</span>
                    </div>
                    <div className="option">
                      <input
                        {...register("gender")}
                        type="radio"
                        value="UNSPECIFIED"
                      />
                      <span>I'd rather not say</span>
                    </div>
                  </div>
                </div>
                <div className={`gender`}>
                  <div className="label">
                    <span>I'm 18 years & older</span>
                  </div>
                  <div
                    className={`checbox  ${
                      errors?.isAdult?.message && "error_input"
                    }`}
                  >
                    <div className="option">
                      <input
                        {...register("isAdult")}
                        type="radio"
                        value="yes"
                      />
                      <span>Yes</span>
                    </div>
                    <div className="option">
                      <input {...register("isAdult")} type="radio" value="no" />

                      <span>No</span>
                    </div>
                  </div>
                </div>
                {showCourses && (
                  <div className="lesson__location">
                    <h4>What would you like to teach?</h4>
                    <div className="levels">
                      <div className="option">
                        <input
                          type="radio"
                          name="teachCourses"
                          id="teachingType"
                          className="signup__checbox"
                          value={teachCourses}
                          checked={teachCourses}
                          onChange={(e) => {
                            handleLocation(e);
                          }}
                        />
                        <span>Pre-recorded Online Courses</span>
                      </div>
                      <div className="option">
                        <input
                          type="radio"
                          name="teachLessons"
                          id="teachingType"
                          className="signup__checbox"
                          value={teachLessons}
                          checked={teachLessons}
                          onChange={(e) => {
                            handleLocation(e);
                          }}
                        />
                        <span>Lessons</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* <div className={`next__btn ${isFilled ? "isfilled" : ""}`}> */}
              <div
                // className={`next__btn`}
                className={`next__btn ${
                  !hasErrors(errors) &&
                  termsChecked == true &&
                  isAdult === "yes" &&
                  "isfilled"
                }`}
              >
                <div className="teacher_tos">
                  I Agree to the{" "}
                  <Link to={"/terms/teachers"} target="_blank">
                    <span className="sp-val">Terms and Conditions</span>
                  </Link>
                  <input
                    type="checkbox"
                    value={termsChecked}
                    onChange={(e) => setTermsChecked(!termsChecked)}
                  />
                </div>
                <button
                  type="submit"
                  className={
                    (hasErrors(errors) || !termsChecked || isAdult !== "yes") &&
                    "locked"
                  }
                >
                  Next
                  <img src={next} />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

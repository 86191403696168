import React, { useState, useEffect } from "react";
import {
  checkMarkTeacher,
  deleteUser,
  getTokenForTwillio,
  rejectTeachers,
  verifyTeacher,
} from "../../../ReduxToolkit/Slices/adminSlice";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import "./TeacherPersonalInfo.styles.scss";
import check from "../../../assets/AdminApp/svgs/check.svg";
import hang from "../../../assets/AdminApp/images/call.png";
import call from "../../../assets/AdminApp/images/hang.png";
import { toast } from "react-toastify";
import { getVideoIdFromUrl, updateCloudinaryUrl } from "../../../hooks";
// import axios from "axios";
import { Device } from "@twilio/voice-sdk";
import Button from "../../../Components/Button/Button";

export default function TeacherPersonalInfo({ teacher }) {
  const dispatch = useDispatch();
  let verifiedbg = teacher?.background?.status === "ACCEPTED";

  const [verified, setVerified] = useState(verifiedbg);
  const navigate = useNavigate();
  const reject = () => {
    dispatch(rejectTeachers(teacher.id)).then(() => {
      navigate("/admin");
      return toast.success("This account was rejected, thank you!");
    });
  };
  const onDelete = () => {
    dispatch(deleteUser(teacher.user.id)).then((res) => {
      if (res.payload.message === "User deleted successfully") {
        toast.success("User deleted successfully");
      }
      return navigate("/admin");
    });
  };

  const verifyBg = () => {
    dispatch(verifyTeacher(teacher.id)).then((res) => {
      if (res?.payload?.message === "Teacher approved") {
        toast.success(
          "Thank you, this teacher's background check is now verified"
        );
        setVerified(true);
      } else toast.error("Error verifying teacher please try again.");
    });
  };
  const onCheckMarkClick = () => {
    dispatch(checkMarkTeacher(teacher.id)).then((res) => {
      if (res?.payload?.checkMark) {
        toast.success("Teacher's check mark granted");
      } else {
        toast.success("Teacher's check mark removed");
      }
    });
  };

  const [device, setDevice] = useState(null);
  const [callInProgress, setCallInProgress] = useState(false);

  useEffect(() => {
    // Fetch the Twilio token
    dispatch(getTokenForTwillio()).then((res) => {
      if (res?.payload?.token) {
        try {
          const token = res?.payload?.token;

          const newDevice = new Device(token, {
            logLevel: "debug", // Increase log level for detailed logging
            codecPreferences: ["opus", "pcmu"],
          });
          newDevice.on("ready", () => {
            console.log("Device ready");
            setDevice(newDevice);
          });

          newDevice.on("error", (error) => {
            console.error("Twilio Device Error:", error.message);
          });

          newDevice.on("connect", () => {
            console.log("Call connected");
            setCallInProgress(true);
          });

          newDevice.on("disconnect", () => {
            console.log("Call disconnected");
            setCallInProgress(false);
          });

          setDevice(newDevice);
        } catch (error) {
          console.error("Error fetching token:", error);
        }
      }
    });
  }, []);

  const handleCall = async () => {
    const params = { To: "+15874331979", From: "default_identity" };
    if (!device) {
      return alert("No device available for making the call.");
    }

    try {
      const call = await device.connect({
        params: params,
        intent: "support_call",
        callerId: "+14318133968",
        rtcConstraints: {
          audio: true,
        },
      });

      call.on("accept", () => {
        console.log("Call accepted");
      });

      call.on("disconnect", () => {
        console.log("The call has been disconnected.");
      });

      call.on("reject", () => {
        console.log("The call was rejected.");
      });

      call.on("error", (error) => {
        console.error("Error during the call connection:", error.message);
      });
    } catch (error) {
      console.error("Error during the call connection:", error);
    }
  };

  const handleHangup = () => {
    // if (device) {
    //   device.disconnectAll();
    // }
  };
  const { id } = useParams();
  const handleNavigate = () => {
    navigate(`/admin/teachers/${id}/edit`);
  };
  return (
    teacher.user?.name && (
      <div className="teacher__personal__info">
        <div className="header">
          <div className="teacher__profile">
            <img src={teacher.user.profileImage?.url} alt="" />
            <span className="flex_center">
              {teacher.user.name} {teacher.user.lastName}{" "}
              {teacher.checkMark && <img src={check} alt="" />}
            </span>
            <div className="call_container">
              <img alt="" src={hang} onClick={() => handleHangup()} />
              <img alt="" src={call} onClick={() => handleCall()} />
            </div>
            <p>Teacher</p>
          </div>
          <div className="teacher__desc">
            <p>{teacher.description}</p>
          </div>
        </div>

        <div>
          <h3>Verification Video</h3>
          <div className="flex_center">
            {teacher?.testRecord && (
              <div className="sub__row">
                {!teacher?.testRecord.url?.includes("youtube.com") ? (
                  <video width="220" height="140" controls>
                    <source
                      src={updateCloudinaryUrl(teacher?.testRecord?.url)}
                      type="video/mp4"
                    />
                  </video>
                ) : (
                  <iframe
                    width="320"
                    height="240"
                    src={`https://www.youtube.com/embed/${getVideoIdFromUrl(
                      teacher?.videos[0]?.url
                    )}`}
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                )}
              </div>
            )}
          </div>

          <div className="flex_center">
            <button className="btn_send" onClick={() => onCheckMarkClick()}>
              Give Check Mark{" "}
            </button>
            <img src={check} alt="" />
          </div>
        </div>

        <div className="teacher__rates">
          <div className="group">
            <div className="row__2">
              <div className="col second">
                <span className="bg__check__span">Background Check</span>
              </div>
              <div
                className="col"
                style={{
                  justifyContent: "flex-start",
                  cursor: "pointer",
                  gap: "15px",
                }}
              >
                <button
                  className="discard"
                  onClick={() =>
                    window.open(teacher?.background?.image?.url, "_blank")
                  }
                >
                  View
                </button>
              </div>
            </div>
            <div className="row__2">
              <div className="col second">
                <span className="bg__check__span">Status</span>
              </div>
              <div className="col">
                {/* <img src={check} alt="" /> */}
                {!verified ? (
                  <button className="danger">Not verified</button>
                ) : (
                  <button className="accepted">Verified</button>
                )}
              </div>
            </div>
            {!verified && (
              <div className="row__2">
                <div className="col second">
                  <span className="bg__check__span">Action</span>
                </div>
                (
                <div className="col">
                  {/* <img src={check} alt="" /> */}
                  <button className="accepted" onClick={() => verifyBg()}>
                    Verify
                  </button>
                </div>
                )
              </div>
            )}
          </div>

          <div className="teacher__contact">
            <div className="row">
              <span>Contact Phone</span>
              <p>{teacher.user.phone}</p>
            </div>
            <div className="row">
              <span>Email Address</span>
              <p>{teacher.user.email}</p>
            </div>
            <div className="row">
              <span>Address</span>
              <p>{teacher?.user?.address.street}</p>
            </div>
            <div className="row">
              <span style={{ display: "flex", alignSelf: "center" }}>Edit</span>
              <p>
                <Button variant="new_primary" onClick={handleNavigate}>
                  Edit
                </Button>
              </p>
            </div>
          </div>
        </div>
        <div className="teacher__btns">
          <button className="pause" onClick={() => reject()}>
            Reject/Pause Account
          </button>
          <button className="delete" onClick={() => onDelete()}>
            Delete Account
          </button>
        </div>
      </div>
    )
  );
}

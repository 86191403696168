import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useStripe,
  useElements,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";

import {
  bookALesson,
  calculateStudentTotal,
  handleLessonChange,
  saveBookALesson,
} from "../../../ReduxToolkit/Slices/lessonsSlice";

import "./PaymentMethod.styles.scss";

import LoadingAnimation from "../../../SharedApp/Pages/LoadingAnimation/LoadingAnimation";
import arrowRight from "../../../assets/StudentApp/svgs/Arrow-right.svg";
import arrowLeft from "../../../assets/StudentApp/svgs/Arrow-left.svg";
import Payments from "../../Components/Payments/Payments";
import EnrollModal from "../../../SharedApp/Components/EnrollModal/EnrollModal";
import StudentPaymentMethod from "../../Components/StudentPaymentsMethod/StudentPaymentMethods";
import { getPaymentMethod } from "../../../ReduxToolkit/Slices/userSlice";
import {
  checkoutCart,
  getCartItems,
} from "../../../ReduxToolkit/Slices/coursesSlice";
import {
  convertTimezone,
  convertTimezoneFromDate2,
} from "../../../SharedApp/Components/Timezone";
import {
  bookingStudentAdminBooking,
  setStudentCart,
} from "../../../ReduxToolkit/Slices/studentSlice";
import { handlePurchase } from "../../../hooks";
import { getAdminBookingById } from "../../../ReduxToolkit/Slices/adminSlice";

export default function PaymentMethod({ checkout, isAdminBooking }) {
  const {
    lessonDate,
    selectedLesson,
    lessonName,
    selectedTeacher,
    bookingType,
    FlexibleDates,
    subscription,
  } = useSelector((state) => state.lessons);
  const { student, selectedPackage } = useSelector((state) => state.student);
  const { lessonLocation } = useSelector((state) => state.student);
  const { timezone } = useSelector((state) => state.user);
  const selectedAdminBookingLesson = useSelector(
    (state) => state.lessons?.selectedAdminBookingLesson
  );
  const [modal, setModal] = useState(false);
  const { user } = useSelector((state) => state.user);
  const [method, setMethod] = useState({});
  const [courses, setCourses] = useState([]);
  const [total, setTotal] = useState([]);
  const [busy, setBusy] = useState(false);
  const [wallet, setWallet] = useState(null);
  const [prices, setPrices] = useState({ total: 0, initial: 0 });

  const [isCreditFilled, setIsCreditFilled] = useState(false);
  const [isPaypalFilled, setIsPaypalFilled] = useState(false);
  const { bookingId, studentId } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { appointmentId } = useParams();

  const FlexibleDate = FlexibleDates.length
    ? FlexibleDates.map((dates) => moment(dates.date).format("DD/MM/YYYY"))
    : [];

  const flexAppointments = FlexibleDates.map((dates) => {
    return {
      ...dates,
      date: convertTimezoneFromDate2(
        moment(dates.date).format("YYYY-MM-DDTHH:mm:ss.sssZ"),
        timezone,
        "UTC"
      ),
    };
  });

  const handleNextClick = () => {
    const paymentError = () =>
      toast.error("Please enter a payment method first");
    if (method.paymentMethod === "CREDIT_CARD" && !isCreditFilled) {
      return toast.error("You need to fill all the values first");
    }
    if (method.paymentMethod === "PAYPAL" && !isPaypalFilled) {
      return toast.error("You need to fill paypal values first");
    }
    if (!method.accountHolderName && method.paymentMethod !== "WALLET") {
      return paymentError();
    }
    if (method.paymentMethod === "PAYPAL") {
      if (!method.paypalGmail) {
        return paymentError();
      }
    }
    let amount =
      selectedPackage?.studentTotalPrice
        ?.studentTotalAfterFeeAfterPackageDiscount;

    if (method.paymentMethod === "WALLET" && wallet < amount) {
      return toast.error("Insufficient amount in wallet");
    }

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setModal(true);
  };

  const generateDates = (startDate, number) => {
    const dates = [];
    let currentDate = moment(startDate).format("YYYY-MM-DDTHH:mm:ss.sssZ");
    // Push that date to the array
    dates.push(moment(currentDate).format("YYYY-MM-DDTHH:mm:ss.sssZ"));
    // Iterate for the desired number of weeks
    for (let i = 1; i < number; i++) {
      // Add 7 days to get the next dayOfWeek
      currentDate = moment(currentDate).add(7, "days");
      dates.push(moment(currentDate).format("YYYY-MM-DDTHH:mm:ss.sssZ"));
    }

    return dates.map((date) => convertTimezoneFromDate2(date, timezone, "UTC"));
  };

  const newDate = moment(lessonDate.date).format("DD/MM/YYYY");

  let dates = generateDates(lessonDate.date, selectedPackage.numOfLessons);

  let formatedDate = dates.map((date) => {
    return moment(date).format("DD/MM/YYYY");
  });

  const [adminBooking, setAdminBooking] = useState();
  useEffect(() => {
    isAdminBooking &&
      dispatch(getAdminBookingById(bookingId)).then((res) => {
        if (res.type.includes("fulfilled")) {
          setAdminBooking({ ...res?.payload, ...res?.payload?.adminBooking });
        }
      });
    checkout &&
      dispatch(getCartItems(student)).then((res) => {
        setCourses(res.payload.cartItems);
        setTotal(res.payload.total);
      });
  }, []);
  console.log("adminbookiing", adminBooking);
  let coursesNames = courses.length
    ? `${courses.map(
        (course, i) =>
          `${course.course.title}${i !== courses.length - 1 ? ", " : ""}`
      )}`
    : "";

  const [showLoading, setShowLoading] = useState(false);
  const handleSubmit = () => {
    setShowLoading(true);
    if (isAdminBooking) {
      handleBookAdminBooking();
    } else {
      if (checkout) {
        setBusy(true);
        dispatch(
          checkoutCart({
            checkout: {
              studentId: student,
            },
            transaction: {
              currency: "USD",
              description: `Payment for: ${coursesNames}`,
              type: "PAYMENT",
              paymentMethod: method.paymentMethod,
              senderId: user.id,
              receiverId: "ADMIN",
              status: "PENDING",
            },
          })
        ).then((res) => {
          setBusy(false);
          setShowLoading(false);
          if (res.type === "checkoutCart/rejected") {
            return toast.error(res.payload);
          }
          if (res?.payload?.data?.type === "StripeCardError") {
            return toast.error("Error paying with selected card");
          }
          if (method.paymentMethod === "PAYPAL") {
            return window.open(res.payload);
          } else {
            return navigate("/payment");
          }
        });
      } else {
        handleBookLesson();
      }
    }
  };
  useEffect(() => {}, [bookingId]);

  useEffect(() => {
    !checkout &&
      dispatch(
        calculateStudentTotal({
          studentId: student,
          teacherId: selectedTeacher.id,
          lessonId: selectedLesson.id,
          type: bookingType,
          groupLessonAppointmentId: appointmentId,
          packageId: appointmentId ? "1" : selectedPackage.id,
          location: lessonLocation,
          persisted: true,
          subscription: false,
          bookingId: bookingId ?? "",
        })
      ).then((res) => {
        if (
          res?.payload?.response?.data?.message ===
          "Teacher doesnt not reach this place"
        ) {
          navigate(-2);
          return toast.error(
            "Teacher does not reach your place, please pick a different lesson location"
          );
        }
        setPrices(res.payload.data);
      });
  }, []);

  const { cartItems } = useSelector((state) => state.student);

  const formatDateAfterUtc = (dateObj) => {
    if (!dateObj) return;
    const { year, month, day, hour, minute } = dateObj;

    // Create a moment object using the provided date and time components
    const date = moment({
      year: year,
      month: month - 1, // Months are 0-based in Moment.js
      day: day,
      hour: hour,
      minute: minute,
    });

    // Format the date to ISO 8601 format
    return date.toISOString();
  };

  const handleBookLesson = () => {
    // setShowLoading(true);
    let greater = selectedPackage.numOfLessons > 1;

    setBusy(true);
    const appointments = dates.map((date) => {
      return {
        date,
        startTime: lessonDate.startTime,
        endTime: lessonDate.endTime,
        day: lessonDate.day,
      };
    });
    dispatch(
      bookALesson({
        booking: {
          studentId: student,
          teacherId: selectedTeacher.id,
          lessonId: selectedLesson.id,
          type: bookingType,
          weeklyAppointments: {
            startTime: formatDateAfterUtc(lessonDate?.lessonDate),
          },
          flexibleAppointments: FlexibleDates.map((flexDate) => ({
            startTime: convertToUTC(flexDate?.date, timezone),
          })),
          // appointments: appointmentId
          //   ? undefined
          //   : bookingType === "WEEKLY"
          //   ? appointments
          //   : flexAppointments,
          groupLessonAppointmentId: appointmentId,
          packageId: appointmentId ? "1" : selectedPackage.id,
          location: lessonLocation,
          persisted: true,
          subscription:
            selectedPackage.numOfLessons < 2 || bookingType === "FLEXIBLE"
              ? false
              : appointmentId
              ? true
              : subscription,
        },
        transaction: {
          currency: "USD",
          description: `Booking ${selectedPackage.numOfLessons} ${
            selectedLesson.name ? selectedLesson.name : lessonName
          } session${greater && "s"}`,
          type: "PAYMENT",
          paymentMethod: method.paymentMethod,
          senderId: user.id,
          receiverId: "ADMIN",
          status: "PENDING",
        },
      })
    )
      .then((res) => {
        setBusy(false);
        setShowLoading(false);
        if (res.type === "bookALesson/rejected") {
          if (
            res?.payload?.response?.message === "You already booked this lesson"
          ) {
            return toast.error(res.payload);
          }

          if (
            res?.payload?.data?.type === "StripeCardError" ||
            res?.payload?.data?.type === "StripeInvalidRequestError"
          ) {
            return toast.error("Error paying with selected card");
          }
          if (res?.payload?.response?.data?.message === "Group not available") {
            toast.error("This group lesson is fully booked");
            return navigate(-3);
          }
          if (
            res?.payload?.response?.data?.message === "Teacher not available"
          ) {
            toast.error(
              "Teacher not available, please select a different date"
            );
            return navigate(-1);
          }
          if (res?.payload?.response?.data?.message) {
            toast.error(res?.payload?.response?.data?.message);
            return navigate(-3);
          }
          if (res?.payload?.response?.data?.error) {
            return toast.error(res?.payload?.response?.data?.error);
          }

          if (
            res.payload === "This lesson has a maximum capacity of 1 students"
          ) {
            toast.error(
              "This time has already booked by another teacher, please try another timing"
            );
            return navigate(-2);
          }
          return toast.error("Error booking lesson");
        }
        if (res?.type === "bookALesson/fulfilled") {
          if (method.paymentMethod === "PAYPAL") {
            if (res?.payload?.data?.id) {
              handlePurchase(
                res?.payload?.data?.id,
                res?.payload?.data?.Appointments,
                user,
                false
              );
              return navigate("/payment");
            }
            return window.open(res.payload.data);
          }
          handlePurchase(
            res?.payload?.data?.id,
            res?.payload?.data?.Appointments,
            user,
            false
          );
          let newCart = cartItems.filter(
            ({ lesson }) => lesson.id !== selectedLesson.id
          );
          dispatch(setStudentCart(newCart));
          dispatch(handleLessonChange({ name: "FlexibleDates", value: [] }));

          return navigate("/payment");
        }
      })
      .catch((err) => {
        setBusy(false);
      });
  };
  const convertToUTC = (date, timeZone) => {
    // Parse the date with the given time zone
    const localDate = moment.tz(date, timeZone);
    // Convert to UTC
    const utcDate = localDate.utc();
    // Format the UTC date to a desired format (optional)
    return utcDate.format();
  };
  const handleBookAdminBooking = () => {
    if (isAdminBooking && bookingId && studentId) {
      dispatch(
        bookingStudentAdminBooking({
          bookingId,
          studentId,
          transaction: {
            currency: "USD",
            description: `Payment for ${selectedAdminBookingLesson?.lessonName}`,
            type: "PAYMENT",
            paymentMethod: method.paymentMethod,
            senderId: user.id,
            receiverId: "ADMIN",
            status: "PENDING",
          },
        })
      ).then((res) => {
        if (res.type.includes("fulfilled")) {
          setShowLoading(false);
          if (method.paymentMethod === "PAYPAL") {
            if (res?.payload?.data?.id) {
              handlePurchase(
                res?.payload?.data?.id,
                res?.payload?.data?.Appointments,
                user,
                false
              );
              return navigate("/payment");
            }
            return window.open(res.payload.data);
          }
          handlePurchase(
            res?.payload?.data?.id,
            res?.payload?.data?.Appointments,
            user,
            false
          );
          let newCart = cartItems.filter(
            ({ lesson }) => lesson.id !== selectedLesson.id
          );
          dispatch(setStudentCart(newCart));
          dispatch(handleLessonChange({ name: "FlexibleDates", value: [] }));

          return navigate("/payment");
        } else {
          setShowLoading(false);

          if (res?.payload?.response?.data?.msg) {
            return toast.error(res?.payload?.response?.data?.msg);
          }
          if (res?.payload?.response?.data?.error) {
            return toast.error(res?.payload?.response?.data?.error);
          }
          if (
            res?.payload?.response?.data?.msg ===
            "You already booked this lesson"
          ) {
            return toast.error(res.payload);
          }

          if (
            res?.payload?.data?.type === "StripeCardError" ||
            res?.payload?.data?.type === "StripeInvalidRequestError"
          ) {
            return toast.error("Error paying with selected card");
          }

          if (
            res?.payload?.response?.data?.message === "Teacher not available"
          ) {
            toast.error(
              "Teacher not available, please select a different date"
            );
            return navigate(-1);
          }
          if (res?.payload?.response?.data?.message) {
            toast.error(res?.payload?.response?.data?.message);
            return navigate(-3);
          }
          if (res?.payload?.response?.data?.error) {
            return toast.error(res?.payload?.response?.data?.error);
          }

          if (
            res.payload === "This lesson has a maximum capacity of 1 students"
          ) {
            toast.error(
              "This time has already booked by another teacher, please try another timing"
            );
            return navigate(-2);
          }
          toast.error("Something went wrong");
        }
      });
    }
  };
  const { pathname } = useLocation();
  const isAdminBookingRoute = pathname.includes("/admin-booking");
  return (
    <>
      {showLoading && <LoadingAnimation />}
      <div className="payment__method__page">
        {modal && (
          <EnrollModal
            handleSubmit={handleSubmit}
            groupLessonPrice={selectedLesson?.price}
            setModal={setModal}
            dates={bookingType === "WEEKLY" ? formatedDate : FlexibleDate}
            newDate={newDate}
            checkout={checkout}
            courses={courses}
            total={total}
            bookingBusy={busy}
            bookingType={bookingType}
            className={"courses_modal"}
            prices={prices}
            adminBookingPrices={
              isAdminBookingRoute
                ? {
                    initial: adminBooking?.initial,
                    total: adminBooking?.total,
                  }
                : false
            }
          />
        )}

        <div className="payment__header">
          <h1>Choose Payment Method</h1>
        </div>
        <div className="student__payments__container">
          <StudentPaymentMethod
            paying
            setPaying={setMethod}
            setCheckWallet={setWallet}
            isCreditFilled={isCreditFilled}
            setIsCreditFilled={setIsCreditFilled}
            isPaypalFilled={isPaypalFilled}
            setIsPaypalFilled={setIsPaypalFilled}
          />
          {/* <Payments
          paymentMethod={paymentMethods}
          handleSubmit={handleSubmit}
          stripeError={stripeError}
          setPaymentMethod={setPaymentMethod}
        /> */}
          <div className="payment__method__btns">
            <button className="prev" onClick={() => navigate(-1)}>
              <img src={arrowLeft} alt="" />
              Previous
            </button>
            <button className="btn__primary" onClick={() => handleNextClick()}>
              Next
              <img src={arrowRight} alt="" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

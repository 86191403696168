import Select from "react-select";

/**
 * BookingInput Component
 *
 * @component
 * @param {Object} props - Component props
 * @param {string} props.label - The label for the input field
 * @param {Array} props.options - The options for the dropdown menu
 * @param {boolean} [props.isMulti=false] - Determines if multiple options can be selected
 * @param {any} props.value - The current value of the input field
 * @param {function} props.onChange - Function to handle changes in the selection
 * @param {Object} props.customStyles - Custom styles for the dropdown
 * @param {string} props.placeholder - Placeholder text for the dropdown
 * @param {boolean} props.menuIsOpen - Controls the visibility of the dropdown menu
 * @param {function} props.handleButtonClick - Function to open the dropdown menu
 * @param {function} props.handleMenuClose - Function to close the dropdown menu
 * @param {string} props.icon - URL of the icon to be displayed
 * @param {boolean} [props.imgWithMargin=false] - Adds margin to the icon if true
 * @example
 * return (
 *   <BookingInput
 *     label="Select Option"
 *     options={[{ value: 'chocolate', label: 'Chocolate' }]}
 *     isMulti
 *     value={selectedOptions}
 *     onChange={setSelectedOptions}
 *     customStyles={{}}
 *     placeholder="Select..."
 *   />
 * )
 */
const BookingInput = ({
  label,
  options,
  isMulti = false,
  value,
  onChange,
  customStyles,
  placeholder,
  menuIsOpen,
  handleButtonClick,
  handleMenuClose,
  icon,
  imgWithMargin,
  onIconClick,
  onSearch,
  isLoading,
  formatOptionLabel,
  disabled,
}) => {
  return (
    <div
      className={`booking_input ${disabled ? "booking_input_disabled" : ""}`}
    >
      <span className="input_name">{label}</span>
      <div className="input">
        <Select
          isMulti={isMulti}
          value={value}
          onChange={onChange}
          options={options}
          styles={customStyles}
          menuIsOpen={menuIsOpen}
          onMenuClose={handleMenuClose}
          onMenuOpen={handleButtonClick}
          className="admin_booking_dropdown"
          placeholder={placeholder}
          isLoading={isLoading}
          formatOptionLabel={formatOptionLabel}
          isDisabled={disabled}
          onInputChange={(val) => {
            if (onSearch) {
              onSearch(val);
            }
          }}
        />
        {icon && (
          <img
            src={icon}
            alt=""
            style={imgWithMargin && { marginRight: "20px" }}
            role="button"
            title={`Add ${label}`}
            onClick={onIconClick}
          />
        )}
      </div>
    </div>
  );
};

export default BookingInput;

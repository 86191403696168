import React from "react";
import moment from "moment";

import done from "../../../assets/TeacherApp/assets/svgs/File-done.svg";
import undone from "../../../assets/TeacherApp/assets/svgs/File-undone.svg";
import {
  convertTimezoneDate,
  convertTimezoneFromDate,
} from "../../../SharedApp/Components/Timezone";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function NotificationRow({
  type,
  text,
  title,
  time,
  action,
  admin,
}) {
  const { timezone } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const newTime = () => {
    return convertTimezoneFromDate(moment(time).format(), "UTC", timezone);
  };

  const handleClick = () => {
    type === "BOOKING_ADMIN"
      ? navigate("/students/admin-booking")
      : admin
      ? navigate(`/admin/teachers/${type}/payment`)
      : action(type);
  };

  return (
    <div className="notification__row" onClick={() => handleClick()}>
      {type && <img src={type === "done" ? done : undone} alt="" />}
      <div className="notification__content">
        <div className="notification__text ">
          <p>{admin ? title : text}</p> <span>{newTime()}</span>
        </div>
      </div>
    </div>
  );
}

import Button from "../../../Components/Button/Button";
import SharedModal from "../../Components/SharedModal/SharedModal";
import closeIco from "../../../assets/AdminApp/svgs/close.svg";

const SharedModalContainer = ({
  title,
  onSubmit,
  onCancel,
  children,
  isOpen,
  onClose,
  isLoading,
  submitButtonName,
}) => {
  return (
    <SharedModal isOpen={isOpen} className={"admin_booking_modal"}>
      <div className="create_booking_modal_header">
        <div className="shared_header">
          <span>{title}</span>
          <img src={closeIco} alt="" onClick={onClose} role="button" />
        </div>
        <div className="admin_booking_details">
          {children}
          <div className="admin_booking_buttons">
            <Button variant="new_cancel" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              onClick={onSubmit}
              variant="new_primary"
              isLoading={isLoading}
            >
              {submitButtonName ? submitButtonName : "Save"}
            </Button>
          </div>
        </div>
      </div>
    </SharedModal>
  );
};

export default SharedModalContainer;

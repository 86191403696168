import React, { useState } from "react";
import delBtn from "../../../assets/SVG/del-btn.svg";
import editBtn from "../../../assets/SVG/edit-btn.svg";
import arrRight from "../../../assets/SVG/arrRight.svg";
import Button from "../../../Components/Button/Button";
import closeBtn from "/src/assets/AdminApp/svgs/close.svg";
import { toast } from "react-toastify";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleSliceChange } from "../../../ReduxToolkit/Slices/teacherSlice";

const SignupStep11 = () => {
  const teacherToRegister = useSelector(
    (state) => state?.teacher?.teacherToRegister
  );
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [experiences, setExperiences] = useState(
    teacherToRegister?.experience ? teacherToRegister.experience : []
  );
  const [experience, setExperience] = useState({
    institution: "",
    JobTitle: "",
    startDate: "",
    endDate: "",
    location: "",
    employmentType: "",
  });
  const handleExperienceChange = ({ name, value }) => {
    setExperience({ ...experience, [name]: value });
  };
  const handleCloseModal = () => {
    setExperience({
      institution: "",
      JobTitle: "",
      startDate: "",
      endDate: "",
      location: "",
      employmentType: "",
    });
    setShow(false);
  };
  const handleAddExperience = () => {
    if (!validateExperience()) return;
    if (editIndex !== null) {
      // Edit mode
      const updatedExperiences = experiences?.map((exp, index) =>
        index === editIndex ? experience : exp
      );
      setExperiences(updatedExperiences);
    } else {
      // Add mode
      setExperiences([...experiences, experience]);
    }
    handleCloseModal(); // Close the modal after adding/editing the experience
  };

  const handleDeleteExperience = (index) => {
    const updatedExperiences = experiences.filter((_, i) => i !== index);
    setExperiences(updatedExperiences);
  };

  const handleEditExperience = (index) => {
    const experienceToEdit = experiences[index];
    setExperience(experienceToEdit);
    setShow(true); // Open the modal to edit the experience
    setEditIndex(index); // Set the index of the experience being edited
  };
  const validateExperience = () => {
    const {
      institution,
      JobTitle,
      startDate,
      endDate,
      location,
      employmentType,
    } = experience;
    const missingFields = [];

    if (!institution) missingFields.push("Institution");
    if (!JobTitle) missingFields.push("Job Title");
    if (!startDate) missingFields.push("Start Date");
    if (!endDate) missingFields.push("End Date");
    if (!location) missingFields.push("Location");
    if (!employmentType) missingFields.push("Employment Type");

    if (missingFields.length > 0) {
      toast.warning(
        `Please fill out the following fields: ${missingFields.join(", ")}`
      );
      return false;
    }
    return true;
  };
  const dispatch = useDispatch();
  const handleSubmit = () => {
    if (!experiences?.length) {
      return toast.error("Empty Experience");
    }
    dispatch(
      handleSliceChange({
        name: "teacherToRegister",
        value: {
          ...teacherToRegister,
          experience: experiences,
        },
      })
    );
    navigate("/teacher-signup/step-eleven");
  };
  return (
    <div className="signup_experience_section_container">
      <div className="signup_experience_section">
        <div className="title">
          <h1>Work Experience</h1>
        </div>
        <div className="experience_container">
          <p className="text_description">
            Showcasing your expertise builds trust with parents and students,
            and gets you matched with the right students. Highlight your skills
            and qualifications in subjects like math, languages, finance, arts
            and crafts, science, music, programming, and more. A detailed
            profile helps you stand out and attracts more opportunities.
          </p>
          <div className="exp_list">
            {experiences &&
              experiences?.map((el, index) => (
                <ExperienceItem
                  key={index}
                  {...el}
                  index={index}
                  onDelete={handleDeleteExperience}
                  onEdit={handleEditExperience}
                />
              ))}
          </div>
          <div className="add_experience_button">
            <span className="xp" onClick={() => setShow(true)} role="button">
              Add {!!experiences.length && "another"} work experience
              <span className="plus">+</span>
            </span>
          </div>

          <div className="button_exp">
            <Button
              variant={"cancel"}
              onClick={() => navigate("/teacher-signup/step-nine")}
            >
              Go back
            </Button>
            <Button variant="new_secondary" onClick={handleSubmit}>
              Proceed
              <img src={arrRight} alt="" />
            </Button>
          </div>
        </div>
        {show && (
          <ExperienceModal
            experience={experience}
            handleExperienceChange={handleExperienceChange}
            handleAddExperience={handleAddExperience}
            handleCloseModal={handleCloseModal}
          />
        )}
      </div>
    </div>
  );
};

export const ExperienceModal = ({
  experience,
  handleExperienceChange,
  handleAddExperience,
  handleCloseModal,
}) => {
  return (
    <div className="xp_modal_overlay">
      <div className="xp_modal_container">
        <img
          src={closeBtn}
          className="close"
          alt=""
          onClick={handleCloseModal}
        />
        <p>
          Showcasing your expertise builds trust with parents and students, and
          gets you matched with the right students. Highlight your skills and
          qualifications in subjects like math, languages, finance, arts and
          crafts, science, music, programming, and more. A detailed profile
          helps you stand out and attracts more opportunities.
        </p>
        <div className="inputs">
          <div className="input">
            <span>Job Title</span>
            <input
              type="text"
              placeholder="Ex. Mathematics Teacher"
              name="JobTitle"
              value={experience.JobTitle}
              onChange={(e) =>
                handleExperienceChange({
                  name: e.target.name,
                  value: e.target.value,
                })
              }
            />
          </div>
          <div className="input">
            <span>Institution/Organization</span>
            <input
              type="text"
              placeholder="Ex. Springfield, USA"
              name="institution"
              value={experience.institution}
              onChange={(e) =>
                handleExperienceChange({
                  name: e.target.name,
                  value: e.target.value,
                })
              }
            />
          </div>
          <div className="input">
            <span>Employment Type</span>
            <select
              name="employmentType"
              value={experience.employmentType}
              onChange={(e) =>
                handleExperienceChange({
                  name: e.target.name,
                  value: e.target.value,
                })
              }
            >
              <option value="">Employment Type</option>
              <option value="Full-time">Full-time</option>
              <option value="Part-time">Part-time</option>
              <option value="Contract">Contract</option>
            </select>
          </div>
          <div className="multiple">
            <div className="input">
              <span>Start date</span>
              <input
                type="date"
                name="startDate"
                value={experience.startDate}
                onChange={(e) =>
                  handleExperienceChange({
                    name: e.target.name,
                    value: e.target.value,
                  })
                }
              />
            </div>
            <div className="input">
              <span>End date</span>
              <input
                type="date"
                name="endDate"
                value={experience.endDate}
                onChange={(e) =>
                  handleExperienceChange({
                    name: e.target.name,
                    value: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="input">
            <span>Location</span>
            <input
              type="text"
              placeholder="Ex. New York, USA"
              name="location"
              value={experience.location}
              onChange={(e) =>
                handleExperienceChange({
                  name: e.target.name,
                  value: e.target.value,
                })
              }
            />
          </div>
          <div className="modal_submit">
            <Button variant="new_secondary" onClick={handleAddExperience}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ExperienceItem = ({
  institution,
  JobTitle,
  startDate,
  endDate,
  location,
  onDelete,
  onEdit,
  index,
  employmentType,
}) => {
  return (
    <div className="signup_experience_item">
      <div className="col">
        <div className="header">
          <span className="logo">{institution[0].toUpperCase()}</span>
          <div className="title">
            <span>{institution}</span>
          </div>
        </div>
        <div className="job_title">
          <span>{JobTitle}</span>
        </div>
        <div className="date_issue">
          <span>
            {moment(startDate).format("DD/MM/YYYY")} -{" "}
            {moment(endDate).format("DD/MM/YYYY")}
          </span>
        </div>
        <div className="exp_location">
          {location} <li>{employmentType}</li>
        </div>
      </div>
      <div className="col">
        <div className="buttons">
          <img src={editBtn} alt="" onClick={() => onEdit(index)} />
          <img src={delBtn} alt="" onClick={() => onDelete(index)} />
        </div>
      </div>
    </div>
  );
};
export default SignupStep11;

import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";

import "./StudentPaymentMethods.styles.scss";
import {
  getPaymentMethod,
  getUserTransactions,
  setPaymentMethod,
  updatePaymentMethod,
} from "../../../ReduxToolkit/Slices/userSlice";
import { PaypalInfo } from "./PaymentMethods";
import BankTransfer from "../../../SharedApp/Components/BankTransfer/BankTransfer";
import { useLocation } from "react-router-dom";
import { Steps } from "intro.js-react";
import Note from "../../../TeacherApp/Components/Note/Note";
import MyWallets from "../../Pages/MyWallets/MyWallets";

export default function StudentPaymentMethod({
  paying,
  setPaying,
  wallets,
  setCheckWallet,
  setIsCreditFilled,
  isCreditFilled,
  setIsPaypalFilled,
  isPaypalFilled,
}) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const elements = useElements();
  const stripe = useStripe();
  const stripeElement = useRef(null);

  const notify = (message) => toast.success(message);
  const sendError = (message) => toast.error(message);

  const [method, setMethod] = useState({
    paymentMethod: "CREDIT_CARD",
    receiveMethod: "",
    cardId: "",
    paypalGmail: "",
    userId: user.id,
    currency: "USD",
    type: "",
    last4: "",
    accountHolderName: "",
  });
  const [prevMethod, setPrevMethod] = useState(false);
  const [ccInfo, setccInfo] = useState({
    accountHolderName: method.accountHolderName,
    cardNumber: "",
    Expiry: "",
    cvc: "",
    last4: "",
  });
  const [paypalInfo, setPaypalInfo] = useState({
    accountHolderName: "",
    email: method.paypalGmail,
  });
  const [cardId, setCardId] = useState(null);
  const [wallet, setWallet] = useState(null);
  const [bank, setBank] = useState(false);
  const [busy, setBusy] = useState(null);
  const [fetched, setFetched] = useState(false);
  const [stripeError, setStripeError] = useState(undefined);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    return () => {
      stripeElement.current = null;
    };
  }, []);

  useEffect(() => {
    paying && setPaying(method);
  }, [method]);

  const location = useLocation();
  useEffect(() => {
    dispatch(getPaymentMethod()).then((res) => {
      if (res.payload.length) {
        setMethod(res.payload[0]);

        setPrevMethod(true);
        if (res.payload.receiveMethod === "BANK_TRANSFER") {
          setBank(true);
        }
        return setFetched(true);
      }
      setFetched(true);
    });
  }, [location]);

  useEffect(() => {
    if (
      ccInfo.Expiry &&
      (ccInfo.accountHolderName || method.accountHolderName) &&
      ccInfo.cardNumber &&
      ccInfo.cvc
    ) {
      setIsCreditFilled && setIsCreditFilled(true);
    } else {
      setIsCreditFilled && setIsCreditFilled(false);
    }

    if (method.accountHolderName) {
      setIsCreditFilled && setIsCreditFilled(true);
    }
  }, [
    method.last4,
    ccInfo.Expiry,
    ccInfo.accountHolderName,
    ccInfo.cardNumber,
    ccInfo.cvc,
  ]);

  useEffect(() => {
    if (method.paypalGmail) {
      setPaypalInfo({ ...paypalInfo, email: method.paypalGmail });
    }
    if (method.accountHolderName && paypalInfo.email) {
      setIsPaypalFilled(true);
    } else setIsPaypalFilled(false);
  }, [
    paypalInfo.accountHolderName,
    paypalInfo.email,
    method.paypalGmail,
    method.accountHolderName,
  ]);

  const handleMethodChange = (e) => {
    const { name, value } = e.target;

    setMethod({
      ...method,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (elements == null) {
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(
        CardNumberElement,
        CardExpiryElement,
        CardCvcElement
      ),
    });
    if (error) {
      setStripeError(error.message);
      setCardId(undefined);
      handleMethodChange({
        target: {
          name: "cardId",
          value: cardId,
        },
      });
      return sendError(error.message);
    } else {
      setStripeError(undefined);
      // setCardId(paymentMethod.id);
      setMethod({
        ...method,
        last4: paymentMethod.card.last4 ? paymentMethod.card.last4 : "",
      });
      return onSubmit(paymentMethod.id, paymentMethod.card.last4);
    }
  };

  useEffect(() => {
    paying &&
      !wallets &&
      dispatch(getUserTransactions()).then((res) => {
        let wallet = res?.payload?.wallet?.balance;
        if (wallet) {
          setCheckWallet(wallet);
          setWallet(wallet);
        }
      });
  }, []);

  const onSubmit = (cardId, last4) => {
    if (method.paymentMethod === "PAYPAL" && !method.paypalGmail) {
      return sendError("Please enter your paypal email address");
    }
    if (stripeError) {
      return sendError(stripeError);
    }
    if (prevMethod) {
      setBusy(true);
      return dispatch(
        updatePaymentMethod({
          cardId: cardId ? cardId : method.cardId,
          paymentMethod: method.paymentMethod,
          userId: user.id,
          receiveMethod: method.receiveMethod ? method.receiveMethod : "PAYPAL",
          paypalGmail: method.paypalGmail
            ? method.paypalGmail
            : "null@null.null",
          currency: "USD",
          type: method.type,
          bankDetails: method.bankDetails,
          accountHolderName: method.accountHolderName,
          last4: last4 ? last4 : method.last4,
        })
      )
        .then((res) => {
          setBusy(false);
          if (res.type === "updatePaymentMethod/fulfilled") {
            notify("Payment method updated, thank you!");
            setEdit(false);
          } else {
            sendError("Error updating payment");
          }
        })
        .catch(() => setBusy(false));
    } else {
      setBusy(true);
      dispatch(
        setPaymentMethod({
          cardId,
          paymentMethod: method.paymentMethod,
          userId: user.id,
          receiveMethod: "PAYPAL",
          paypalGmail: method.paypalGmail
            ? method.paypalGmail
            : "null@null.null",
          currency: "USD",
          type: method.type,
          accountHolderName: method.accountHolderName,
          last4,
        })
      )
        .then((res) => {
          setBusy(false);
          if (res.type === "setPaymentMethod/fulfilled") {
            notify("Payment added, thank you!");
            setEdit(false);
          } else {
            sendError("Error adding payment");
          }
        })
        .catch(() => setBusy(false));
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSubmit();
    }
  };
  const [showGuide, setShowGuide] = useState(true);
  const steps = [
    {
      element: ".student__payments_container",
      title: "Payment",
      intro: "Here, you can see and edit your prefered payment type",
    },
    {
      element: ".current__payments",
      title: "Current payments",
      intro: "By clicking an option you select your prefered payment type",
    },
  ];

  return (
    <>
      {/* {!!pathname.includes("/students") && <MyWallets />} */}
      {fetched && (
        <div className="" style={{ marginTop: "30px" }}>
          <Note
            p1={
              "Please note that we only accept Credit Cards and Paypal as payment option."
            }
            p2={
              "You need to add atleast 1 payment option before booking a lesson"
            }
          />
          <div className="student__payments_container accordion">
            {showGuide && location.pathname.includes("payment") && (
              <Steps
                enabled={user.helpGuide}
                steps={steps}
                initialStep={0}
                onExit={() => setShowGuide(false)}
                options={{ exitOnOverlayClick: false }}
              />
            )}
            <div className="pays">
              <div className="title">
                <h3>Payment Methods</h3>
                <div className="current__payments">
                  <span
                    className={`${
                      method.paymentMethod === "CREDIT_CARD" ? "selected" : ""
                    }`}
                    onClick={() =>
                      handleMethodChange({
                        target: { name: "paymentMethod", value: "CREDIT_CARD" },
                      })
                    }
                  >
                    Credit Card
                  </span>
                  <span
                    className={`${
                      method.paymentMethod === "PAYPAL" ? "selected" : ""
                    }`}
                    onClick={() =>
                      handleMethodChange({
                        target: { name: "paymentMethod", value: "PAYPAL" },
                      })
                    }
                  >
                    PayPal
                  </span>
                  {paying && !wallets && (
                    <span
                      className={`${
                        method.paymentMethod === "WALLET" ? "selected" : ""
                      }`}
                      onClick={() =>
                        handleMethodChange({
                          target: { name: "paymentMethod", value: "WALLET" },
                        })
                      }
                    >
                      Wallet
                    </span>
                  )}
                </div>
              </div>
              <div className="inner__container">
                {method.paymentMethod === "CREDIT_CARD" ? (
                  method.cardId && !edit ? (
                    <Accordions setEdit={setEdit} last4={method.last4} />
                  ) : (
                    <form
                      className="newinputs"
                      onSubmit={(e) => handleSubmit(e)}
                    >
                      <div className="input">
                        <h4>Account Holder Name</h4>
                        <input
                          type="text"
                          name="accountHolderName"
                          value={method.accountHolderName}
                          onChange={(e) => {
                            setccInfo({
                              ...ccInfo,
                              accountHolderName: e.target.value,
                            });
                            handleMethodChange(e);
                          }}
                        />
                      </div>
                      <div className="input">
                        <h4>Card Number</h4>
                        {CardNumberElement && (
                          <CardNumberElement
                            onChange={(e) =>
                              setccInfo({ ...ccInfo, cardNumber: e.complete })
                            }
                            className="input2"
                          />
                        )}
                      </div>
                      <div className="input__small input">
                        <div className="col1">
                          <h4>Expiration Date</h4>
                          <CardExpiryElement
                            onChange={(e) =>
                              setccInfo({ ...ccInfo, Expiry: e.complete })
                            }
                            className="input2"
                          />
                        </div>
                        <div className="col2">
                          <h4>CVC Number</h4>
                          <CardCvcElement
                            onChange={(e) => {
                              setccInfo({ ...ccInfo, cvc: e.complete });
                            }}
                            className="input2"
                          />
                        </div>
                      </div>
                      {stripeError && <p>Error: {stripeError} </p>}
                      <button
                        type="submit"
                        className={
                          busy ? "btn__primary fetching" : "btn__primary"
                        }
                      >
                        Submit
                      </button>
                    </form>
                  )
                ) : method.paymentMethod === "PAYPAL" ? (
                  <PaypalInfo
                    handleKeyDown={handleKeyDown}
                    handleMethodChange={handleMethodChange}
                    onSubmit={onSubmit}
                    method={method}
                    setPaypalInfo={setPaypalInfo}
                    paypalInfo={paypalInfo}
                  />
                ) : (
                  <div className="wallet_container">
                    <p>Amount in wallet:</p>
                    <span>{wallet / 100} </span>
                    <span>USD</span>
                  </div>
                )}
              </div>
              {/* {!paying && (
            <>
              <div className="title">
                <h3>Refund Methods</h3>
                <div className="current__payments">
                  <span
                    className={`${
                      method.receiveMethod === "BANK_TRANSFER" ? "selected" : ""
                    }`}
                    onClick={() =>
                      handleMethodChange({
                        target: {
                          name: "receiveMethod",
                          value: "BANK_TRANSFER",
                        },
                      })
                    }
                  >
                    Bank Transfer
                  </span>
                  <span
                    className={`${
                      method.receiveMethod === "PAYPAL" ? "selected" : ""
                    }`}
                    onClick={() =>
                      handleMethodChange({
                        target: { name: "receiveMethod", value: "PAYPAL" },
                      })
                    }
                  >
                    PayPal
                  </span>
                </div>
              </div>
              <div className="item">
                {method.receiveMethod === "BANK_TRANSFER" ? (
                  !bank ? (
                    <button
                      className={
                        busy
                          ? "btn__primary fetching bank_button"
                          : "btn__primary bank_button"
                      }
                      onClick={() => setBank(true)}
                    >
                      Edit Bank Details
                    </button>
                  ) : (
                    <div className=" show">
                      <BankTransfer
                        setMethod={setMethod}
                        toggle={true}
                        method={method}
                        onSubmit={onSubmit}
                        handleMethodChange={handleMethodChange}
                      />
                    </div>
                  )
                ) : (
                  <PaypalInfo
                    handleKeyDown={handleKeyDown}
                    handleMethodChange={handleMethodChange}
                    onSubmit={onSubmit}
                    method={method}
                  />
                )}
              </div>
            </>
          )} */}
              {/* <div className="inner__container">
            <BankTransfer />
          </div> */}
            </div>
            {/* <PromoCodes /> */}
          </div>
        </div>
      )}
    </>
  );
}
const Accordions = ({ last4, setEdit }) => {
  return (
    <div className="student__accordion">
      <div className="item">
        <div className={`content show`}>
          <div className="inputs">
            <div className="extra__card__details">
              <div className="card__col">
                {/* <img src={image} alt="" /> */}
                <div className="name">
                  <span>Card number **** {last4}</span>
                  {/* <p>Card expires at 09/24</p> */}
                </div>
              </div>
              <div className="cards__btns">
                {/* <button
                  className="card__delete"
                >
                  Delete
                </button> */}
                <button onClick={() => setEdit(true)}>Edit</button>
              </div>
            </div>
            {/* <div className="card__infos">
              {data.map((row) => (
                <div className="info__row">
                  <span className="row__title">{row.name}</span>
                  <div className="row__answer">{row.value} </div>
                </div>
              ))}
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
const Alert = ({ img, text, btn1Text, btn1Color, btn2 }) => {
  return (
    <div className="alert__container">
      <img src={img} alt="" />
      <p>{text} </p>
      <div className="alert__btns">
        <button style={{ backgroundColor: `#${btn1Color}` }}>{btn1Text}</button>
        {btn2 && <button className="cancel__btn">No, Cancel</button>}
      </div>
    </div>
  );
};

import React, { useEffect, useLayoutEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import SharedLayout from "../Components/SharedDashboardLayout/SharedLayout/SharedLayout";
import TeacherRequests from "./Pages/TeacherRequests/TeacherRequests";
import TeacherEarnings from "./Pages/TeacherEarnings/TeacherEarnings";
import SetupLesson from "./Pages/SetupLesson/SetupLesson";
import Schedule from "./Pages/Schedule/Schedule";
import Review from "./Pages/Review/Review";
import MyMessages from "../StudentApp/Pages/MyMessages/MyMessages";
import FAQ from "../StudentApp/Pages/FAQ/FAQ";
import TeacherPaymentMethod from "./Pages/TeacherPaymentMethod/TeacherPayment";
import AccountSettings from "./Pages/AccountSettings/AccountSettings";
import TeacherLanding from "../StudentApp/Pages/TeacherLanding/TeacherLanding";
import Notifications from "../StudentApp/Pages/Courses/Notifications/Notifications";
import { CheckLoggedIn } from "../hooks";
import { useDispatch, useSelector } from "react-redux";
import { getTeacherById } from "../ReduxToolkit/Slices/teacherSlice";
import CoursesLanding from "./Pages/Courses/Landing/CoursesLanding";
import NewOnlineCourse from "./Pages/Courses/NewOnlineCourse/NewOnlineCourse";
import NotFound from "../SharedApp/Pages/404/NotFound";
import AddCurriculum from "./Pages/Courses/AddCurriculum/AddCurriculum";
import PaymentPlan from "./Pages/Courses/PaymentPlan/PaymentPlan";
import ReviewAndSubmit from "./Pages/Courses/ReviewAndSubmit/ReviewAndSubmit";
import Complete from "./Pages/Complete/Complete";
import DraftCourses from "./Pages/Courses/DraftCourses/DraftCourses";
import EditCourse from "./Pages/Courses/EditCourse/EditCourse";
import AllPayments from "./Pages/AllPayments/AllPayments";
import AddVideo from "./Components/Courses/AddVideo/AddVideo";
import StartCourse from "../SharedApp/Pages/Courses/StartCourse/StartCourse";
import Promo from "../AdminApp/Pages/Promo/Promo";
import CreatePromo from "../AdminApp/Pages/CreatePromo/CreatePromo";
import ViewPromo from "../AdminApp/Pages/ViewPromo/ViewPromo";
import EditPromo from "../AdminApp/Pages/EditPromo/EditPromo";
import TeacherQuestions from "./Pages/TeacherQuestions/TeacherQuestions";
import { setUser } from "../ReduxToolkit/Slices/userSlice";
import TeacherCalendar from "./Pages/TeacherCalendar/TeacherCalendar";
import HomeWorks from "./Pages/HomeWorks/HomeWorks";
import ViewStudentsHomeworks from "./Pages/ViewStudentsHomeworks/ViewStudentsHomeworks";
import RequestRecommendation from "./Pages/RequestRecommendation/RequestRecommendation";
import MyRecommendations from "./Pages/MyRecommendations/MyRecommendations";
import TeacherNotes from "./Pages/TeacherNotes/TeacherNotes";
import ViewAllNotes from "./Pages/ViewAllNotes/ViewAllNotes";
import Chat from "../StudentApp/Pages/Chat/Chat";

function TeacherApp() {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const showCourses = useSelector((state) => state.user);
  if (user?.role !== "TEACHER") {
    navigate("/login/sessionExpired");
  }
  const data = [
    {
      step: 1,
      title: "Lessons",
      subtitle: "Setup Your Lessons",
    },
    {
      step: 2,
      title: "Availability",
      subtitle: "Select Your Availability",
    },
  ];
  const CourseTrackData = [
    {
      step: 1,
      title: "Add Information",
      subtitle: "Setup Your Course",
    },
    {
      step: 2,
      title: "Add Curriculum",
      subtitle: "Create Your Curriculum",
    },
    // {
    //   step: 3,
    //   title: "Add Price",
    //   subtitle: "Setup Pricing Plan",
    // },
    // {
    //   step: 3,
    //   title: "Publish Course",
    //   subtitle: "Review and Publish",
    // },
  ];
  const location = useLocation();

  const dispatch = useDispatch();

  const { refreshUser } = useSelector((state) => state.user);
  const { teacher, refreshTeacher } = useSelector((state) => state.teacher);

  useEffect(() => {
    try {
      let newTeacher = localStorage.getItem("teacher");
      if (!newTeacher && !CheckLoggedIn()) {
        return;
      } else {
        if (newTeacher && JSON.parse(newTeacher)?.id && !teacher.id) {
          dispatch(getTeacherById(JSON.parse(newTeacher).id)).then((res) => {
            dispatch(setUser(res?.payload?.user));
          });
        }
      }
    } catch (err) {
      return navigate("/login/sessionExpired");
    }
  }, [teacher.id, refreshTeacher]);

  useEffect(() => {
    let newTeacher = localStorage.getItem("teacher");
    if (!newTeacher && !CheckLoggedIn()) {
      return;
    }
    if (!teacher.id) {
      return;
    }
    JSON.parse(newTeacher)?.id &&
      dispatch(getTeacherById(JSON.parse(newTeacher).id)).then((res) => {
        dispatch(setUser(res?.payload?.user));
      });
  }, [refreshUser]);

  useEffect(() => {
    if (!CheckLoggedIn()) {
      navigate("/login/sessionExpired");
    }
  }, [location]);

  return (
    <div>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<SharedLayout teacherLayout />}>
          <Route path="/" element={<TeacherLanding />} />
          <Route path="/myclasses" element={<TeacherLanding />} />
          <Route path="/myrequests" element={<TeacherRequests />} />
          <Route path="/myearnings" element={<TeacherEarnings />} />
          <Route path="/payment" element={<TeacherPaymentMethod />} />
          <Route
            path="/subjects/"
            element={
              <SetupLesson
                customClass="teacher__setup__lesson"
                customLinks={true}
                given={data}
                track
              />
            }
          />
          <Route
            path="/subjects/1"
            element={
              <SetupLesson
                customClass="teacher__setup__lesson"
                customLinks={true}
                given={data}
                track
              />
            }
          />
          <Route
            path="/subjects/2"
            element={
              <Schedule
                customClass="teacher__schedule"
                customLinks={true}
                given={data}
              />
            }
          />
          <Route
            path="/subjects/3"
            element={
              <Review
                customClass="teacherDash__review"
                customLinks={true}
                given={data}
              />
            }
          />
          <Route path="/mymessages" element={<Chat />} />
          <Route path="/roster" element={<TeacherCalendar roster />} />
          <Route path="/calendar" element={<TeacherCalendar />} />
          <Route path="/notes" element={<TeacherNotes />} />
          <Route path="/notes/all" element={<ViewAllNotes />} />
          <Route path="/homework" element={<HomeWorks />} />
          <Route path="/recommendation" element={<RequestRecommendation />} />
          <Route
            path="/recommendation/my-recommendations"
            element={<MyRecommendations />}
          />
          {/* <Route path="/homework/edit" element={<HomeWorks edit />} /> */}
          <Route path="/homework/edit/:id" element={<HomeWorks edit />} />
          <Route path="/homework/view" element={<ViewStudentsHomeworks />} />
          <Route path="/mymessages/:id" element={<MyMessages />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/questions/:id" element={<TeacherQuestions />} />
          <Route path="/settings" element={<AccountSettings />} />
          <Route
            path="/notifications"
            element={<Notifications type={"teacher"} />}
          />
          {/* courses */}
          {/* <Route path="/courses" element={<CoursesLanding />} /> */}
          {!!showCourses && (
            <>
              <Route
                path="/courses/create"
                element={<NewOnlineCourse given={CourseTrackData} />}
              />
              <Route
                path="/courses/edit/:id"
                element={<NewOnlineCourse given={CourseTrackData} />}
              />
              <Route
                path="/courses/create/2"
                element={<AddCurriculum given={CourseTrackData} />}
              />
              <Route
                path="/courses/edit/:id/2"
                element={<AddCurriculum given={CourseTrackData} edit />}
              />
              <Route path="/courses/create/3/all" element={<AllPayments />} />
              <Route
                path="/courses/create/3"
                element={<ReviewAndSubmit given={CourseTrackData} />}
              />
              <Route path="/courses/create/5" element={<Complete custom />} />
              <Route
                path="/courses/draft"
                element={<DraftCourses title={"Draft Courses"} isDraft />}
              />
              <Route
                path="/courses/mycourses"
                element={<DraftCourses title={"Published Courses"} />}
              />
              <Route
                path="/courses/mycourses/:id"
                element={<StartCourse teacher />}
              />
            </>
          )}
          <Route path="/coupon" element={<Promo teacher />} />
          <Route path="/coupon/new" element={<CreatePromo teacher />} />
          <Route path="/coupon/:id" element={<ViewPromo teacher />} />
          <Route path="/coupon/:id/edit" element={<EditPromo teacher />} />
        </Route>
      </Routes>
    </div>
  );
}
export default TeacherApp;

import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import moment from "moment";

import "./BookingDetails.styles.scss";
import {
  getBookingsById,
  getFilteredTeachers,
  getTeachers,
  reassignStudent,
} from "../../../ReduxToolkit/Slices/adminSlice";
import TeachersTable from "../TeachersTable/TeachersTable";
import { toast } from "react-toastify";

export default function BookingDetails() {
  const { id } = useParams();

  // add travel chjharge and distance
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [reAssign, setReAssign] = useState(false);
  const [filters, setFilters] = useState({
    status: "APPROVED",
    limit: "10",
    page: currentPage.toString(),
  });
  const [teachers, setTeachers] = useState([]);
  const [booking, setBooking] = useState({});
  const [refresh, setRefersh] = useState(false);
  useEffect(() => {
    dispatch(getBookingsById(id)).then((res) => {
      setBooking(res.payload);
    });
  }, [id, refresh]);

  useEffect(() => {
    if (reAssign) {
      dispatch(getFilteredTeachers(filters)).then((res) => {
        setTeachers(res?.payload?.teachers);
        setPage(res?.payload?.numOfPages);
      });
    }
  }, [currentPage, filters, reAssign, page]);

  const onSearchPending = (e) => {
    setFilters({ ...filters, search: e.target.value });
  };
  const onReAssign = (id) => {
    if (id === booking?.lesson?.teacher.id) {
      return toast.error("You can not assing to same teacher");
    }
    dispatch(
      reassignStudent({
        oldBookingId: booking?.id,
        teacherId: id,
        lessonId: booking?.lesson?.id,
      })
    ).then((res) => {
      setReAssign(false);
      if (res.type.includes("fulfilled")) {
        setRefersh(!refresh);
        return toast.success("Booking reassigned");
      } else return toast.error("Error");
    });
  };
  const MappedDates = () => {
    if (booking?.Appointments?.length) {
      let mapped = booking?.Appointments.map((el) =>
        moment(el.startTime).format("DD/MM/YYYY")
      );
      mapped = mapped.join(" ,");
      return mapped;
    } else return "no appointments";
  };

  let MappedLessonTimes = () => {
    if (!booking.Appointments) {
      return null;
    }
    let times = booking?.Appointments.map(
      (app) =>
        `${moment(app.startTime).format("DD/MM/YYYY HH:mm")} - ${moment(
          app.endTime
        ).format("HH:mm")} (${app.status})`
    );
    return times.join(", ");
  };

  const lessonData = [
    { name: "Subject", text: booking?.lesson?.title },
    { name: "Summary", text: booking?.lesson?.summary },
    { name: "Booking Status", text: booking?.status },
    {
      name: "Lesson Location",
      text: booking?.lesson?.location?.map((el) => el).join(","),
    },
    { name: "Teacher Travel Distance", text: booking?.lesson?.travelDistance },
    { name: "Teacher Travel Charge", text: booking?.lesson?.travelCharge },
    {
      name: "Scheduled Dates",
      text: MappedDates(),
    },
    // { name: "Lesson Completed", text: "1" },
    // { name: "Admin Earning", text: "$4" },
    { name: "Rating", text: booking?.lesson?.teacher?.rating },
    {
      name: "Lesson time",
      text: MappedLessonTimes(),
      // getLessonDuration(
      //   booking?.groupLessonAppointment["startTime"],
      //   booking?.groupLessonAppointment["endTime"]
      // ),
    },
    {
      name: "Online Lesson Price",
      text: booking?.lesson?.onlinePrice / 100,
    },
    {
      name: "Teacher Place Lesson Price",
      text: booking?.lesson?.teacherPrice / 100,
    },
    {
      name: "Student Place Lesson Price",
      text: booking?.lesson?.studentPrice / 100,
    },
    {
      name: "Age range",
      text: `${booking?.lesson?.fromAge} - ${booking?.lesson?.toAge} `,
    },
    {
      name: "Maximum number of students",
      text: booking?.lesson?.maxStudents,
    },
    {
      name: "Booking Created At",
      text: moment(booking?.createdAt).format("DD/MM/YYYY"),
    },
  ];

  const StudentDetails = [
    { name: "Name", text: booking?.student?.user?.name },
    { name: "Last name", text: booking?.student?.user?.lastName },
    { name: "Email", text: booking?.student?.user?.email },
    { name: "Contact", text: booking?.student?.user?.phone },
    {
      name: "Address",
      text: booking?.student?.user?.address?.street,
    },
  ];
  const TeacherDetails = [
    { name: "Name", text: booking?.lesson?.teacher?.user?.name },
    { name: "Email", text: booking?.lesson?.teacher?.user?.email },
    { name: "Phone", text: booking?.lesson?.teacher?.user?.phone },
    {
      name: "Address",
      text: `${booking?.lesson?.teacher?.user?.address?.country},${booking?.lesson?.teacher?.user?.address?.city}, ${booking?.lesson?.teacher?.user?.address?.street}`,
    },
  ];
  const data = [
    {
      header: "Lesson Details",
      array: lessonData,
      link: "",
    },
    {
      header: "Student Details",
      array: StudentDetails,
      link: `/admin/students/${booking?.student?.id}/`,
    },

    {
      header: "Teacher Details",
      array: TeacherDetails,
      link: `/admin/teachers/${booking?.lesson?.teacher?.id}/`,
    },
  ];
  const [dashboard, setDashboard] = useState([
    {
      totalBookings: 0,
      totalLessons: 0,
      totalStudents: 0,
      totalTeachers: 0,
    },
  ]);

  useEffect(() => {
    setFilters({ ...filters, page: currentPage });
  }, [currentPage]);

  return reAssign ? (
    <div className="teachers__page">
      <TeachersTable
        reAssign={true}
        onReAssign={(id) => onReAssign(id)}
        type={"Please Select A Teacher To Re-assign"}
        teachers={teachers}
        currentPage={currentPage}
        page={page}
        setCurrentPage={setCurrentPage}
        onSearch={onSearchPending}
        nbRows={3}
        dashboard={dashboard}
      />
    </div>
  ) : (
    <div className="booking__details">
      {data.map((cont, i) => (
        <div
          className="booking__section pointer"
          key={i}
          onClick={() => navigate(cont.link)}
        >
          <p className="link">{cont.header}</p>
          <div className="section__container">
            <div className="col">
              {cont.array.map((el, j) => (
                <div className="row" key={j}>
                  <span>{el.name}</span>
                  <p>
                    {el.name === "Booking Status" ? (
                      <div>
                        <button className="confirmed">{el.text}</button>
                        {el.text !== "COMPLETED" &&
                          el.text !== "REASSIGNED" && (
                            <button
                              className="rejected"
                              onClick={() => setReAssign(true)}
                            >
                              Re-Assign
                            </button>
                          )}
                      </div>
                    ) : (
                      el.text
                    )}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

import { type } from "@testing-library/user-event/dist/type";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { currencylist } from "../../../assets/SharedApp/data/CountryList";
import { getRequirements } from "../../../ReduxToolkit/Slices/paymentSlice";
import "../../../StudentApp/Components/PaymentAccordion/Accordion.styles.scss";

import "./BankTransfer.styles.scss";

function BankTransfer({
  toggle,
  method,
  setMethod,
  onSubmit,
  handleMethodChange,
}) {
  const dispatch = useDispatch();
  const [requirements, setRequirements] = useState([]);

  const [paymentOptions, setPaymentOptions] = useState({});

  const handlePaymentOptions = (e) => {
    handleMethodChange({
      target: {
        name: "type",
        value: e,
      },
    });
    setPaymentOptions(requirements.filter((req) => req.type === e)[0]);
  };

  useEffect(() => {
    setPaymentOptions({});
    setRequirements([]);
    method.currency !== "" &&
      dispatch(getRequirements(method.currency)).then((res) => {
        let data =
          res?.payload?.data.length &&
          res.payload.data.filter((el) => el.type !== "email");
        setRequirements(data);
        data.length && handlePaymentOptions(data[0].type);
        data.length && setPaymentOptions(data[0]);
      });
  }, [method.currency]);

  let bankDetails = {
    legalType: null,
    accountNumber: null,
    sortCode: null,
    abartn: null,
    accountType: null,
    bankgiroNumber: null,
    ifscCode: null,
    bsbCode: null,
    institutionNumber: null,
    transitNumber: null,
    phoneNumber: null,
    bankCode: null,
    russiaRegion: null,
    routingNumber: null,
    branchCode: null,
    cpf: null,
    cardNumber: null,
    idType: null,
    idNumber: null,
    idCountryIso3: null,
    idValidFrom: null,
    idValidTo: null,
    clabe: null,
    swiftCode: null,
    dateOfBirth: null,
    clearingNumber: null,
    bankName: null,
    branchName: null,
    businessNumber: null,
    province: null,
    city: null,
    rut: null,
    token: null,
    cnpj: null,
    payinReference: null,
    pspReference: null,
    orderId: null,
    idDocumentType: null,
    idDocumentNumber: null,
    targetProfile: null,
    taxId: null,
    iban: null,
    bic: null,
    IBAN: null,
    BIC: null,
    interacAccount: null,
    address: {
      country: null,
      countryCode: null,
      firstLine: null,
      postCode: null,
      city: null,
      state: null,
    },
  };
  const handleBankChange = (e) => {
    const { name, value } = e.target;

    if (name === "type") {
      return setMethod({ ...method, type: value });
    }
    if (name.includes("address")) {
      let newName = name.split(".");
      return setMethod({
        ...method,
        bankDetails: {
          ...method.bankDetails,
          address: {
            ...method.bankDetails.address,
            [newName[1]]: value,
          },
        },
      });
    }
    setMethod({
      ...method,
      bankDetails: {
        ...method.bankDetails,
        [name]: value,
      },
    });
  };
  // console.log("requirements here", requirements);
  return (
    <div className={`content ${toggle ? "show" : ""}`}>
      <div className="inputs">
        <div className="input">
          <h4>Account Holder Name</h4>
          <input
            type="text"
            placeholder="Enter your full name"
            name="accountHolderName"
            value={method.accountHolderName}
            onChange={(e) => handleMethodChange(e)}
          />
        </div>
        <div className="input">
          <h4>Account Currency</h4>
          <select
            onChange={(e) => {
              setMethod({ ...method, currency: e.target.value, bankDetails });
            }}
            value={method.currency}
          >
            {Object.keys(currencylist).map((keyname, i) => (
              <option value={keyname} key={i}>
                {keyname} - {currencylist[keyname]}
              </option>
            ))}
          </select>
        </div>
        {requirements.length > 0 && (
          <>
            <div className="input">
              <h4>Payment Options</h4>
              <select
                onChange={(e) => handlePaymentOptions(e.target.value)}
                value={paymentOptions?.type}
              >
                <option value={""}> Please select</option>
                {requirements.map((req, i) => (
                  <option value={req.type} key={i}>
                    {req.type} - {req.title}
                  </option>
                ))}
              </select>
            </div>
            <div>
              {paymentOptions?.fields &&
                paymentOptions.fields.length > 0 &&
                paymentOptions.fields.map((field, i) => (
                  <div className="input" key={i}>
                    <h4>{field.name}</h4>
                    {(field.group[0].type === "select" ||
                      field.group[0].type === "radio") &&
                    field.group[0].valuesAllowed !== null ? (
                      <select
                        required={field.group[0].required}
                        onChange={(e) => handleBankChange(e)}
                        value={
                          method?.bankDetails &&
                          method.bankDetails[field.group[0].key] &&
                          method.bankDetails[field.group[0].key]
                        }
                        name={field.group[0].key}
                      >
                        <option value={""}>Please select</option>
                        {field.group[0].valuesAllowed?.length > 0 &&
                          field.group[0].valuesAllowed?.map((op, i) => (
                            <option value={op.key}>{op.name}</option>
                          ))}
                      </select>
                    ) : (
                      <input
                        type={field.group[0].type}
                        placeholder={`Enter ${field.name}`}
                        name={field.group[0].key}
                        onChange={(e) => handleBankChange(e)}
                        value={
                          method?.bankDetails &&
                          method.bankDetails[field.group[0].key] &&
                          method.bankDetails[field.group[0].key]
                        }
                        required={field.group[0].required}
                      />
                    )}
                  </div>
                ))}
              <button className="btn__primary" onClick={() => onSubmit()}>
                Submit
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default BankTransfer;

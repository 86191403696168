import { useState } from "react";
import calendarIco from "../../../assets/AdminApp/svgs/calendar.svg";
import selectArrowDown from "../../../assets/AdminApp/svgs/select_arrow_down.svg";
import { Calendar } from "react-multi-date-picker";
import "./dateTimeInput.styles.scss";
import closeBtn from "/src/assets/AdminApp/svgs/close.svg";

/**
 * DateTimeInput Component
 *
 * @component
 * @param {Object} props - Component props
 * @param {Object} props.dateRef - Reference to the date input element
 * @param {Object} props.timeRef - Reference to the time input element
 * @example
 * const dateRef = useRef();
 * const timeRef = useRef();
 * return (
 *   <DateTimeInput dateRef={dateRef} timeRef={timeRef} />
 * )
 */
const DateTimeInput = ({
  dateRef,
  timeRef,
  disabled,
  showPicker,
  setShowPicker,
  children,
  value,
  onChange,
}) => {
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const day = today.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  return (
    <div className={"booking_input date_booking_input"}>
      {/* {showPicker && (
        <AvailabilityDateTimePicker setShowPicker={setShowPicker} />
      )} */}
      <span className="input_name">Start Date/Time</span>
      <div className="multiple">
        <div className="input">
          <input
            type="date"
            ref={dateRef}
            disabled={disabled}
            min={getTodayDate()}
            value={value}
            onChange={onChange}
          />
          <img
            src={calendarIco}
            alt=""
            onClick={() => {
              !disabled && dateRef.current.showPicker();
            }}
          />
        </div>
        <div className="input">
          {children}
          {/* <input type="time" ref={timeRef} disabled={disabled} />
          <img
            src={selectArrowDown}
            alt=""
            onClick={() => {
              // !disabled && timeRef.current.showPicker();
              setShowPicker && setShowPicker(true);
            }}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default DateTimeInput;

const AvailabilityDateTimePicker = ({ onChange, setShowPicker }) => {
  return (
    <div className="picker_overlay">
      <div className="picker_container">
        <div className="booking_switch">
          <span className={"selected"}>Weekly</span>
          <span>FLEXIBLE</span>
        </div>
        <div className="picker_inner_container">
          <img
            className="close"
            src={closeBtn}
            alt=""
            role="button"
            onClick={() => {
              setShowPicker && setShowPicker(false);
            }}
          />
          {/* <Calendar
            className="admin_custom__calendar"
            // shadow={false}
            weekStartDayIndex={1}
            minDate={new Date()}
            onChange={onChange}
          /> */}
          <div className="times_picker">
            <div className="times">
              {Array.from(Array(10)).map((el) => (
                <div className="time">
                  <span>15:30</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPublicSettings,
  handleUserChange,
} from "./ReduxToolkit/Slices/userSlice";
import moment from "moment-timezone";

import zone from "./assets/TeacherApp/assets/svgs/zone.svg";
import { setAdminState } from "./ReduxToolkit/Slices/adminSlice";
export function updateCloudinaryUrl(url) {
  // Check if the URL is a Cloudinary URL and starts with "http"
  if (url && url.includes("cloudinary") && url.startsWith("http:")) {
    // Update "http" to "https"
    return url.replace(/^http:/i, "https:");
  }
  // Return the original URL if it doesn't meet the criteria
  return url;
}
export const handlePurchase = (transactionId, items, user, string) => {
  const email = user?.email || "guest@example.com"; // Replace with actual email logic

  const script = document.createElement("script");
  script.type = "text/javascript";
  script.innerHTML = `
    window.criteo_q = window.criteo_q || [];
    var deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
    window.criteo_q.push(
      { event: "setAccount", account: 112050 },
      { event: "setEmail", email: "${email}" },
      { event: "setSiteType", type: deviceType },
      { event: "trackTransaction", id: "${transactionId}", currency: "USD", item: ${JSON.stringify(
    items
  )} }
    );
  `;
  document.head.appendChild(script);
};
export function useFirestoreQuery(query) {
  const [docs, setDocs] = useState([]);

  // Store current query in ref
  const queryRef = useRef(query);

  // Compare current query with the previous one
  useEffect(() => {
    // Use Firestore built-in 'isEqual' method
    // to compare queries
    if (!queryRef?.curent?.isEqual(query)) {
      queryRef.current = query;
    }
  });

  // Re-run data listener only if query has changed
  useEffect(() => {
    if (!queryRef.current) {
      return null;
    }

    // Subscribe to query with onSnapshot
    const unsubscribe = queryRef.current.onSnapshot((querySnapshot) => {
      // Get all documents from collection - with IDs
      const data = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      // Update state
      setDocs(data);
    });

    // Detach listener
    return unsubscribe;
  }, [queryRef]);

  return docs;
}

export const TimezoneSelector = () => {
  const dispatch = useDispatch();
  const { timezone } = useSelector((state) => state.user);
  const allTimezones = moment.tz.names();

  const handleTimezoneChange = (value) => {
    dispatch(
      handleUserChange({
        name: "timezone",
        value,
      })
    );
  };
  return (
    <select
      className="timezone-select"
      value={timezone}
      style={{ background: `url(${zone})` }}
      onChange={(e) => handleTimezoneChange(e.target.value)}
    >
      {allTimezones &&
        allTimezones.map((tz, i) => (
          <option className="timezone__option" value={tz} key={i}>
            {tz}
          </option>
        ))}
    </select>
  );
};

export const StudentFeeCalculator = (price) => {
  return price;
};
export const TeacherFeeCalculator = (price) => {
  const { publicSetting } = useSelector((state) => state.admin);

  if (!publicSetting.studentFee) {
    const dispatch = useDispatch();
    dispatch(getPublicSettings()).then((res) => {
      res?.payload?.adminSetting &&
        dispatch(
          setAdminState({
            name: "publicSetting",
            value: res?.payload?.adminSetting,
          })
        );
      const priceAfterStudentFee =
        (price * res?.payload?.adminSetting.teacherCommission) / 100;
      return priceAfterStudentFee;
    });
  } else {
    const priceAfterStudentFee =
      (price * publicSetting.teacherCommission) / 100;
    return priceAfterStudentFee;
  }
};
export const LessonPriceCalculator = (price) => {
  const { publicSetting } = useSelector((state) => state.admin);
  if (!publicSetting.studentFee) {
    const dispatch = useDispatch();
    dispatch(getPublicSettings()).then((res) => {
      res?.payload?.adminSetting &&
        dispatch(
          setAdminState({
            name: "publicSetting",
            value: res?.payload?.adminSetting,
          })
        );
      const priceAfterStudentFee =
        price + (price * res?.payload?.adminSetting?.studentFee) / 100;
      return priceAfterStudentFee;
    });
  } else {
    const priceAfterStudentFee =
      price + (price * publicSetting.studentFee) / 100;
    return priceAfterStudentFee;
  }
};

export function useAuthState(auth) {
  const [initializing, setInitializing] = useState(true);
  const [user, setUser] = useState(() => auth.currentUser);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(false);
      }
      if (initializing) {
        setInitializing(false);
      }
    });

    // Cleanup subscription
    return unsubscribe;
  }, [auth, initializing]);

  return { user, initializing };
}
export const passwordRegex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
export function validatePassword(password) {
  const passwordRegex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
  return passwordRegex.test(password);
}

export function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
    }
  };

  return [storedValue, setValue];
}

export function getVideoIdFromUrl(url) {
  if (!url) {
    return;
  }
  if (url.includes("youtu.be")) {
    return url.split("/").pop().split("?")[0]; // Remove any query parameters
  } else if (url.includes("youtube.com")) {
    const params = new URL(url).searchParams;
    return params.get("v");
  }
  return "";
}
export function isYoutubeLink(link) {
  // YouTube video ID regex pattern
  const youtubeVideoIdPattern = /^[a-zA-Z0-9_-]{11}$/;

  // YouTube link regex pattern
  const youtubeLinkPattern =
    /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/;

  // Check if link matches YouTube link pattern
  if (youtubeLinkPattern.test(link)) {
    // Extract video ID from link
    const videoId = link.match(youtubeVideoIdPattern);

    // If video ID is found and matches the pattern, link is a YouTube video
    if (videoId && youtubeVideoIdPattern.test(videoId[0])) {
      return true;
    }
  }

  // Link is not a YouTube video
  return false;
}

export function useMedia(queries, values, defaultValue) {
  // Array containing a media query list for each query
  const mediaQueryLists = queries.map((q) => window.matchMedia(q));

  // Function that gets value based on matching media query
  const getValue = useCallback(() => {
    // Get index of first media query that matches
    const index = mediaQueryLists.findIndex((mql) => mql.matches);
    // Return related value or defaultValue if none
    return typeof values[index] !== "undefined" ? values[index] : defaultValue;
  }, [mediaQueryLists, values, defaultValue]);

  // State and setter for matched value
  const [value, setValue] = useState(getValue);

  useEffect(() => {
    // Event listener callback
    // Note: By defining getValue outside of useEffect we ensure that it has ...
    // ... current values of hook args (as this hook callback is created once on mount).
    const handler = () => setValue(getValue);
    // Set a listener for each media query with above handler as callback.
    mediaQueryLists.forEach((mql) => mql.addListener(handler));
    // Remove listeners on cleanup
    return () => mediaQueryLists.forEach((mql) => mql.removeListener(handler));
  }, [getValue, mediaQueryLists]);

  return value;
}

export const CheckLoggedIn = () => {
  let storageUser = localStorage.getItem("user");
  let loggedIn = localStorage.getItem("loggedIn");

  if (!storageUser || !loggedIn) {
    return false;
  } else return true;
};
export function useDarkMode() {
  // See if user has set a browser or OS preference for dark mode.
  const prefersDarkMode = useMedia(
    ["(prefers-color-scheme: dark)"],
    [true],
    false
  );

  // Use our useLocalStorage hook to persist state through a page refresh
  const [enabled, setEnabled] = useLocalStorage(
    "dark-mode-enabled",
    prefersDarkMode
  );

  // Fire off effect that add/removes dark mode class
  useEffect(
    () => {
      const className = "dark";
      const element = window.document.body;
      if (enabled) {
        element.classList.add(className);
      } else {
        element.classList.remove(className);
      }
    },
    [enabled] // Only re-call effect when value changes
  );

  // Return enabled state and setter
  return [enabled, setEnabled];
}

export const formatTime = (seconds) => {
  if (seconds === 0) {
    return;
  }
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);
  const remainingSeconds = seconds - hours * 3600 - minutes * 60;

  const formattedHours = hours > 0 ? hours + "h " : "";
  const formattedMinutes = minutes > 0 ? minutes + "mins " : "";
  const formattedSeconds = remainingSeconds > 0 ? remainingSeconds + "sec" : "";

  return formattedHours + formattedMinutes + formattedSeconds;
};

export const validateYoutubeUrl = (url) => {
  let p =
    /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  if (url.match(p)) {
    return url.match(p)[1];
  }
  return false;
};
export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

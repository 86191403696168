import React, { useLayoutEffect, useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import "./GlobalStyles/app.styles.scss";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  HashRouter,
} from "react-router-dom";
import defaultImage from "./assets/AdminApp/images/defautImage-resized.jpg";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/database";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";

import CookiePopup from "./Components/CookiePopup/CookiePopup";
import { ToastContainer } from "react-toastify";
import AppContainer from "./AppContainer";
import metaTags from "./metatags";
import { BetaBar } from "./AdminApp/Components/DiscountBar/DiscountBar";

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

const firebaseConfig = {
  apiKey: import.meta.env.VITE_Firebase_apiKey,
  authDomain: import.meta.env.VITE_Firebase_authDomain,
  projectId: import.meta.env.VITE_Firebase_projectId,
  storageBucket: import.meta.env.VITE_Firebase_storageBucket,
  messagingSenderId: import.meta.env.VITE_Firebase_messagingSenderId,
  appId: import.meta.env.VITE_Firebase_appId,
  measurementId: import.meta.env.VITE_Firebase_measurementId,
};

firebase.initializeApp(firebaseConfig);

function App() {
  const [signedIn, setSignedIn] = useState(false);
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      // The app is fully initialized and the user is signed in.
      // You can now use Firebase services.
      !signedIn && setSignedIn(true);
    } else {
      // The app is fully initialized, but the user is not signed in.
      signedIn && setSignedIn(false);
    }
  });

  const showErrorOverlay = (err) => {
    const ErrorOverlay = customElements.get("vite-error-overlay");
    if (!ErrorOverlay) {
      return;
    }
    if (err?.message === "Script error.") {
      return;
    } else if (err?.message != "Script error.") {
      const overlay = new ErrorOverlay(err ? err : err?.toString());
      document?.body?.appendChild(overlay);
    }
  };

  window.addEventListener("error", showErrorOverlay);
  window.addEventListener("unhandledrejection", ({ reason }) =>
    showErrorOverlay(reason)
  );

  useEffect(() => {
    const defaultImageSrc = defaultImage; // Replace with your default image path
    // Function to handle image loading errors
    const handleImageError = (event) => {
      event.target.src = defaultImageSrc;
    };
    // Function to check and set the default image if src is empty or invalid
    const checkAndSetDefaultImage = (image) => {
      if (!image.src || image.src === window.location.href) {
        image.src = defaultImageSrc;
      }
    };
    // Function to add error handling to an image
    const addErrorHandling = (image) => {
      image.addEventListener("error", handleImageError);
      checkAndSetDefaultImage(image);
    };
    // Select all existing images in the document
    const images = document.querySelectorAll("img");
    images.forEach(addErrorHandling);
    // Use MutationObserver to watch for new images added to the DOM
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "childList") {
          mutation.addedNodes.forEach((node) => {
            if (node.tagName === "IMG") {
              addErrorHandling(node);
            } else if (node.querySelectorAll) {
              node.querySelectorAll("img").forEach(addErrorHandling);
            }
          });
        }
      });
    });
    // Start observing the document for changes
    observer.observe(document.body, { childList: true, subtree: true });
    // Cleanup function to disconnect the observer and remove event listeners
    return () => {
      observer.disconnect();
      images.forEach((image) => {
        image.removeEventListener("error", handleImageError);
      });
    };
  }, []);

  return (
    <>
      <HashRouter>
        <Wrapper>
          <Helmet>{metaTags}</Helmet>
          <div className="">
            <ToastContainer
              position="top-center"
              autoClose={2000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick={false}
              rtl={false}
              pauseOnFocusLoss
              draggable={false}
              pauseOnHover={false}
              theme="light"
            />
            <CookiePopup />

            {/* <BetaBar /> */}
            <AppContainer signedIn={signedIn} />
          </div>
        </Wrapper>
      </HashRouter>
    </>
  );
}

export default App;

import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { getFilteredBookings } from "../../../ReduxToolkit/Slices/adminSlice";

import BookingTable from "./BookingTable";
import BookingPage from "../BookingPage/BookingPage";

import "./Booking.styles.scss";
import Button from "../../../Components/Button/Button";

export default function Booking() {
  const dispatch = useDispatch();
  const [bookings, setBookings] = useState([]);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [fetched, setFetched] = useState(false);
  const setBooking = () => {};
  const [filters, setFilters] = useState({
    search: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    const handler = setTimeout(() => {
      dispatch(getFilteredBookings({ ...filters, page: currentPage })).then(
        (res) => {
          if (res.type.includes("fulfilled")) {
            setBookings(res.payload.bookings);
            setPage(res?.payload?.numOfPages);
            setFetched(true);
          }
        }
      );
    }, 100);

    return () => {
      clearTimeout(handler);
    };
  }, [dispatch, filters, currentPage]);

  return (
    <>
      <div className="custom_modal_btn">
        <Button
          variant="secondary"
          onClick={openModal}
          className="custom_sec_btn"
        >
          Custom Booking
        </Button>
      </div>
      <div className="booking__list" style={{ marginTop: "10px" }}>
        {/* <div className="booking"></div> */}

        <BookingPage
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          openModal={openModal}
          setIsModalOpen={setIsModalOpen}
        />
        <div className="booking__table">
          <BookingTable
            bookings={bookings}
            page={page}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setBooking={setBooking}
            filters={filters}
            setFilters={setFilters}
          />
        </div>
      </div>
    </>
  );
}

import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import "./ViewStudent.styles.scss";
import { StudentCompletedLessons } from "../../Components/CompletedLessons/CompletedLessons";
import StudentFullInfo from "../../Components/StudentFullInfo/StudentFullInfo";
import StudentChart from "../../Components/StudentChart/StudentChart";
import LessonTable from "../../Components/LessonTable/LessonTable";
import AssignModal from "../../Components/AssignModal/AssignModal";
import MakePayment from "../../Components/MakePayment/MakePayment";
import { useEffect } from "react";
import {
  getLessonList,
  getStudentBookings,
  getStudentById,
  getStudentDashboard,
} from "../../../ReduxToolkit/Slices/studentSlice";
import { useState } from "react";
import TeacherPayments from "../TeacherPayments/TeacherPayments";

export default function ViewStudent() {
  const dispatch = useDispatch();
  const params = useParams();

  const { id } = params;
  const [student, setStudent] = useState(null);
  const [lessons, setLessons] = useState([]);
  const [dashboard, setDashboard] = useState({
    acceptedLesson: 0,
    cancelledLessons: 0,
    completedLessons: 0,
    pendingLessons: 0,
    rejectedLessons: 0,
    totalHours: 0,
    totalLessons: 0,
  });

  useEffect(() => {
    dispatch(getStudentDashboard(id)).then((res) => setDashboard(res.payload));
    dispatch(getStudentById(id)).then((res) => {
      setStudent(res.payload.data);
    });

    let filters = `studentId=${id}`;

    dispatch(getLessonList(filters)).then((res) => {
      let list = res.payload.lessons;
      setLessons(res.payload.lessons);
    });
  }, []);

  return (
    <div className="view__student">
      <div className="dash__row">
        <StudentFullInfo student={student} />
        <StudentChart dashboard={dashboard} student={student} />
      </div>
      <div className="dash__row">
        <LessonTable lessons={lessons} />
        <StudentCompletedLessons prevBookings={lessons} title="Lessons" />
      </div>
      <div className="dash__row">
        {/* <AssignModal /> */}
        {/* <MakePayment /> */}
      </div>
      <TeacherPayments />
    </div>
  );
}

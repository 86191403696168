import React, { useEffect, useState } from "react";
import Pagination from "../../../Components/Pagination/Pagination";
import { Link } from "react-router-dom";
import moment from "moment";
import notfound from "../../../assets/SharedApp/images/default.webp";
import delIco from "../../../assets/AdminApp/svgs/deleteico.svg";
import right from "../../../assets/SharedApp/svgs/right.svg";
import { useDispatch } from "react-redux";
import {
  deleteAdminBookingById,
  getAdminBookings,
} from "../../../ReduxToolkit/Slices/adminSlice";
import Button from "../../../Components/Button/Button";

import { toast } from "react-toastify";
import BookingPage from "../BookingPage/BookingPage";

const AdminBooking = () => {
  const dispatch = useDispatch();
  const [bookings, setBookings] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [filters, setFilters] = useState({ limit: "100" });
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  let timeoutId;

  useEffect(() => {
    // Clear the previous timeout if filters or refresh changes
    clearTimeout(timeoutId);

    // Set a new timeout
    timeoutId = setTimeout(() => {
      dispatch(getAdminBookings(filters)).then((res) => {
        if (res.type.includes("fulfilled")) {
          setBookings(res?.payload);
        }
      });
    }, 500); // Adjust the delay as needed (e.g., 500ms)

    // Cleanup function to clear the timeout if the component unmounts or filters/refresh changes
    return () => {
      clearTimeout(timeoutId);
    };
  }, [filters, refresh]);

  const handleDeleteAdminBooking = (id) => {
    const confirm = window.confirm(
      "Are you sure you want to delete this booking ?"
    );
    if (confirm) {
      dispatch(deleteAdminBookingById(id)).then((res) => {
        if (res.type.includes("fulfilled")) {
          toast.success("Booking deleted successfully");
          setRefresh(!refresh);
        }
      });
    }
  };

  return (
    <div className="admin_booking">
      <AdminBookingTable
        bookings={bookings}
        onDelete={handleDeleteAdminBooking}
        setFilters={setFilters}
        filters={filters}
        page={page}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
    </div>
  );
};
const AdminBookingTable = ({
  bookings,
  page,
  setCurrentPage,
  currentPage,
  //   setBooking,
  filters,
  setFilters,
  onDelete,
}) => {
  const newDate = (date) => {
    return moment(date).format("DD/MM/YYYY");
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  return (
    <div className="manage__booking__page">
      <div className="custom_modal_btn">
        <Button
          variant="secondary"
          onClick={openModal}
          className="custom_sec_btn"
        >
          Custom Booking
        </Button>
      </div>
      <BookingPage
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        openModal={openModal}
        setIsModalOpen={setIsModalOpen}
      />
      <div className="header" style={{ marginBottom: "10px" }}>
        <div className="header__text">
          <span>Booking Lists</span>
          {/* <p>More than 400+ members</p> */}
        </div>
        <div className="teachers__actions">
          <div className="search__teachers">
            <input
              type="text"
              placeholder="Search students"
              value={filters.search}
              onChange={(e) =>
                setFilters({ ...filters, search: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className="students__table">
        <table>
          <tr>
            <th>STUDENTS</th>
            <th>LESSONS</th>
            <th>CREATED AT</th>
            <th>TEACHER</th>
            <th>AMOUNT</th>
            <th>STATUS</th>
            <th></th>
          </tr>
          {bookings &&
            bookings.length > 0 &&
            bookings.map((booking) => (
              <tr>
                <td>
                  <div className="student__cell">
                    <img
                      src={
                        booking.student.user?.profileImage?.url
                          ? booking.student.user?.profileImage?.url
                          : notfound
                      }
                      alt=""
                    />
                    <div className="name">
                      <span>
                        {booking?.student?.user?.name}{" "}
                        {booking?.student?.user?.lastName}
                      </span>
                      <p>{booking?.student?.user?.address?.street}</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="manag e__std__lesson">
                    <div className="lesson__date">
                      <span>
                        {booking?.lesson?.isGroup ? "Group" : "Private"} Lesson
                      </span>
                      <p>{booking?.lesson?.title}</p>
                    </div>
                  </div>
                </td>
                <td>
                  <span>{newDate(booking.createdAt)} </span>
                  <p>Date</p>
                </td>
                <td>
                  <span>
                    {booking?.lesson?.teacher?.user?.name}
                    {booking?.lesson?.teacher?.user?.lastName}
                  </span>
                  {/* change bellow */}
                  <p>Virtual</p>
                </td>
                <td>
                  <div className="student__cell">
                    <div className="name">
                      <span>{booking.lesson.hourlyRate}</span>
                      <p>per hour</p>
                    </div>
                  </div>
                </td>
                <td>
                  <button className={booking.status.toLowerCase()}>
                    {booking.status.toLowerCase() === "pendingpayment"
                      ? "Pending payment"
                      : booking.status.toLowerCase()}
                  </button>
                </td>
                <td>
                  <Link to={`${booking.id}`} style={{ marginRight: "10px" }}>
                    <img src={right} alt="" />
                  </Link>
                  <img
                    src={delIco}
                    alt=""
                    onClick={() => onDelete(booking?.id)}
                    style={{ cursor: "pointer" }}
                  />
                </td>
              </tr>
            ))}
        </table>
      </div>
      <Pagination
        page={page}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
    </div>
  );
};
export default AdminBooking;

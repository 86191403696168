import React, { useEffect, useLayoutEffect } from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import bell from "../../../assets/TeacherApp/assets/svgs/bell.svg";
import group1742 from "../../../assets/SVG/group1742.svg";
import teacherImg from "../../../assets/TeacherApp/assets/images/teacher.webp";
import search from "../../../assets/TeacherApp/assets/svgs/search.svg";
import rectangle367 from "../../../assets/SVG/rectangle367.svg";
import stockholmIconsCom from "../../../assets/SVG/stockholmIconsCom.svg";
import icon from "../../../assets/SVG/icon.svg";
import sending from "../../../assets/SVG/sending.svg";
import mirror from "../../../assets/SVG/mirror.svg";
import creditCard from "../../../assets/SVG/creditCard.svg";
import settings from "../../../assets/SVG/settings.svg";
import groupChat from "../../../assets/SVG/groupChat.svg";
import categories from "../../../assets/AdminApp/svgs/panel/categories.svg";
import content from "../../../assets/AdminApp/svgs/panel/content.svg";
import faq from "../../../assets/AdminApp/svgs/panel/faq.svg";
import subjects from "../../../assets/AdminApp/svgs/panel/subjects.svg";
import User from "../../../assets/AdminApp/svgs/panel/User.svg";
import users from "../../../assets/AdminApp/svgs/panel/users.svg";
import notification from "../../../assets/AdminApp/svgs/panel/notification.svg";
import setting from "../../../assets/AdminApp/svgs/panel/settings.svg";
import { useDispatch, useSelector } from "react-redux";
import num30022 from "../../../assets/SVG/num30022.png";
import DashboardDropDown from "../../../Components/SharedDashboardLayout/Menu/DashboardDropDown";
import { getStudentAdminBookings } from "../../../ReduxToolkit/Slices/studentSlice";

export default function DashboardPanel({ hasLogo, mobile }) {
  const [bookings, setBookings] = useState();
  const { user } = useSelector((state) => state.user);
  let params = useLocation();
  const dispatch = useDispatch();
  let newparms = params.length ? params.pathname.split("/")[2] : "";
  const [showDropDown, setShowDropDown] = useState(false);
  const { showCourses } = useSelector((state) => state.user);

  const itemsInitialState = [
    {
      title: "Overview",
      icon: mirror,
      selected: true,
      link: "myclasses",
    },
    {
      title: "Calendar",
      link: "calendar",
      icon: sending,
    },
    {
      link: "subjects/1",
      title: "My Listings",
      icon: sending,
    },
    {
      title: "HomeWork",
      link: "homework",
      icon: sending,
      options: [
        {
          name: "Create Homework",
          link: "/homework",
        },
        {
          name: "View homeworks",
          link: "/homework/view",
        },
      ],
    },
    {
      title: "Notes",
      link: "notes",
      icon: sending,
      options: [
        {
          name: "Add a note",
          link: "/notes",
        },
        {
          name: "View all notes",
          link: "/notes/all",
        },
      ],
    },
    {
      title: "Course Coupons",
      link: "coupon",
      icon: settings,
    },
    {
      title: "My Requests",
      icon: stockholmIconsCom,
      link: "myrequests",
    },
    {
      title: "My Earnings",
      link: "myearnings",
      icon: creditCard,
    },
    {
      title: "My Messages",
      link: "mymessages",
      icon: groupChat,
    },
    {
      title: "Payment Method",
      link: "payment",
      icon: settings,
    },
    {
      title: "Request Recommendation",
      link: "recommendation",
      icon: icon,
      options: [
        {
          name: "My Recommendations",
          link: "/recommendation/my-recommendations",
        },
      ],
    },

    {
      link: "courses/create",
      title: "Online Courses",
      icon: settings,
      options: [
        {
          name: "Create Course",
          link: "/courses/create",
        },
        {
          name: "My Courses",
          link: "/courses/mycourses",
        },
      ],
    },

    {
      title: "FAQ",
      link: "faq",
      icon: icon,
    },
    {
      title: "Notifications",
      link: "notifications",
      icon: notification,
    },
    {
      title: "Help & Support",
      link: "https://educifyinc.freshdesk.com",
      icon: settings,
      openNewPage: true,
    },
  ];

  const studentsInitial = [
    {
      title: "My Lessons",
      icon: mirror,
      selected: true,
      link: "myclasses",
    },
    {
      title: "Calendar",
      link: "calendar",
      icon: sending,
    },
    {
      title: "Find Classes",
      icon: mirror,
      selected: true,
      link: "findClasses",
    },
    {
      title: "Notes",
      link: "notes",
      icon: sending,
    },
    {
      title: "HomeWorks",
      link: "homework",
      icon: sending,
    },
    {
      title: "Subscriptions",
      link: "subscriptions",
      icon: icon,
      options: [
        {
          name: "Pending Invoices",
          link: "/admin-booking",
          className: "",
        },
      ],
    },
    {
      title: "My Wallet",
      icon: creditCard,
      link: "mywallet",
    },
    {
      title: "My Messages",
      link: "mymessages",
      icon: groupChat,
    },
    {
      title: "Manage Payment",
      link: "payment",
      icon: settings,
    },
    // {
    //   link: "invite",
    //   title: "Invite Friends",
    //   icon: sending,
    // },
    {
      title: "Promo Codes",
      link: "promo",
      icon: settings,
    },

    {
      title: "FAQ",
      link: "faq",
      icon: icon,
    },
    {
      link: "courses",
      title: "Online Courses",
      icon: settings,
      options: [
        {
          name: "All Courses",
          link: "/courses",
          className: "std_list1",
        },
        {
          name: "My List",
          link: "/courses/mylist",
          className: "std_list2",
        },
        // {
        //   name: "Wishlist",
        //   link: "/courses/wishlist",
        // },
      ],
    },
    {
      title: "Help & Support",
      link: "https://educifyinc.freshdesk.com",
      icon: settings,
      openNewPage: true,
    },
    {
      title: "Notification",
      link: "notifications",
      icon: notification,
    },
  ];
  const AdminInitial = [
    {
      title: "Dashboard",
      icon: mirror,
      selected: true,
      link: "dashboard",
    },
    {
      title: "Analytics",
      icon: User,
      link: "analytics",
    },
    {
      title: "SEO",
      icon: settings,
      link: "seo",
    },
    {
      title: "Teachers",
      icon: User,
      link: "teachers",
    },
    {
      title: "Notifications",
      link: "notification",
      icon: notification,
    },
    {
      title: "Students",
      link: "students",
      icon: users,
    },
    {
      title: "Blocked Users",
      icon: User,
      link: "users",
    },
    {
      title: "Deleted Accounts",
      icon: User,
      link: "deleted accounts",
    },
    {
      title: "Bookings",
      link: "booking",
      icon: settings,
    },
    {
      title: "Blogs",
      link: "blogs",
      icon: settings,
      options: [
        {
          name: "View Blogs",
          link: "/blogs",
        },
        {
          name: "Add blog",
          link: "/blogs/create",
        },
      ],
    },
    {
      title: "Settings",
      link: "settings",
      icon: setting,
      options: [
        {
          name: "Admin List",
          link: "/settings",
        },
        {
          name: "Basic Settings",
          link: "/settings/basic",
        },
        // {
        //   name: "Installation Settings",
        //   link: "/settings/installation",
        // },
        // {
        //   name: "Mail Templates",
        //   link: "/settings/mail",
        // },
        // {
        //   name: "SMS Templates",
        //   link: "/settings/sms",
        // },
      ],
    },
    {
      title: "Payments",
      link: "payments",
      icon: creditCard,
    },
    {
      link: "courses",
      title: "Online Courses",
      icon: settings,
      options: [
        {
          name: "Categories",
          link: "/courses/categories",
        },
        {
          name: "Subjects",
          link: "/courses/subjects",
        },
      ],
    },
    {
      title: "Categories",
      link: "categories",
      icon: categories,
    },
    {
      title: "Subjects",
      link: "subjects",
      icon: subjects,
    },
    {
      title: "Promo Code",
      link: "promo",
      icon: settings,
    },
    {
      link: "packages",
      title: "Manage Packages",
      icon: sending,
    },
    // {
    //   title: "Content Pages",
    //   link: "content",
    //   icon: content,
    // },
    {
      title: "FAQ",
      link: "faq",
      icon: faq,
    },
    // {
    //   title: "Push Notification",
    //   link: "notifications",
    //   icon: notification,
    // },
  ];
  const BlogAdminInitial = [
    {
      title: "SEO",
      icon: settings,
      link: "seo",
    },
    {
      title: "Teachers",
      icon: User,
      link: "teachers",
    },
    {
      title: "Notifications",
      link: "notification",
      icon: notification,
    },
    {
      title: "Students",
      link: "students",
      icon: users,
    },
    {
      title: "Blogs",
      link: "blogs",
      icon: settings,
      options: [
        {
          name: "View Blogs",
          link: "/blogs",
        },
        {
          name: "Add blog",
          link: "/blogs/create",
        },
      ],
    },
    {
      link: "courses",
      title: "Online Courses",
      icon: settings,
      options: [
        {
          name: "Categories",
          link: "/courses/categories",
        },
        {
          name: "Subjects",
          link: "/courses/subjects",
        },
      ],
    },
    {
      title: "Categories",
      link: "categories",
      icon: categories,
    },
    {
      title: "Subjects",
      link: "subjects",
      icon: subjects,
    },

    {
      title: "FAQ",
      link: "faq",
      icon: faq,
    },
  ];
  const [items, setItems] = useState(studentsInitial);
  const [loaded, setLoaded] = useState(false);
  let location = params.pathname.slice(1, params.pathname.length).split("/")[0];
  useLayoutEffect(() => {
    if (location === "admin") {
      setItems(AdminInitial);
      setLoaded(true);
    } else if (location === "students") {
      setItems(studentsInitial);
      setLoaded(true);
    } else if (location === "teachers") {
      setItems(itemsInitialState);
      setLoaded(true);
    } else if (location === "blogadmin") {
      setItems(BlogAdminInitial);
      setLoaded(true);
    }
  }, [params]);

  useEffect(() => {
    if (user.role === "STUDENT") {
      dispatch(getStudentAdminBookings()).then((res) => {
        if (res.type.includes("fulfilled")) {
          setBookings(res.payload);
        }
      });
    }
  }, []);

  const title = items.filter((element) => {
    if (newparms.length > 0) {
      return element.link === newparms;
    } else return [];
  });
  let lastParam = params.pathname.split("/");
  lastParam = lastParam[lastParam.length - 1];
  let goodParms = params.pathname.split("/")[2];
  const userType = params.pathname.split("/")[1];

  useLayoutEffect(() => {
    if (location === "admin") {
      setItems(AdminInitial);
      setLoaded(true);
    } else if (location === "students") {
      if (!showCourses) {
        setItems(
          studentsInitial.filter(
            (el) => !el.title.toLowerCase().includes("course")
          )
        );
      } else {
        setItems(studentsInitial);
      }
      setLoaded(true);
    } else if (location === "teachers") {
      if (showCourses) {
        setItems(itemsInitialState);
      } else {
        setItems(
          itemsInitialState.filter(
            (el) => !el.title.toLowerCase().includes("course")
          )
        );
      }
      setLoaded(true);
    } else if (location === "blogadmin") {
      if (showCourses) {
        setItems(BlogAdminInitial);
      } else {
        setItems(
          BlogAdminInitial.filter(
            (el) => !el.title.toLowerCase().includes("course")
          )
        );
      }
      setLoaded(true);
    }
  }, [params]);

  return (
    <div className="SharedLayout_Subcontainer SharedLayout_Subcontainer_show">
      {hasLogo && (
        <Link to="/" className="group-1742">
          <img src={group1742} />
        </Link>
      )}
      {mobile && (
        <DashboardDropDown
          setShowDropDown={setShowDropDown}
          showDropDown={showDropDown}
          user={user}
          customClass={"mobileDropDown"}
        />
        // <div className="nav__dash__menu">
        //   <div className="nav__col">
        //     <img src={search} alt="" />
        //   </div>
        //   {/* <div className="nav__col">
        //     <img src={bell} alt="" />
        //   </div> */}
        //   <div className="nav__acount">
        //     <div className="name">
        //       <p>Hello</p>
        //       <span>{user.name}</span>
        //     </div>
        //     <img
        //       src={user?.profileImage?.url ? user.profileImage.url : num30022}
        //       onClick={() => setShowDropDown(!showDropDown)}
        //       alt=""
        //     />
        //   </div>
        // </div>
      )}
      {items &&
        items.map((item, i) => (
          <>
            <Link
              to={`/${location + "/" + item.link}`}
              className={goodParms === item.link ? "item-1" : "flex-container"}
              key={i}
            >
              {item.link.includes(goodParms) && goodParms !== "" && (
                <img className="frame" src={rectangle367} />
              )}
              {item.link === "" && goodParms === "" && (
                <img className="frame" src={rectangle367} />
              )}
              {/* {goodParms === item.link && (
                    <img className="frame" src={rectangle367} />
                  )} */}
              <img className="icons" src={item.icon} />
              <a className="titles">{item.title}</a>
            </Link>

            <div className="settings__list">
              {item.options &&
                item.options.map((el) => (
                  <li
                    className={`${
                      el.link.split("/")[2] == lastParam ? "selected__list" : ""
                    }`}
                  >
                    <Link
                      style={{ whiteSpace: "nowrap" }}
                      to={`/${userType}${el.link}`}
                    >
                      -{el.name}{" "}
                      {bookings?.length > 0 &&
                        el.name.toLowerCase() === "pending invoices" && (
                          <span className="notification_number">
                            {bookings?.length}
                          </span>
                        )}
                    </Link>{" "}
                  </li>
                ))}
            </div>
          </>
        ))}
    </div>
  );
}

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customFetch, { customFetchToken } from "../customFetch/customFetch";

export const initial = {
  title: "",
  description: "",
  price: 0,
  discount: 0,
  discountEndDate: new Date(),
  location: [],
  level: "",
  images: [],
  videos: [],
  learningGoals: [],
  materialsNeeded: [],
  categoryIds: [],
  subjectIds: [],
  isGroup: false,
  maxStudents: 1,
  groupLessonAppointment: [],
  selectedAdminBookingLesson: undefined,
};
const initialState = {
  lessons: [],
  selectedLesson: "",
  studentDistance: 0,
  selectedTeacher: {},
  lessonToSetup: initial,
  selectedLesson: {},
  lessonDate: {
    day: null,
    date: null,
    startTime: null,
    endTime: null,
    timeZone: "string",
  },
  FlexibleDates: [],
  categories: [],
  chosenCategories: [],
  chosenSubjects: [],
  lessonName: "",
  location: [],
  lessonDetails: {},
  bookingType: "WEEKLY",
  subscription: true,
  booking: {},
};

export const getLessonById = createAsyncThunk(
  "getLessonById",
  async (id, thunkApi) => {
    let url = `/lessons/${id}`;
    try {
      const resp = await customFetch.get(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);

export const getCategories = createAsyncThunk(
  "getCategories",
  async (filters, thunkApi) => {
    let url = `/categories`;
    try {
      const resp = await customFetchToken.get(url, { params: filters });
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const getCategoriesWithSubjects = createAsyncThunk(
  "getCategoriesWithSubjects",
  async (params, thunkApi) => {
    let url = `/categories/withSubjects`;
    try {
      const resp = await customFetch.get(url, {
        params,
      });
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const getAllCategoriesWithSubjects = createAsyncThunk(
  "getAllCategoriesWithSubjects",
  async (params, thunkApi) => {
    let url = `/categories/withSubjectsAll`;
    try {
      const resp = await customFetch.get(url, {
        params,
      });
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const getSubjects = createAsyncThunk(
  "getSubjects",
  async (filters, thunkApi) => {
    let url = `/subjects`;
    try {
      const resp = await customFetch.get(url, { params: filters });
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);

export const getSubjectsByCategory = createAsyncThunk(
  "getSubjectsByCategory",
  async (id, thunkApi) => {
    let url = `/subjects/category/${id}`;
    try {
      const resp = await customFetch.get(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);

export const getSubjectByName = createAsyncThunk(
  "getSubjectByName",
  async (name, thunkApi) => {
    let url = `/subjects/${name}`;
    try {
      const resp = await customFetch.get(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const updateSubject = createAsyncThunk(
  "updateSubject",
  async (item, thunkApi) => {
    let url = `/subjects/${item.id}`;
    try {
      const resp = await customFetchToken.patch(url, item.subject);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const createSubject = createAsyncThunk(
  "createSubject",
  async (subject, thunkApi) => {
    let url = `/subjects`;
    try {
      const resp = await customFetchToken.post(url, subject);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const deleteSubject = createAsyncThunk(
  "deleteSubject",
  async (name, thunkApi) => {
    let url = `/subjects/${name}`;
    try {
      const resp = await customFetchToken.delete(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const getCateoryByName = createAsyncThunk(
  "getCateoryByName",
  async (name, thunkApi) => {
    let url = `/categories/${name}`;
    try {
      const resp = await customFetch.get(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const createCategory = createAsyncThunk(
  "createCategory",
  async (category, thunkApi) => {
    let url = `/categories`;
    try {
      const resp = await customFetchToken.post(url, category);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const updateCategory = createAsyncThunk(
  "updateCategory",
  async (item, thunkApi) => {
    let url = `/categories/${item.id}`;
    try {
      const resp = await customFetchToken.patch(url, item.category);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const deleteCategory = createAsyncThunk(
  "deleteCategory",
  async (name, thunkApi) => {
    let url = `/categories/${name}`;
    try {
      const resp = await customFetchToken.delete(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const getAllLessons = createAsyncThunk(
  "getAllLessons",
  async (filters, thunkApi) => {
    let url = `/lessons/list?${filters.filters}`;
    try {
      const resp = await customFetch.get(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updateLesson = createAsyncThunk(
  "updateLesson",
  async (item, thunkApi) => {
    let url = `/lessons/${item.id}`;
    try {
      const resp = await customFetchToken.patch(url, item.lesson);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const deleteLesson = createAsyncThunk(
  "deleteLesson",
  async (id, thunkApi) => {
    let url = `/lessons/${id}`;
    try {
      const resp = await customFetchToken.delete(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const checkPromo = createAsyncThunk(
  "checkPromo",
  async (item, thunkApi) => {
    let url = `/payments/promo`;
    try {
      const resp = await customFetchToken.post(url, item.promo);
      return resp;
    } catch (error) {
      return error.response.data.msg;
    }
  }
);
export const acceptBooking = createAsyncThunk(
  "acceptBooking",
  async (item, thunkApi) => {
    let url = `/bookings/acceptBooking/${item.bookingId}`;
    try {
      const resp = await customFetchToken.put(url, item.booking);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const rescheduleLesson = createAsyncThunk(
  "rescheduleLesson",
  async (item, thunkApi) => {
    let url = `/bookings/changeStudentSchedule/`;
    try {
      const resp = await customFetchToken.patch(url, item);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const calculateTravelFees = createAsyncThunk(
  "calculateTravelFees",
  async (item, thunkApi) => {
    let url = `/bookings/travelFees/`;
    try {
      const resp = await customFetchToken.post(url, item);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const rejectBooking = createAsyncThunk(
  "rejectBooking",
  async (item, thunkApi) => {
    let url = `/bookings/rejectBooking/${item.bookingId}`;
    try {
      const resp = await customFetchToken.put(url, item.booking);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const bookALesson = createAsyncThunk(
  "bookALesson",
  async (item, thunkApi) => {
    let url = `/bookings`;
    try {
      const resp = await customFetchToken.post(url, item);
      // if (resp?.data?.statusCode === 404) {
      //   return "Error booking";
      // }
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const saveBookALesson = createAsyncThunk(
  "saveBookALesson",
  async (item, thunkApi) => {
    let url = `/bookings/save`;
    try {
      const resp = await customFetchToken.post(url, item);
      if (resp?.data?.statusCode === 404) {
        return "Error booking";
      }
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const calculateStudentTotal = createAsyncThunk(
  "calculateStudentTotal",
  async (item, thunkApi) => {
    let url = `/bookings/studentTotal`;
    try {
      const resp = await customFetchToken.post(url, item);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getTeacherBookings = createAsyncThunk(
  "getTeacherBookings",
  async (teacherId, thunkApi) => {
    let url = `/bookings/teacherBookings/${teacherId}`;
    try {
      const resp = await customFetchToken.get(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const getTeacherAcceptedBookings = createAsyncThunk(
  "getTeacherAcceptedBookings",
  async (teacherId, thunkApi) => {
    let url = `/bookings/teacherBookings/${teacherId}/ACCEPTED`;
    try {
      const resp = await customFetchToken.get(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const getTeacherAppointmentsByStatus = createAsyncThunk(
  "getTeacherAppointmentsByStatus",
  async (item, thunkApi) => {
    let url = `/bookings/teacherAppointments/${item.teacherId}/${item.status}`;
    try {
      const resp = await customFetchToken.get(url, {
        params: item.filters,
      });
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const getStudentAppointmentsByStatus = createAsyncThunk(
  "getStudentAppointmentsByStatus",
  async (item, thunkApi) => {
    let url = `/bookings/studentAppointments/${item.studentId}`;
    try {
      const resp = await customFetchToken.get(url, {
        params: item.filters,
      });
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const getStudentReviewCheck = createAsyncThunk(
  "getStudentReviewCheck",
  async (_, thunkApi) => {
    let url = `/bookings/reviewCheck`;
    try {
      const resp = await customFetchToken.get(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const getTeacherPrevBookings = createAsyncThunk(
  "getTeacherPrevBookings",
  async (teacherId, thunkApi) => {
    let url = `/bookings/teacherAppointments/${teacherId}/COMPLETED`;
    try {
      const resp = await customFetchToken.get(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const finishTeacherLesson = createAsyncThunk(
  "finishTeacherLesson",
  async (bookingId, thunkApi) => {
    let url = `/bookings/completeBooking/${bookingId}`;
    try {
      const resp = await customFetch.put(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const getTeacherLessons = createAsyncThunk(
  "getTeacherLessons",
  async (teacherId, thunkApi) => {
    let url = `/teachers/${teacherId}/lessons`;
    try {
      const resp = await customFetch.get(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const getTeacherBookingsByStatus = createAsyncThunk(
  "getTeacherBookingsByStatus",
  async (item, thunkApi) => {
    let url = `/bookings/teacherBookings/${item.teacherId}/${item.status}`;
    try {
      const resp = await customFetchToken.get(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const teacherAddNotes = createAsyncThunk(
  "teacherAddNotes",
  async (item, thunkApi) => {
    let url = `/notes/teachers`;
    try {
      const resp = await customFetchToken.post(url, item);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const teacherDeleteNotes = createAsyncThunk(
  "teacherAddNotes",
  async (id, thunkApi) => {
    let url = `/notes/${id}`;
    try {
      const resp = await customFetchToken.delete(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);

export const getTeacherNotes = createAsyncThunk(
  "getTeacherNotes",
  async (_, thunkApi) => {
    let url = `/notes/teachers`;
    try {
      const resp = await customFetchToken.get(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);

export const checkBookings = createAsyncThunk(
  "checkBookings",
  async (lessonId, thunkApi) => {
    let url = `/bookings/checkbooking/${lessonId}`;
    try {
      const resp = await customFetch.get(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const completeAppointment = createAsyncThunk(
  "completeAppointment",
  async (item, thunkApi) => {
    let url = `/bookings/completeAppointment/${item.id}`;
    try {
      const resp = await customFetchToken.post(url, { status: item.status });
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.error);
    }
  }
);

const lessonSlice = createSlice({
  initialState,
  name: "lessons",
  reducers: {
    clearLessonsData: (state, action) => {
      state.lessons = [];
    },
    selectLesson: (state, action) => {
      state.selectedLesson = action.payload.lesson;
      state.selectedTeacher = action.payload.teacher;
      state.lessonName = action.payload.lessonName;
      state.location = action.payload.location;
      state.lessonDetails = action.payload.lessonDetails;
    },
    selectLessonDate: (state, action) => {
      state.lessonDate = action.payload;
    },
    handleLessonChange: (state, action) => {
      state[action.payload.name] = action.payload.value;
    },
    handleAdminBookingLesson: (state, action) => {
      state.selectedAdminBookingLesson = action.payload;
    },
    handleSetupLessonChange: (state, action) => {
      const { name, value } = action.payload;
      state.lessonToSetup = { ...state.lessonToSetup, [name]: value };
    },
  },
});

export const {
  clearLessonsData,
  selectLesson,
  selectLessonDate,
  handleSetupLessonChange,
  handleLessonChange,
  handleAdminBookingLesson,
} = lessonSlice.actions;

export default lessonSlice.reducer;

import categories from "../../../assets/AdminApp/svgs/panel/categories.svg";
import content from "../../../assets/AdminApp/svgs/panel/content.svg";
import faq from "../../../assets/AdminApp/svgs/panel/faq.svg";
import subjects from "../../../assets/AdminApp/svgs/panel/subjects.svg";
import User from "../../../assets/AdminApp/svgs/panel/User.svg";
import users from "../../../assets/AdminApp/svgs/panel/users.svg";
import notification from "../../../assets/AdminApp/svgs/panel/notification.svg";
import setting from "../../../assets/AdminApp/svgs/panel/settings.svg";
import icon from "../../../assets/SVG/icon.svg";
import sending from "../../../assets/SVG/sending.svg";
import mirror from "../../../assets/SVG/mirror.svg";
import creditCard from "../../../assets/SVG/creditCard.svg";
import settings from "../../../assets/SVG/settings.svg";
import groupChat from "../../../assets/SVG/groupChat.svg";
import stockholmIconsCom from "../../../assets/SVG/stockholmIconsCom.svg";

export const itemsInitialState = [
  {
    title: "Overview",
    icon: mirror,
    selected: true,
    link: "myclasses",
  },
  {
    title: "Calendar",
    link: "calendar",
    icon: sending,
  },
  {
    link: "subjects/1",
    title: "My Listings",
    icon: sending,
  },
  {
    title: "HomeWork",
    link: "homework",
    icon: sending,
    options: [
      {
        name: "Create Homework",
        link: "/homework",
      },
      {
        name: "View homeworks",
        link: "/homework/view",
      },
    ],
  },
  {
    title: "Notes",
    link: "notes",
    icon: sending,
    options: [
      {
        name: "Add a note",
        link: "/notes",
      },
      {
        name: "View all notes",
        link: "/notes/all",
      },
    ],
  },
  {
    title: "Course Coupons",
    link: "coupon",
    icon: settings,
  },
  {
    title: "My Requests",
    icon: stockholmIconsCom,
    link: "myrequests",
  },
  {
    title: "My Earnings",
    link: "myearnings",
    icon: creditCard,
  },
  {
    title: "My Messages",
    link: "mymessages",
    icon: groupChat,
  },
  {
    title: "Payment Method",
    link: "payment",
    icon: settings,
  },
  {
    title: "Request Recommendation",
    link: "recommendation",
    icon: icon,
    options: [
      {
        name: "My Recommendations",
        link: "/recommendation/my-recommendations",
      },
    ],
  },

  {
    link: "courses/create",
    title: "Online Courses",
    icon: settings,
    options: [
      {
        name: "Create Course",
        link: "/courses/create",
      },
      {
        name: "My Courses",
        link: "/courses/mycourses",
      },
    ],
  },

  {
    title: "FAQ",
    link: "faq",
    icon: icon,
  },
  {
    title: "Notifications",
    link: "notifications",
    icon: notification,
  },
  {
    title: "Help & Support",
    link: "https://educifyinc.freshdesk.com",
    icon: settings,
    openNewPage: true,
  },
];

export const studentsInitial = [
  {
    title: "My Lessons",
    icon: mirror,
    selected: true,
    link: "myclasses",
  },
  {
    title: "Calendar",
    link: "calendar",
    icon: sending,
  },
  {
    title: "Find Classes",
    icon: mirror,
    selected: true,
    link: "findClasses",
  },
  {
    title: "Notes",
    link: "notes",
    icon: sending,
  },
  {
    title: "HomeWorks",
    link: "homework",
    icon: sending,
  },
  {
    title: "Subscriptions",
    link: "subscriptions",
    icon: icon,
    options: [
      {
        name: "Pending Invoices",
        link: "/admin-booking",
        className: "",
      },
    ],
  },
  {
    title: "My Wallet",
    icon: creditCard,
    link: "mywallet",
  },
  {
    title: "My Messages",
    link: "mymessages",
    icon: groupChat,
  },
  {
    title: "Manage Payment",
    link: "payment",
    icon: settings,
  },
  // {
  //   link: "invite",
  //   title: "Invite Friends",
  //   icon: sending,
  // },
  {
    title: "Promo Codes",
    link: "promo",
    icon: settings,
  },

  {
    title: "FAQ",
    link: "faq",
    icon: icon,
  },
  {
    link: "courses",
    title: "Online Courses",
    icon: settings,
    options: [
      {
        name: "All Courses",
        link: "/courses",
        className: "std_list1",
      },
      {
        name: "My List",
        link: "/courses/mylist",
        className: "std_list2",
      },
      // {
      //   name: "Wishlist",
      //   link: "/courses/wishlist",
      // },
    ],
  },
  {
    title: "Help & Support",
    link: "https://educifyinc.freshdesk.com",
    icon: settings,
    openNewPage: true,
  },
  {
    title: "Notification",
    link: "notifications",
    icon: notification,
  },
];

export const AdminInitial = [
  {
    title: "Dashboard",
    icon: mirror,
    selected: true,
    link: "dashboard",
  },
  {
    title: "Analytics",
    icon: User,
    link: "analytics",
  },
  {
    title: "Messages",
    icon: settings,
    link: "admin-messages",
  },
  {
    title: "SEO",
    icon: settings,
    link: "seo",
  },
  {
    title: "Teachers",
    icon: User,
    link: "teachers",
  },
  {
    title: "Notifications",
    link: "notification",
    icon: notification,
  },
  {
    title: "Students",
    link: "students",
    icon: users,
  },
  {
    title: "Blocked Users",
    icon: User,
    link: "users",
  },
  {
    title: "Deleted Accounts",
    icon: User,
    link: "deleted accounts",
  },
  {
    title: "Admin Bookings",
    link: "adminBookings",
    icon: settings,
  },
  {
    title: "Bookings",
    link: "booking",
    icon: settings,
  },
  {
    title: "Blogs",
    link: "blogs",
    icon: settings,
    options: [
      {
        name: "View Blogs",
        link: "/blogs",
      },
      {
        name: "Add blog",
        link: "/blogs/create",
      },
    ],
  },
  {
    title: "Settings",
    link: "settings",
    icon: setting,
    options: [
      {
        name: "Admin List",
        link: "/settings",
      },
      {
        name: "Basic Settings",
        link: "/settings/basic",
      },
      // {
      //   name: "Installation Settings",
      //   link: "/settings/installation",
      // },
      // {
      //   name: "Mail Templates",
      //   link: "/settings/mail",
      // },
      // {
      //   name: "SMS Templates",
      //   link: "/settings/sms",
      // },
    ],
  },
  {
    title: "Payments",
    link: "payments",
    icon: creditCard,
  },
  {
    link: "courses",
    title: "Online Courses",
    icon: settings,
    options: [
      {
        name: "Categories",
        link: "/courses/categories",
      },
      {
        name: "Subjects",
        link: "/courses/subjects",
      },
    ],
  },
  {
    title: "Categories",
    link: "categories",
    icon: categories,
  },
  {
    title: "Subjects",
    link: "subjects",
    icon: subjects,
  },
  {
    title: "Promo Code",
    link: "promo",
    icon: settings,
  },
  {
    link: "packages",
    title: "Manage Packages",
    icon: sending,
  },
  // {
  //   title: "Content Pages",
  //   link: "content",
  //   icon: content,
  // },
  {
    title: "FAQ",
    link: "faq",
    icon: faq,
  },
  // {
  //   title: "Push Notification",
  //   link: "notifications",
  //   icon: notification,
  // },
];
export const BlogAdminInitial = [
  {
    title: "SEO",
    icon: settings,
    link: "seo",
  },
  {
    title: "Teachers",
    icon: User,
    link: "teachers",
  },
  {
    title: "Notifications",
    link: "notification",
    icon: notification,
  },
  {
    title: "Students",
    link: "students",
    icon: users,
  },
  {
    title: "Blogs",
    link: "blogs",
    icon: settings,
    options: [
      {
        name: "View Blogs",
        link: "/blogs",
      },
      {
        name: "Add blog",
        link: "/blogs/create",
      },
    ],
  },
  {
    link: "courses",
    title: "Online Courses",
    icon: settings,
    options: [
      {
        name: "Categories",
        link: "/courses/categories",
      },
      {
        name: "Subjects",
        link: "/courses/subjects",
      },
    ],
  },
  {
    title: "Categories",
    link: "categories",
    icon: categories,
  },
  {
    title: "Subjects",
    link: "subjects",
    icon: subjects,
  },

  {
    title: "FAQ",
    link: "faq",
    icon: faq,
  },
];

export const steps = [
  {
    element: "#guide0",
    title: "My Lessons",
    intro:
      "In the My 'Lessons section', you can access an overview of the lessons you've scheduled with your teacher. Here, you'll find detailed information about each lesson, including the date and time of your upcoming sessions.",
  },
  {
    element: "#guide1",
    title: "Course Coupons",
    intro: `In the "Course Coupon" section, you have the ability to view your coupon codes, create new ones, edit existing ones, and remove them as needed. Additionally, you can share these coupons with your students, providing them with discounts on your courses.`,
  },
  {
    element: "#guide2",
    title: "My Requests",
    intro: `Within the "My Requests" section, you'll find incoming requests from new students who are interested in joining your lessons. Here, you have the option to either accept or deny these requests, allowing you to manage and respond to new student inquiries effectively.`,
  },
  {
    element: "#guide3",
    title: "My Earnings",
    intro: `In the "My Earnings" section, you can easily access and view your total earnings, which represent the amount you've been paid for your teaching services. This feature provides a convenient way to keep track of your financial transactions related to teaching.`,
  },
  {
    element: "#guide4",
    title: "My Messages",
    intro: `In the "My Messages" section, you can engage in conversations with students who have booked your lessons. This feature enables seamless communication, allowing you to address questions, provide instructions, and maintain open lines of communication with your students for a productive teaching experience.`,
  },
  {
    element: "#guide5",
    title: "Payment Method",
    intro: `Within the "Payment Method" section, you have the ability to configure, view, and edit your preferred payment methods. This feature allows you to customize how you'd like to receive payments, providing flexibility and convenience in managing your financial transactions.`,
  },
  {
    element: "#guide6",
    title: "Manage Subjects",
    intro: `In the "Manage Subjects" section, you have the capability to perform various actions related to the subjects you teach. These actions include viewing, editing, deleting, and creating new subjects as needed. This feature empowers you to efficiently manage the subjects you offer for teaching.`,
  },
  {
    element: "#guide7",
    title: "Online Courses",
    intro: `In the "Online Courses" section, you have comprehensive control over your courses. You can easily view, create, edit, or delete courses to tailor your offerings. Additionally, you have the flexibility to choose between creating free courses or selling them to your audience, giving you full control over your course content and pricing.`,
  },
  {
    element: "#guide8",
    title: "FAQ",
    intro: "View FAQs here",
  },
  {
    element: "#guide9",
    title: "Notifications",
    intro: `In the "Notifications" section, you can stay informed about various updates and alerts, including notifications about new lesson bookings. This feature ensures you're promptly notified about important events and activities related to your teaching, helping you manage your schedule and interactions effectively.`,
  },
];
export const studentSteps = [
  {
    element: "#guide0",
    title: "My Lessons",
    intro:
      "In the My 'Lessons section', you can access an overview of the lessons you've scheduled with your teacher. Here, you'll find detailed information about each lesson, including the date and time of your upcoming sessions.",
  },
  {
    element: "#guide1",
    title: "Calendar",
    intro: `Here you can see your upcoming classes`,
  },
  {
    element: "#guide2",
    title: "Find Classes",
    intro: `In the "Find Classes" section, you can explore a comprehensive list of lessons offered by our dedicated teachers. This includes both individual lessons and group lessons, as well as full courses, providing you with a wide range of learning opportunities to choose from.`,
  },
  {
    element: "#guide3",
    title: "Notes",
    intro: `Here you can see notes from your instructors`,
  },
  {
    element: "#guide4",
    title: "HomeWorks",
    intro: `Here you can see lesson homeworks from your instructors`,
  },
  {
    element: "#guide5",
    title: "Subscriptions",
    intro: `Here you can view or remove lessons your are subscribed to`,
  },
  {
    element: "#guide6",
    title: "My Wallet",
    intro: `In this section, you can check your current account balance or add funds using your credit card or PayPal account. This feature gives you control over your financial transactions and allows you to manage your account balance conveniently.`,
  },
  {
    element: "#guide7",
    title: "My Messages",
    intro: `In the "My Messages" section, you can engage in conversations with teachers which you have booked your lessons with. This feature enables seamless communication, allowing you to address questions, provide instructions, and maintain open lines of communication with your students for a productive teaching experience.`,
  },
  {
    element: "#guide8",
    title: "Payment Method",
    intro: `Through the "Payment Method" feature, you can easily setup your preferred payment method, wether its Paypal or Credit Card`,
  },
  {
    element: "#guide9",
    title: "Invite Friends",
    intro: `Through the "Invite Friends" feature, you can easily invite your friends via email. This functionality simplifies the process of inviting friends to join and participate in your online activities.`,
  },
  {
    element: "#guide10",
    title: "Promo Codes",
    intro: `The "Promo Codes" feature allows you to share your promo code with friends, enabling them to enjoy a 15% discount on lessons. It's a great way to offer your friends a special discount and encourage them to participate in your lessons.`,
  },
  {
    element: "#guide11",
    title: "FAQ",
    intro: "View FAQs here",
  },
  {
    element: "#guide12",
    title: "Help & Support",
    intro: `With Help and Support you can view Frequently asked questions or reach out to our customer service with a question or have a chat with us.`,
  },
  {
    element: "#guide13",
    title: "Notifications",
    intro: `In the "Notifications" section, you can stay informed about various updates and alerts, including notifications about new lesson bookings. This feature ensures you're promptly notified about important events and activities related to your teaching, helping you manage your schedule and interactions effectively.`,
  },
  // {
  //   element: "#guide13",
  //   title: "Online Courses",
  //   intro: `In the "Online Courses" section, you have comprehensive control over your courses. You can easily view courses you bought, or buy a new course`,
  // },
];

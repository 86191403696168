import React, { useEffect, useState } from "react";
import { setExchangeRates } from "./ReduxToolkit/Slices/currencySlice";
import { useDispatch, useSelector } from "react-redux";

export const ExchangeRateHook = ({ price, strike, newPrice }) => {
  const [convertedPrice, setConvertedPrice] = useState(0);
  const currency = useSelector((state) =>
    state.currency.currency.toUpperCase()
  );

  let oldCurrency = currency;
  const exchangeRates = useSelector((state) => state.currency.exchangeRates);
  const dispatch = useDispatch();

  const fetchExchangeRates = () => {
    const baseUrl = "https://v6.exchangerate-api.com/v6/";
    const API_KEY = import.meta.env.VITE_EXCHANGE_API; // Use an environment variable for API key
    const url = `${baseUrl}${API_KEY}/latest/USD`; // Assume USD as the base currency for example

    return fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data.result === "success") {
          dispatch(setExchangeRates(data.conversion_rates));
        } else {
          dispatch(setExchangeRates(["failed"]));
          console.error("Failed to fetch exchange rates:", data["error-type"]);
        }
      })
      .catch((error) => {
        dispatch(setExchangeRates(["failed"]));
        console.error("Failed to fetch exchange rates:", error);
      });
  };

  useEffect(() => {
    if (exchangeRates && exchangeRates[currency]) {
      const exchangeRate = exchangeRates[currency];
      const convertedAmount = price * exchangeRate;
      setConvertedPrice(Math.ceil(convertedAmount * 100) / 100);
    }
  }, [exchangeRates, currency, price]);

  const currencySymbols = {
    USD: "$",
    EUR: "€",
    GBP: "£",
    CAD: "CA$",
    AUD: "A$",
  };

  const currencySymbol = currencySymbols[currency];

  return (
    <span
      style={
        strike && {
          textDecoration: strike ? "line-through" : "none",
          textDecorationColor: "red",
          marginRight: "10px",
        }
      }
    >
      <span>{currencySymbol} </span>
      {currency === "USD"
        ? Math.ceil(price) / 100
        : Math.ceil(convertedPrice) / 100}
    </span>
  );
};

import moment from "moment";

export const getCurrentTime = () => {
  const now = new Date();
  const hours = now.getHours();
  const minutes = now.getMinutes();
  return hours * 100 + minutes;
};
export const filterPastTimes = (times, selectedDate) => {
  const currentTime = getCurrentTime();
  if (isToday(selectedDate)) {
    return times.filter((time) => get24HourTime(time.label) >= currentTime);
  }
  return times;
};

export const isToday = (dateString) => {
  const selectedDate = new Date(dateString);
  const today = new Date();
  return selectedDate.toDateString() === today.toDateString();
};
export const get24HourTime = (time) => {
  const [hours, minutes] = time.split(":");
  const [timePart, amPm] = minutes.split(" ");
  let hour = parseInt(hours, 10);
  if (amPm === "pm" && hour !== 12) hour += 12;
  if (amPm === "am" && hour === 12) hour = 0;
  return hour * 100 + parseInt(timePart, 10);
};
export const timesCustomStyles = {
  control: (provided, state) => ({
    ...provided,
    border: "none",
    boxShadow: "none",
    "&:hover": {
      border: "none",
    },
    backgroundColor: state.isFocused ? "white" : "white", // Ensures the background color doesn't change on focus
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: "none",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  menu: (provided) => ({
    ...provided,
    border: "none",
    boxShadow: "none",
  }),
  input: (provided) => ({
    ...provided,
    boxShadow: "none",
  }),
  placeholder: (provided) => ({
    ...provided,
    boxShadow: "none",
  }),
  singleValue: (provided) => ({
    ...provided,
    boxShadow: "none",
  }),
};
export function convertTimeToTimezone(time, targetTimezone) {
  // Define the format for the input time
  const format = "h:mm a"; // e.g., 9:00 am

  // Create a moment object for the input time in UTC
  const utcTime = moment.utc(time, format);

  // Convert the UTC time to the target timezone
  const localTime = utcTime.tz(targetTimezone);

  // Return the formatted local time
  return localTime.format(format);
}
export const getSortableTime = (label) => {
  const [time, period] = label.split(" ");
  const [hours, minutes] = time.split(":");
  const hour = parseInt(hours, 10);
  const adjustedHour =
    period === "pm" && hour !== 12
      ? hour + 12
      : hour === 12 && period === "am"
      ? 0
      : hour;
  return adjustedHour * 100 + parseInt(minutes, 10);
};

export const convertDateTimeToUtc = ({ date, time, timezone }) => {
  // Combine date and time strings into one
  const combinedStr = `${date} ${time}`;

  // Parse the combined string into a Moment object with the given timezone
  const localDateTime = moment.tz(combinedStr, "YYYY-MM-DD h:mm A", timezone);

  // Check if date is valid
  if (!localDateTime.isValid()) {
    console.error("Invalid date format");
    return null;
  }

  // Convert the local date-time to UTC
  const utcDateTime = localDateTime.utc();

  // Format the UTC date-time to an ISO string
  const utcString = utcDateTime.toISOString();

  return utcString;
};
export const convertCustomDateToUTC = (dateTime) => {
  // Parse the input dateTime string in UTC format
  const utcDateTime = moment.utc(dateTime, "YYYY-MM-DD at h:mm A");

  // Check if dateTime is valid
  if (!utcDateTime.isValid()) {
    console.error("Invalid date format");
    return null;
  }

  // Format the UTC dateTime to an ISO string
  const utcString = utcDateTime.toISOString();

  return utcString;
};

export const ConvertFromTimeZoneToTimeZone = (
  currentTimezone,
  date,
  time,
  targetTimezone
) => {
  // Combine date and time into a single string
  const dateTimeString = `${date} ${time}`;

  // Parse the date and time in the current timezone using the 12-hour format
  const localTime = moment.tz(
    dateTimeString,
    "YYYY-MM-DD hh:mm A",
    currentTimezone
  );

  // Convert the moment object to the target timezone
  const targetTime = localTime.clone().tz(targetTimezone);

  // Format the converted time in ISO 8601 format
  return targetTime.toISOString();
};

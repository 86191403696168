import React, { useState } from "react";
import UpdateTeacher from "../../../TeacherApp/Components/UpdateTeacher/UpdateTeacher";
import { useParams } from "react-router-dom";
function EditForm({ onChange, user, role, onSubmit, password, update }) {
  const { id } = useParams();

  return (
    <div className="">
      <UpdateTeacher id={id} />
      {/* <div className="edit__container">
        {role ? <h3>Edit {role}</h3> : <h3>Add admin</h3>}
        {!role && (
          <div className="">
            <div
              className="header"
              style={{ display: "flex", gap: "10px", alignItems: "center" }}
            >
              <p>Admin Role</p>
              <select
                style={{ height: "30px" }}
                value={user.role}
                defaultValue={user.role}
                onChange={(e) => onChange(e)}
                name="role"
              >
                <option value="" disabled>
                  Select admin role
                </option>
                <option value="ADMIN">Super Admin</option>
                <option value="MANAGER">Manager</option>
                <option value="BLOGADMIN">Content admin</option>
              </select>
            </div>
          </div>
        )}
        <div className="inputs__container">
          <div className="inputs">
            <div className="input">
              <span>First Name</span>
              <input
                type="text"
                value={user.name}
                name="name"
                onChange={(e) => onChange(e)}
              />
              <p>Please enter first name.</p>
            </div>
            <div className="input">
              <span>Last Name</span>
              <input
                type="text"
                value={user.lastName}
                name="lastName"
                onChange={(e) => onChange(e)}
              />
              <p>Please enter last name.</p>
            </div>
            <div className="input__multiple">
              <div className="input">
                <span>Phone</span>
                <input
                  type="text"
                  value={user.phone}
                  name="phone"
                  onChange={(e) => onChange(e)}
                />

                <p>Please enter phone number.</p>
              </div>
              <div className="input">
                <span>Email</span>
                <input
                  type="text"
                  value={user.email}
                  name="email"
                  onChange={(e) => onChange(e)}
                />
                <p>Please enter email address.</p>
              </div>
            </div>
            {password && (
              <div className="input">
                <span>Password</span>
                <input
                  type={!togglePass ? "password" : "text"}
                  className="pwd__input"
                  name="password"
                  value={user?.password}
                  onChange={(e) => onChange(e)}
                />
                <p>Please enter a new password.</p>
                <img
                  src={hideImage}
                  alt="hide"
                  className="hide_image_edit"
                  onClick={() => setTogglePass(!togglePass)}
                />
              </div>
            )}
          </div>
        </div>

        <button className="secondary" onClick={() => onSubmit()}>
          {update ? "Update Admin" : "Save Changes"}
        </button>
      </div> */}
    </div>
  );
}

export default EditForm;

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAdminMessages } from "../../../ReduxToolkit/Slices/adminSlice";
import ContactsContainer from "../../../StudentApp/Pages/Chat/ContactsContainer";
import defaultPP from "../../../assets/SharedApp/images/default.webp";
// import CustomMessageList from "../../../StudentApp/Pages/Chat/CustomMessageList"; // Import your CustomMessageList component
import "./adminChat.styles.scss";

import {
  MinChatUiProvider,
  MainContainer,
  MessageInput,
  MessageContainer,
  MessageList,
  MessageHeader,
} from "@minchat/react-chat-ui";
import moment from "moment";

const AdminChat = () => {
  const [rooms, setRooms] = useState([]);
  const [search, setSearch] = useState("");
  const [recipient, setRecipient] = useState({ name: "", profileImage: "" });
  const [recipientId, setRecipientId] = useState("");
  const [selectedRoom, setSelectedRoom] = useState({});
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  let timeoutId;
  useEffect(() => {
    // Clear previous timeout if it exists
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set a new timeout
    timeoutId = setTimeout(() => {
      dispatch(getAdminMessages({ search })).then((res) => {
        if (res.type.includes("fulfilled")) {
          const fetchedRooms = res.payload; // Assuming the API returns 'rooms'
          setRooms(fetchedRooms);

          // Set default recipient to the first room if available
          if (fetchedRooms.length > 0) {
            const firstRoom = fetchedRooms[0];
            setRecipientId(firstRoom.roomId);
            setSelectedRoom(firstRoom);
            setRecipient({
              name: firstRoom.users
                .map((user) => `${user.name} ${user?.lastName}`)
                .join(" & "),
              profileImage: firstRoom.users[0]?.profileImage
                ? firstRoom.users[0]?.profileImage
                : defaultPP,
            });
          }
        }
      });
    }, 300); // Adjust the timeout duration (300ms in this example)

    // Cleanup function to clear timeout if component unmounts
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [dispatch, search]);

  // Preprocess rooms data before passing to ContactsContainer
  const processedContacts = rooms.map((room) => {
    const lastMessage =
      room.messages?.length > 0 ? room.messages[room.messages.length - 1] : {};
    return {
      id: room.roomId,
      name: room.users
        .map((user) => `${user.name} ${user?.lastName}`)
        .join(" & "),
      profileImage: room.users[0]?.profileImage || defaultPP,
      lastMessage: lastMessage?.content ? lastMessage?.content : "No messages",
      lastMessageTime: lastMessage?.createdAt ? lastMessage?.createdAt : "",
      unseenMessages: room.unseenMessages ? room.unseenMessages : 0, // Assuming unseen messages count is part of the API response
      messages: room.messages, // Pass the original messages array
    };
  });

  const handleRoomSelect = (id) => {
    setRecipientId(id);
    const room = rooms.find((el) => el.roomId === id);
    setSelectedRoom(room);
    if (room) {
      setRecipient({
        name: room.users
          .map((user) => `${user.name} ${user?.lastName}`)
          .join(" & "),
        profileImage: room.users[0]?.profileImage
          ? room.users[0]?.profileImage
          : defaultPP,
      });
    }
  };

  return (
    <div className="chat_page admin_chat_page">
      <div className="chat_layout">
        <div className="all_contacts_container">
          <ContactsContainer
            contacts={processedContacts} // Pass the preprocessed contacts
            messageIndex={recipientId}
            profilePic={defaultPP}
            setMesageIndex={handleRoomSelect}
            search={search}
            setSearch={setSearch}
          />
        </div>
        <div className="chatting_container">
          <MinChatUiProvider theme="#235987">
            <MainContainer>
              <MessageContainer>
                <MessageHeader>
                  <div className="message_header">
                    <img
                      src={recipient.profileImage?.url}
                      alt=""
                      className="avatar"
                    />
                    <div className="name">
                      <span>{recipient.name}</span>
                      <p>Tutor</p>
                    </div>
                  </div>
                </MessageHeader>
                <CustomMessageList
                  messages={selectedRoom?.messages || []}
                  userName={user.name}
                  userProfile={user.profileImage?.url || defaultPP}
                  userStatus={{}}
                  isTyping={false}
                />
              </MessageContainer>
            </MainContainer>
          </MinChatUiProvider>
        </div>
      </div>
    </div>
  );
};

export default AdminChat;

const CustomMessageList = ({ messages, userProfile }) => {
  const messageListRef = useRef(null);

  const scrollToBottom = () => {
    if (messageListRef?.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div style={{ overflowY: "auto", flexGrow: 1 }} className="chat-container">
      <div className="all_user_messages">
        {messages &&
          messages.map((msg, i) => {
            // Determine if the message is outgoing or incoming based on the sender
            const isOutgoing = msg.senderName === messages[0]?.senderName;
            return (
              <div
                key={msg.id}
                className={`message-item ${
                  isOutgoing ? "outgoing" : "incoming"
                }`}
              >
                <div className="custom_message_header">
                  <div className="image_name">
                    <img
                      src={
                        msg?.senderProfileImage
                          ? msg?.senderProfileImage
                          : defaultPP
                      }
                      alt=""
                    />
                    <span>{msg.senderName}</span>
                  </div>
                  <span className="msg_time">
                    {moment(msg?.createdAt).format("MMMM DD, YYYY h:mm A")}
                  </span>
                </div>
                <div className="message-content">
                  <p className="message-text">{msg.content}</p>
                  <div className="message-meta"></div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  createPackage,
  getPackages,
  setAdminState,
  updatePackage,
} from "../../../ReduxToolkit/Slices/adminSlice";

import { toast } from "react-toastify";

import "./NewPackage.styles.scss";

export default function ViewPackage({ update }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { packages } = useSelector((state) => state.admin);

  const { name, duration } = useParams();

  let edit = name ? true : false;

  let offerInitial = {
    name: "",
    available: false,
  };

  let packageInitials = edit
    ? packages.filter((pack) => {
        return pack.name === name && pack.duration === duration;
      })
    : {
        name: "",
        duration: 30,
        numOfLessons: "",
        status: "ACTIVE",
        offers: [],
      };
  const [newPackage, setNewPackage] = useState({
    name: name ? name : "",
    ...packageInitials,
  });

  const [allOffers, setAllOffers] = useState(
    packageInitials.offers ? packageInitials.offers : [offerInitial]
  );
  const handleOfferChange = ({ name, value, index, subName }) => {
    if (name === "offers" && index !== null) {
      if (subName === "delete") {
        return setAllOffers((offers) => offers.filter((_, i) => i !== index));
      } else {
        return setAllOffers((offers) => {
          return [
            ...offers.slice(0, index),
            { ...offers[index], [subName]: value },
            ...offers.slice(index + 1),
          ];
        });
      }
    } else if (name === "offers" && index === null) {
      return setAllOffers([...allOffers, offerInitial]);
    }
  };
  const handleChange = ({ name, value }) => {
    setNewPackage({ ...newPackage, [name]: value });
  };

  const [selectedPackage, setSelectedPackage] = useState({});
  useEffect(() => {
    if (name) {
      const FoundPackage = packages.find(
        (pack) => pack.name === name && pack.duration == duration
      );
      setSelectedPackage(FoundPackage);
      if (update) {
        setAllOffers(selectedPackage.offers);
      }
    }
  }, [name, duration, selectedPackage.name]);

  const handleCreate = () => {
    if (update) {
      // update package
      const { name, percentage, status, numOfLessons, duration, id } =
        selectedPackage;
      dispatch(
        updatePackage({
          id,
          name: newPackage.name,
          percentage,
          status,
          numOfLessons,
          duration: duration,
          offers: allOffers,
        })
      ).then((res) => {
        if (res.type === "updatePackage/fulfilled") {
          // get new packages and add them to the state
          dispatch(getPackages()).then((res) => {
            alert("Package updated, thank you!");
            dispatch(setAdminState({ name: packages, value: res.payload }));
            navigate(-1);
          });
        }
      });
    } else {
      dispatch(createPackage({ ...newPackage, offers: allOffers })).then(
        (res) => {
          alert("Package created, thank you!");
          navigate(-1);
        }
      );
    }
  };

  return (
    <div className="subject__details">
      <div className="header">
        <div className="col">
          <h1>Name</h1>
          <p>Package pricing </p>
        </div>
      </div>
      <div className="package__details">
        <div className="input">
          <span>Package Name:</span>
          <input
            type="text"
            placeholder="Name"
            value={newPackage.name}
            onChange={(e) =>
              handleChange({ name: "name", value: e.target.value })
            }
          />
        </div>

        <div className="input">
          <span>Duration</span>
          <select
            disabled={update ? true : false}
            onChange={(e) =>
              handleChange({
                name: "duration",
                value: parseInt(e.target.value),
              })
            }
            value={update ? selectedPackage?.duration : newPackage.duration}
          >
            <option value={30}>30</option>
            <option value={60}>60</option>
            <option value={45}>45</option>
            <option value={90}>90</option>
          </select>
        </div>
        <div className="input">
          <span>Number of Lessons:</span>
          <input
            type="number"
            value={
              update ? selectedPackage.numOfLessons : newPackage.numOfLessons
            }
            placeholder="lessons number"
            onChange={(e) =>
              update
                ? setSelectedPackage({
                    ...selectedPackage,
                    numOfLessons: e.target.valueAsNumber,
                  })
                : handleChange({
                    name: "numOfLessons",
                    value: parseInt(e.target.value),
                  })
            }
          />
        </div>
        <div className="input">
          <span>Package Percentage:</span>
          <input
            type="number"
            placeholder="%"
            max={99}
            value={update ? selectedPackage.percentage : newPackage.percentage}
            min={1}
            onChange={(e) =>
              update
                ? setSelectedPackage({
                    ...selectedPackage,
                    percentage: e.target.valueAsNumber,
                  })
                : handleChange({
                    name: "percentage",
                    value: parseInt(e.target.value),
                  })
            }
          />
        </div>
        <div className="input">
          <span>Package Status:</span>
          <select
            value={update ? selectedPackage?.status : newPackage.status}
            onChange={(e) =>
              update
                ? setSelectedPackage({
                    ...selectedPackage,
                    status: e.target.value,
                  })
                : handleChange({ name: "status", value: e.target.value })
            }
          >
            <option value="ACTIVE">active</option>
            <option value="INACTIVE">inActive</option>
          </select>
        </div>
      </div>

      <div className="detail__rows">
        <h3>Offers</h3>
        {allOffers &&
          allOffers.map((offer, i) => (
            <div className="newPackage__row" key={i}>
              <div className="row__inputs">
                <div className="input">
                  <span>Name</span>
                  <input
                    type="text"
                    placeholder="name"
                    value={offer.name}
                    onChange={(e) => {
                      handleOfferChange({
                        name: "offers",
                        value: e.target.value,
                        index: i,
                        subName: "name",
                      });
                    }}
                  />
                </div>
                <div className="input">
                  <span>Available</span>
                  <select
                    value={offer.available}
                    onChange={(e) =>
                      handleOfferChange({
                        name: "offers",
                        value: e.target.value === "true" ? true : false,
                        index: i,
                        subName: "available",
                      })
                    }
                    defaultValue={true}
                  >
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </select>
                </div>
                {/* <div className="input">
                  <span
                    className="rejected"
                    onClick={() =>
                      setAllOffers(
                        allOffers.filter((pack) => pack.id !== offer.id)
                      )
                    }
                  >
                    delete
                  </span>
                </div> */}
                <button
                  className="delete flex_center"
                  onClick={() =>
                    handleOfferChange({
                      name: "offers",
                      index: i,
                      subName: "delete",
                    })
                  }
                >
                  -
                </button>
              </div>
            </div>
          ))}
        <div className="add__offer">
          Add Offer{" "}
          <button
            onClick={() =>
              // setNewPackage()
              // setNewPackage({ ...newPackage, [name]: value });
              handleOfferChange({ name: "offers", index: null })
            }
          >
            +
          </button>
        </div>
        <div className="packages__nav">
          <button className="create__package" onClick={() => handleCreate()}>
            {update ? "Update Package" : "Create Package"}
          </button>
          <button className="create__package" onClick={() => navigate(-1)}>
            Discard
          </button>
        </div>
      </div>
    </div>
  );
}

const OfferRow = ({ offer }) => {
  return (
    <div className="row__inputs">
      <div className="input">
        <span>Name</span>
        <input type="text" placeholder="name" value={offer.name} />
      </div>
      <div className="input">
        <span>Available</span>
        <select aria-readonly={true} disabled={true} value={offer.available}>
          <option value={true}>Yes</option>
          <option value={false}>No</option>
        </select>
      </div>
    </div>
  );
};
